{
  "accessDenied": {
    "description": "You don't have required permissions to view this page."
  },
  "ingredientUnit": {
    "bag": {
      "plural": "torebki",
      "singular": "torebka"
    },
    "branch": {
      "plural": "gałązki",
      "singular": "gałązka"
    },
    "bunch": {
      "plural": "pęczki",
      "singular": "pęczek"
    },
    "can": {
      "plural": "puszki",
      "singular": "puszka"
    },
    "centiliter": {
      "plural": "centylitry",
      "singular": "centylitr"
    },
    "clove": {
      "plural": "ząbki",
      "singular": "ząbek "
    },
    "cup": {
      "plural": "szklanki",
      "singular": "szklanka"
    },
    "dash": {
      "plural": "szczypty",
      "singular": "szczypta"
    },
    "decigram": {
      "plural": "decygramy",
      "singular": "decygram"
    },
    "deciliter": {
      "plural": "decylitry",
      "singular": "decylitr"
    },
    "drop": {
      "plural": "krople",
      "singular": "kropla"
    },
    "gram": {
      "plural": "gramy",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilogramy",
      "singular": "kilogram"
    },
    "liter": {
      "plural": "litry",
      "singular": "litr"
    },
    "milligram": {
      "plural": "miligramy",
      "singular": "miligram"
    },
    "milliliter": {
      "plural": "mililitry",
      "singular": "mililitr"
    },
    "ounce": {
      "plural": "uncje",
      "singular": "uncja"
    },
    "package": {
      "plural": "opakowania",
      "singular": "opakowanie"
    },
    "piece": {
      "plural": "sztuki",
      "singular": "sztuka"
    },
    "pinch": {
      "plural": "szczypty",
      "singular": "szczypta"
    },
    "sachet": {
      "plural": "saszetki",
      "singular": "saszetka"
    },
    "slice": {
      "plural": "plasterki",
      "singular": "plasterek"
    },
    "tablespoon": {
      "plural": "łyżki",
      "singular": "łyżka"
    },
    "teaspoon": {
      "plural": "łyżeczki",
      "singular": "łyżeczka"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Tak",
        "label": "Łatwy start",
        "notEasyToStart": "Nie",
        "placeholder": "Łatwy start"
      }
    }
  }
}