{
  "ingredientUnit": {
    "bag": {
      "empty": "kese",
      "plural": "kesa",
      "singular": "kesa"
    },
    "branch": {
      "empty": "grančice",
      "plural": "grančice",
      "singular": "grančica"
    },
    "bunch": {
      "empty": "vezice",
      "plural": "vezice",
      "singular": "vezica"
    },
    "can": {
      "empty": "konzerve",
      "plural": "konzerve",
      "singular": "konzerva"
    },
    "centiliter": {
      "empty": "cl",
      "plural": "cl",
      "singular": "cl"
    },
    "clove": {
      "empty": "češnja",
      "plural": "češnja",
      "singular": "češanj"
    },
    "cup": {
      "empty": "šolje",
      "plural": "šolje",
      "singular": "šolja"
    },
    "dash": {
      "empty": "mrvice",
      "plural": "mrvice",
      "singular": "mrvica"
    },
    "decigram": {
      "empty": "dag",
      "plural": "dag",
      "singular": "dag"
    },
    "deciliter": {
      "empty": "dl",
      "plural": "dl",
      "singular": "dl"
    },
    "drop": {
      "empty": "kapi",
      "plural": "kapi",
      "singular": "kap"
    },
    "fluidOunce": {
      "empty": "tečne unce",
      "plural": "tečne unce",
      "singular": "tečna unca"
    },
    "gram": {
      "empty": "g",
      "plural": "g",
      "singular": "g"
    },
    "kilogram": {
      "empty": "kg",
      "plural": "kg",
      "singular": "kg"
    },
    "liter": {
      "empty": "l",
      "plural": "l",
      "singular": "l"
    },
    "microgram": {
      "empty": "mcg",
      "plural": "mcg",
      "singular": "mcg"
    },
    "milligram": {
      "empty": "mg",
      "plural": "mg",
      "singular": "mg"
    },
    "milliliter": {
      "empty": "ml",
      "plural": "ml",
      "singular": "ml"
    },
    "ounce": {
      "empty": "unce",
      "plural": "unce",
      "singular": "unca"
    },
    "package": {
      "plural": "paketa",
      "singular": "paket"
    },
    "piece": {
      "empty": "kom.",
      "plural": "kom.",
      "singular": "kom."
    },
    "pinch": {
      "empty": "prstohvata",
      "plural": "prstohvata",
      "singular": "prstohvat"
    },
    "pound": {
      "empty": "funte",
      "plural": "funte",
      "singular": "funta"
    },
    "sachet": {
      "empty": "kesica",
      "plural": "kesice",
      "singular": "kesica"
    },
    "shot": {
      "empty": "čašice",
      "plural": "čašice",
      "singular": "čašica"
    },
    "slice": {
      "empty": "kriške",
      "plural": "kriške",
      "singular": "kriška"
    },
    "stick": {
      "empty": "štapića",
      "plural": "štapića",
      "singular": "štapić"
    },
    "tablespoon": {
      "empty": "supene kašike",
      "plural": "supene kašike",
      "singular": "supena kašika"
    },
    "teaspoon": {
      "empty": "kafene kašike",
      "plural": "kafene kašike",
      "singular": "kafena kašika"
    }
  }
}