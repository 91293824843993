{
  "ingredientUnit": {
    "bag": {
      "empty": "пакетчета",
      "plural": "пакетчета",
      "singular": "пакетче"
    },
    "branch": {
      "empty": "връзка",
      "plural": "стръкове ",
      "singular": "стрък"
    },
    "bunch": {
      "empty": "връзки",
      "plural": "връзки ",
      "singular": "връзка"
    },
    "can": {
      "empty": "консерви",
      "plural": "консерви",
      "singular": "консерва"
    },
    "centiliter": {
      "empty": "сантилитра ",
      "plural": "сантилитра ",
      "singular": "сантилитър"
    },
    "clove": {
      "empty": "скилидки",
      "plural": "скилидки",
      "singular": "скилидка"
    },
    "cup": {
      "empty": "чаени чаши",
      "plural": "чаени чаши",
      "singular": "чаена чаша"
    },
    "dash": {
      "empty": "щипки",
      "plural": "щипки",
      "singular": "щипка"
    },
    "decigram": {
      "empty": "дециграм ",
      "plural": "дециграма",
      "singular": "дециграм"
    },
    "deciliter": {
      "empty": "децилитри",
      "plural": "децилитри",
      "singular": "децилитра"
    },
    "drop": {
      "empty": "капки",
      "plural": "капки",
      "singular": "капка"
    },
    "fluidOunce": {
      "empty": "течни унции",
      "plural": "течни унции",
      "singular": "течна унция"
    },
    "gram": {
      "empty": "грама",
      "plural": "грама",
      "singular": "грам "
    },
    "kilogram": {
      "empty": "килограма",
      "plural": "килограма",
      "singular": "килограм"
    },
    "liter": {
      "empty": "литри",
      "plural": "литри",
      "singular": "литър"
    },
    "microgram": {
      "empty": "микрограма",
      "plural": "микрограма",
      "singular": "микрограм"
    },
    "milligram": {
      "empty": "милиграми ",
      "plural": "милиграма ",
      "singular": "милиграм "
    },
    "milliliter": {
      "empty": "милилитри",
      "plural": "милилитри",
      "singular": "милилитър"
    },
    "ounce": {
      "empty": "унции",
      "plural": "унции",
      "singular": "унция"
    },
    "package": {
      "plural": "опаковки",
      "singular": "опаковка"
    },
    "piece": {
      "empty": "броя",
      "plural": "броя",
      "singular": "брой "
    },
    "pinch": {
      "empty": "щипки",
      "plural": "щипки",
      "singular": "щипка"
    },
    "pound": {
      "empty": "фунтове",
      "plural": "фунта",
      "singular": "фунт"
    },
    "sachet": {
      "empty": "сашета",
      "plural": "саше",
      "singular": "саше"
    },
    "shot": {
      "empty": "чашки",
      "plural": "чашки",
      "singular": "чашка"
    },
    "slice": {
      "empty": "резена",
      "plural": "резени",
      "singular": "резен"
    },
    "stick": {
      "empty": "стръка",
      "plural": "стръкове ",
      "singular": "стрък"
    },
    "tablespoon": {
      "empty": "супени лъжици",
      "plural": "супени лъжици",
      "singular": "супена лъжица"
    },
    "teaspoon": {
      "empty": "чаени лъжички",
      "plural": "чаени лъжички",
      "singular": "чаена лъжичка"
    }
  }
}