{
  "ingredientUnit": {
    "bag": {
      "empty": "maisi",
      "plural": "maisi",
      "singular": "maiss"
    },
    "branch": {
      "empty": "zari",
      "plural": "zari",
      "singular": "zars"
    },
    "bunch": {
      "empty": "saišķi",
      "plural": "saišķi",
      "singular": "saišķis"
    },
    "can": {
      "empty": "kārbas",
      "plural": "kārbas",
      "singular": "kārba"
    },
    "centiliter": {
      "empty": "cl",
      "plural": "cl",
      "singular": "cl"
    },
    "clove": {
      "empty": "daiviņas",
      "plural": "daiviņas",
      "singular": "daiviņa"
    },
    "cup": {
      "empty": "tases",
      "plural": "tases",
      "singular": "tase"
    },
    "dash": {
      "empty": "lāses",
      "plural": "lāses",
      "singular": "lāse"
    },
    "decigram": {
      "empty": "dg",
      "plural": "dg",
      "singular": "dg"
    },
    "deciliter": {
      "empty": "dl",
      "plural": "dl",
      "singular": "dl"
    },
    "drop": {
      "empty": "pil.",
      "plural": "pil.",
      "singular": "pil."
    },
    "fluidOunce": {
      "empty": "šķidruma unces",
      "plural": "šķidruma unces",
      "singular": "šķidruma unce"
    },
    "gram": {
      "empty": "g",
      "plural": "g",
      "singular": "g"
    },
    "kilogram": {
      "empty": "kg",
      "plural": "kg",
      "singular": "kg"
    },
    "liter": {
      "empty": "l",
      "plural": "l",
      "singular": "l"
    },
    "microgram": {
      "empty": "mcg",
      "plural": "mcg",
      "singular": "mcg"
    },
    "milligram": {
      "empty": "mg",
      "plural": "mg",
      "singular": "mg"
    },
    "milliliter": {
      "empty": "ml",
      "plural": "ml",
      "singular": "ml"
    },
    "ounce": {
      "empty": "oz",
      "plural": "oz",
      "singular": "oz"
    },
    "package": {
      "plural": "pac.",
      "singular": "pac."
    },
    "piece": {
      "empty": "gab.",
      "plural": "gab.",
      "singular": "gab."
    },
    "pinch": {
      "empty": "šķipsnas",
      "plural": "šķipsnas",
      "singular": "šķipsna"
    },
    "pound": {
      "empty": "mārc.",
      "plural": "mārc.",
      "singular": "mārc."
    },
    "sachet": {
      "empty": "maisiņš",
      "plural": "maisiņš",
      "singular": "maisiņš"
    },
    "scoop": {
      "empty": "mērkar.",
      "plural": "mērkar.",
      "singular": "mērkar."
    },
    "shot": {
      "empty": "malki",
      "plural": "malki",
      "singular": "malks"
    },
    "slice": {
      "empty": "šķēles",
      "plural": "šķēles",
      "singular": "šķēle"
    },
    "stick": {
      "empty": "standz.",
      "plural": "standz.",
      "singular": "standz."
    },
    "tablespoon": {
      "empty": "ēd.k.",
      "plural": "ēd.k.",
      "singular": "ēd.k."
    },
    "teaspoon": {
      "empty": "tk.",
      "plural": "tk.",
      "singular": "tk."
    }
  }
}