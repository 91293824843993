{
  "ingredientUnit": {
    "bag": {
      "plural": "пакетики",
      "singular": "пакетик"
    },
    "branch": {
      "plural": "веточки",
      "singular": "веточка"
    },
    "bunch": {
      "plural": "пучки",
      "singular": "пучок"
    },
    "can": {
      "plural": "банки",
      "singular": "банка"
    },
    "centiliter": {
      "plural": "сантилитры",
      "singular": "сантилитр"
    },
    "clove": {
      "plural": "головки",
      "singular": "головка"
    },
    "cup": {
      "plural": "чашки",
      "singular": "чашка"
    },
    "dash": {
      "plural": "щепотки",
      "singular": "щепотка"
    },
    "decigram": {
      "plural": "дециграммы",
      "singular": "дециграмм"
    },
    "deciliter": {
      "plural": "децилитры",
      "singular": "децилитр"
    },
    "drop": {
      "plural": "капли",
      "singular": "капля"
    },
    "fluidOunce": {
      "plural": "жидкие унции",
      "singular": "жидкая унция (30мл)"
    },
    "gram": {
      "plural": "граммы",
      "singular": "грамм"
    },
    "kilogram": {
      "plural": "килограммы",
      "singular": "килограмм"
    },
    "liter": {
      "plural": "литры",
      "singular": "литр"
    },
    "microgram": {
      "plural": "микрограммы",
      "singular": "микрограмм"
    },
    "milligram": {
      "plural": "миллиграммы",
      "singular": "миллиграмм"
    },
    "milliliter": {
      "plural": "миллилитры",
      "singular": "миллилитр"
    },
    "ounce": {
      "plural": "унция (28г)",
      "singular": "унции"
    },
    "package": {
      "plural": "упаковки / пакеты / пачки",
      "singular": "упаковка / пакет / пачка"
    },
    "piece": {
      "plural": "кусочки",
      "singular": "кусочек"
    },
    "pinch": {
      "plural": "щепотки",
      "singular": "щепотка"
    },
    "pound": {
      "plural": "фунты",
      "singular": "фунт (0,45кг = 16 унций)"
    },
    "sachet": {
      "plural": "саше",
      "singular": "саше"
    },
    "shot": {
      "plural": "шоты",
      "singular": "шот (45мл)"
    },
    "slice": {
      "plural": "слайсы / ломтики",
      "singular": "слайс / ломтик"
    },
    "stick": {
      "plural": "палочки",
      "singular": "палочка"
    },
    "tablespoon": {
      "plural": "столовые ложки",
      "singular": "столовая ложка"
    },
    "teaspoon": {
      "plural": "чайные ложки",
      "singular": "чайная ложка"
    }
  }
}