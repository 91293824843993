{
	"_embedded": {
		"item": [
			{
				"_links": {
					"calorieBreakdown": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=CALORIES,CARBOHYDRATES,FAT,PROTEIN,ALCOHOL,ORGANIC_ACIDS&valuePer=SERVING"
					},
					"country": {
						"href": "https://dev.backend.ka.philips.com/api/Country/00000000-0000-0000-0000-000000000000"
					},
					"energyBreakdown": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=CALORIES,CARBOHYDRATES,SUGAR,DIETARY_FIBER,FAT,SATURATED_FAT,UNSATURATED_FATTY_ACIDS,OMEGA_3_FATTY_ACIDS,PROTEIN,SODIUM,SALT{&valuePer}"
					},
					"nutrientsBreakdown": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=VITAMIN_A,VITAMIN_B1,VITAMIN_B2,VITAMIN_B3,VITAMIN_B5,VITAMIN_B6,VITAMIN_B7,FOLIC_ACID,VITAMIN_B12,VITAMIN_C,VITAMIN_D,VITAMIN_E,VITAMIN_K,CALCIUM,IRON,MAGNESIUM,POTASSIUM,ZINC{&valuePer}"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/00000000-0000-0000-0000-000000000000/Nutrition?page=1&size=5"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/00000000-0000-0000-0000-000000000000/Nutrition?page=10&size=5"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/00000000-0000-0000-0000-000000000000/Nutrition?page=4&size=5"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/00000000-0000-0000-0000-000000000000/Nutrition?page=2&size=5"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/00000000-0000-0000-0000-000000000000/Nutrition?page=3&size=5"
		}
	}
}
