{
  "action": {
    "assignUnassignToMyCountryProfile": "Zu meinem Länder-Profil hinzufügen/entfernen"
  },
  "button": {
    "cancel": "Abbrechen",
    "close": "Schliessen",
    "ok": "Ok"
  },
  "common": {
    "consumer": {
      "plural": "Konsumenten",
      "singular": "Konsument"
    },
    "country": {
      "singular": "Land"
    },
    "recipe": {
      "byConsumer": "Von den Konsumenten",
      "byPhilips": "Von Philips",
      "plural": "Rezepte"
    }
  },
  "dashboard": {
    "statistics": {
      "header": {
        "active": "Aktiv",
        "all": "Alle",
        "live": "Live",
        "other": "Andere"
      },
      "subtitle": "Hier ist was sich in der HomeID Community getan hat",
      "title": "Willkommen, {{ name }}!",
      "total": "TOTAL"
    }
  },
  "deviceFamily": {
    "airfryer": "Airfryer"
  },
  "error": {
    "generic": "Fehler",
    "http_401": "Unbefugt",
    "http_403": "Verboten",
    "isRequired": "{{ item }} ist erforderlich\n",
    "itemExists": "Eintrag existiert bereits"
  },
  "ingredient": {
    "choose": "Zutat auswählen",
    "mergeModelTitle": "Zutat “{{ ingredient }}” ersetzten mit ",
    "nameOrId": "Zutatenname oder ID",
    "noResults": "Keine Zutaten gefunden",
    "replace": "Ersetzen",
    "replaceWith": "Ersetzen mit ...",
    "singular": "Zutat"
  },
  "ingredientUnit": {
    "bag": {
      "plural": "Packungen",
      "singular": "Packung"
    },
    "branch": {
      "plural": "Zweige",
      "singular": "Zweig"
    },
    "bunch": {
      "plural": "Bündel",
      "singular": "Bündel"
    },
    "can": {
      "plural": "Dosen",
      "singular": "Dose"
    },
    "centiliter": {
      "plural": "Zentiliter",
      "singular": "Zentiliter"
    },
    "clove": {
      "plural": "Zehen",
      "singular": "Zehe"
    },
    "cup": {
      "plural": "Tassen",
      "singular": "Tasse"
    },
    "dash": {
      "plural": "Spritzer",
      "singular": "Spritzer"
    },
    "decigram": {
      "plural": "Dezigramm",
      "singular": "Dezigramm"
    },
    "deciliter": {
      "plural": "Deziliter",
      "singular": "Deziliter"
    },
    "drop": {
      "plural": "Tropfen",
      "singular": "Tropfen"
    },
    "gram": {
      "plural": "Gramm",
      "singular": "Gramm"
    },
    "kilogram": {
      "plural": "Kilogramm",
      "singular": "Kilogramm"
    },
    "liter": {
      "plural": "Liter",
      "singular": "Liter"
    },
    "milligram": {
      "plural": "Milligramm",
      "singular": "Milligramm"
    },
    "milliliter": {
      "plural": "Milliliter",
      "singular": "Milliliter"
    },
    "ounce": {
      "plural": "Unzen",
      "singular": "Unze"
    },
    "package": {
      "plural": "Packungen",
      "singular": "Packung"
    },
    "piece": {
      "plural": "Stücke",
      "singular": "Stück"
    },
    "pinch": {
      "plural": "Prisen",
      "singular": "Prise"
    },
    "sachet": {
      "plural": "Päckchen",
      "singular": "Päckchen"
    },
    "slice": {
      "plural": "Scheiben",
      "singular": "Scheibe"
    },
    "tablespoon": {
      "plural": "Esslöffel",
      "singular": "Esslöffel"
    },
    "teaspoon": {
      "plural": "Teelöffel",
      "singular": "Teelöffel"
    }
  },
  "language": {
    "choose": "Sprache auswählen",
    "singular": "Sprache"
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Ja",
        "label": "Startrezepte",
        "notEasyToStart": "Nein",
        "placeholder": "Startrezepte"
      }
    }
  },
  "role": {
    "contentManager": "Content manager",
    "globalContentManager": "Global content manager",
    "systemAdministrator": "System administrator"
  },
  "unknownTranslationFor": {
    "ingredient": "Unbekannte Übersetzung für Zutat {{id}}"
  },
  "warning": {
    "iedge": {
      "description": "Es scheint, Sie verwenden Internet Explorer oder Edge Browser. Die Anwendung kann sich nicht wie erwartet verhalten.\n\n<br> <br>\n\nWenn Sie Probleme haben, versuchen Sie es bitte mit Google Chrome oder Mozilla Firefox.",
      "title": "Internet Explorer / Edge"
    }
  }
}