{
	"title": "Pineapple spinach smoothie - title",
	"description": "super pineapple",
	"keywords": "tasty,low carbs",
	"language": "en-US",
	"createdAt": "2018-02-15T19:31:31.342Z",
	"modifiedAt": "2019-07-24T06:23:47.317172Z",
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/RecipeTranslation/recipeTranslationId213434233"
		}
	}
}
