{
  "ingredientUnit": {
    "bag": {
      "plural": "buste",
      "singular": "busta"
    },
    "branch": {
      "plural": "rametti",
      "singular": "rametto"
    },
    "bunch": {
      "plural": "mazzetti",
      "singular": "mazzetto"
    },
    "can": {
      "plural": "lattine",
      "singular": "lattina"
    },
    "centiliter": {
      "plural": "centilitri",
      "singular": "centilitro"
    },
    "clove": {
      "plural": "spicchi",
      "singular": "spicchio"
    },
    "cup": {
      "plural": "tazze",
      "singular": "tazza"
    },
    "dash": {
      "plural": "pizzichi",
      "singular": "pizzico"
    },
    "decigram": {
      "plural": "decigrammi",
      "singular": "decigrammo"
    },
    "deciliter": {
      "plural": "decilitri",
      "singular": "decilitro"
    },
    "drop": {
      "plural": "gocce",
      "singular": "goccia"
    },
    "gram": {
      "plural": "grammi",
      "singular": "grammo"
    },
    "kilogram": {
      "plural": "chilogrammi",
      "singular": "chilogrammo"
    },
    "liter": {
      "plural": "litri",
      "singular": "litro"
    },
    "milligram": {
      "plural": "milligrammi",
      "singular": "milligrammo"
    },
    "milliliter": {
      "plural": "millilitri",
      "singular": "millilitro"
    },
    "ounce": {
      "plural": "once",
      "singular": "oncia"
    },
    "package": {
      "plural": "confezioni",
      "singular": "confezione"
    },
    "piece": {
      "plural": "pezzi",
      "singular": "pezzo"
    },
    "pinch": {
      "plural": "pizzichi",
      "singular": "pizzico"
    },
    "sachet": {
      "plural": "bustine",
      "singular": "bustina"
    },
    "slice": {
      "plural": "fette",
      "singular": "fetta"
    },
    "tablespoon": {
      "plural": "cucchiai",
      "singular": "cucchiaio"
    },
    "teaspoon": {
      "plural": "cucchiaini",
      "singular": "cucchiaino"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Sì",
        "label": "Per principianti",
        "notEasyToStart": "No",
        "placeholder": "Per principianti"
      }
    }
  }
}