{
	"_embedded": {
		"media": {
			"url": "https://dev.backend.ka.philips.com/media/cf-s3-946d1bae-3585-46b4-9b4b-70ebb40e8a9b/fd1c3d6b-6430-42b2-bad5-fc87f49c13c6.jpg",
			"availableSizes": {
				"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-946d1bae-3585-46b4-9b4b-70ebb40e8a9b/fd1c3d6b-6430-42b2-bad5-fc87f49c13c6_thumbnail.jpg",
				"high": "https://dev.backend.ka.philips.com/media/cf-s3-946d1bae-3585-46b4-9b4b-70ebb40e8a9b/fd1c3d6b-6430-42b2-bad5-fc87f49c13c6_high.jpg",
				"medium": "https://dev.backend.ka.philips.com/media/cf-s3-946d1bae-3585-46b4-9b4b-70ebb40e8a9b/fd1c3d6b-6430-42b2-bad5-fc87f49c13c6_medium.jpg"
			},
			"_links": {
				"self": {
					"href": "https://dev.backend.ka.philips.com/api/Media/9c0bce82-12f7-49c7-9a65-6d17013cc293"
				},
				"collection": {
					"href": "https://dev.backend.ka.philips.com/api/Media"
				}
			}
		}
	},
	"shortId": "MR-F040EFF1",
	"name": "Pineapple spinach smoothie",
	"status": "DRAFT",
	"nutritionalInfoComment": "te",
	"createdAt": "2018-02-15T19:31:31.342Z",
	"modifiedAt": "2019-07-24T06:23:47.317172Z",
	"_links": {
		"author": {
			"href": "https://dev.backend.ka.philips.com/api/User/7a171d0e-76b6-4d80-9658-b49e8e513121"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2"
		},
		"collection": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe"
		},
		"media": {
			"href": "https://dev.backend.ka.philips.com/api/Media/9c0bce82-12f7-49c7-9a65-6d17013cc293"
		},
		"domain": {
			"href": "https://dev.backend.ka.philips.com/api/Domain/mockDomainId655748"
		},
		"translations": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation"
		},
		"ingredients": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient"
		},
		"categories": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Category"
		},
		"steps": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step"
		}
	}
}
