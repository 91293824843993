{
	"_embedded": {
		"item": [
			{
				"_embedded": {
					"nutrient": {
						"_links": {
							"self": {
								"href": "http://0.0.0.0:5000/api/Nutrient/00000000-0000-0000-0000-000000000000"
							}
						},
						"code": "VITAMIN_B1",
						"name": "Vitamin B1",
						"synonym": "Vitamin B1 synonym",
						"unit": "GRAM"
					}
				},
				"_links": {
					"nutrient": {
						"href": "http://0.0.0.0:5000/api/Nutrient/00000000-0000-0000-0000-000000000000"
					},
					"phenotype": {
						"href": "http://0.0.0.0:5000/api/Phenotype/00000000-0000-0000-0000-000000000000"
					},
					"self": {
						"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/Information/00000000-0000-0000-0000-000000000000"
					}
				},
				"dailyValue": {
					"unit": "PERCENT",
					"value": 50
				},
				"energyValue": {
					"calorieValue": 187,
					"percentValue": 60
				},
				"unit": "GRAM",
				"value": 250
			}
		]
	},
	"_links": {
		"first": {
			"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?page=1&size=5"
		},
		"item": [
			{
				"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information"
			}
		],
		"last": {
			"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?page=10&size=5"
		},
		"next": {
			"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?page=4&size=5"
		},
		"prev": {
			"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?page=2&size=5"
		},
		"self": {
			"href": "http://0.0.0.0:5000/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?page=3&size=5"
		}
	}
}
