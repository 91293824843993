{
  "ingredientUnit": {
    "bag": {
      "empty": "zacskó",
      "plural": "zacskó",
      "singular": "zacskó"
    },
    "branch": {
      "empty": "ág",
      "plural": "ág",
      "singular": "ág"
    },
    "bunch": {
      "empty": "csomó",
      "plural": "csomó",
      "singular": "csomó"
    },
    "can": {
      "empty": "konzerv",
      "plural": "konzerv",
      "singular": "konzerv"
    },
    "centiliter": {
      "empty": "centiliter",
      "plural": "centiliter",
      "singular": "centiliter"
    },
    "clove": {
      "empty": "gerezd",
      "plural": "gerezd",
      "singular": "gerezd"
    },
    "cup": {
      "empty": "csésze",
      "plural": "csésze",
      "singular": "csésze"
    },
    "dash": {
      "empty": "hintés",
      "plural": "hintés",
      "singular": "hintés"
    },
    "decigram": {
      "empty": "decigramm",
      "plural": "decigramm",
      "singular": "decigramm"
    },
    "deciliter": {
      "empty": "deciliter",
      "plural": "deciliter",
      "singular": "deciliter"
    },
    "drop": {
      "empty": "csepp",
      "plural": "csepp",
      "singular": "csepp"
    },
    "fluidOunce": {
      "empty": "folyadék uncia",
      "plural": "folyadék uncia",
      "singular": "folyadék uncia"
    },
    "gram": {
      "empty": "gramm",
      "plural": "gramm",
      "singular": "gramm"
    },
    "kilogram": {
      "empty": "kilogramm",
      "plural": "kilogramm",
      "singular": "kilogramm"
    },
    "liter": {
      "empty": "liter",
      "plural": "liter",
      "singular": "liter"
    },
    "microgram": {
      "empty": "mikrogramm",
      "plural": "mikrogramm",
      "singular": "mikrogramm"
    },
    "milligram": {
      "empty": "milligramm",
      "plural": "milligramm",
      "singular": "milligramm"
    },
    "milliliter": {
      "empty": "milliliter",
      "plural": "milliliter",
      "singular": "milliliter"
    },
    "ounce": {
      "empty": "uncia",
      "plural": "uncia",
      "singular": "uncia"
    },
    "package": {
      "plural": "csomag",
      "singular": "csomag"
    },
    "piece": {
      "empty": "darab",
      "plural": "darab",
      "singular": "darab"
    },
    "pinch": {
      "empty": "csipet",
      "plural": "csipet",
      "singular": "csipet"
    },
    "pound": {
      "empty": "font",
      "plural": "font",
      "singular": "font"
    },
    "sachet": {
      "empty": "tasak",
      "plural": "tasak",
      "singular": "tasak"
    },
    "shot": {
      "empty": "kupica",
      "plural": "kupica",
      "singular": "kupica"
    },
    "slice": {
      "empty": "szelet",
      "plural": "szelet",
      "singular": "szelet"
    },
    "stick": {
      "empty": "szál",
      "plural": "szál",
      "singular": "szál"
    },
    "tablespoon": {
      "empty": "evőkanál",
      "plural": "evőkanál",
      "singular": "evőkanál"
    },
    "teaspoon": {
      "empty": "teáskanál",
      "plural": "teáskanál",
      "singular": "teáskanál"
    }
  }
}