{
  "ingredientUnit": {
    "bag": {
      "plural": "袋",
      "singular": "袋"
    },
    "branch": {
      "plural": "条",
      "singular": "条"
    },
    "bunch": {
      "plural": "束",
      "singular": "束"
    },
    "can": {
      "plural": "罐",
      "singular": "罐"
    },
    "centiliter": {
      "plural": "厘升",
      "singular": "厘升"
    },
    "clove": {
      "plural": "瓣",
      "singular": "瓣"
    },
    "cup": {
      "plural": "杯",
      "singular": "杯"
    },
    "dash": {
      "plural": "些许",
      "singular": "些许"
    },
    "decigram": {
      "plural": "分克",
      "singular": "分克"
    },
    "deciliter": {
      "plural": "分升",
      "singular": "分升"
    },
    "drop": {
      "plural": "滴",
      "singular": "滴"
    },
    "gram": {
      "plural": "克",
      "singular": "克"
    },
    "kilogram": {
      "plural": "千克",
      "singular": "千克"
    },
    "liter": {
      "plural": "公升",
      "singular": "公升"
    },
    "milligram": {
      "plural": "毫克",
      "singular": "毫克"
    },
    "milliliter": {
      "plural": "毫升",
      "singular": "毫升"
    },
    "ounce": {
      "plural": "盎司",
      "singular": "盎司"
    },
    "package": {
      "plural": "包",
      "singular": "包"
    },
    "piece": {
      "plural": "片",
      "singular": "片"
    },
    "pinch": {
      "plural": "撮",
      "singular": "撮"
    },
    "sachet": {
      "plural": "小袋",
      "singular": "小袋"
    },
    "slice": {
      "plural": "薄片",
      "singular": "薄片"
    },
    "tablespoon": {
      "plural": "汤匙",
      "singular": "汤匙"
    },
    "teaspoon": {
      "plural": "茶匙",
      "singular": "茶匙"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "是",
        "label": "容易开始",
        "notEasyToStart": "否",
        "placeholder": "容易开始"
      }
    }
  }
}