{
	"code": "DE",
	"languages": [
		{
			"language": "de-AT",
			"languageOfOrigin": "de-DE",
			"fallbacks": ["en-GB", "en-US"]
		},
		{
			"language": "en-GB"
		}
	],
	"unitSystem": "METRIC",
	"enabled": true,
	"communityEnabled": true,
	"deviceRegion": "WO",
	"marketingOptIn": true,
	"name": "Germany",
	"features": {
		"devices": [
			"AIR_COOKER",
			"AIRFRYER",
			"ALL_IN_ONE_COOKER",
			"KITCHEN_MACHINE",
			"BLENDER_AND_JUICER"
		]
	},
	"electricSystem": "220V",
	"timeZone": "Europe/Berlin",
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Country/c0000001-0000-0000-0000-cc0000000000"
		},
		"collection": {
			"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Country"
		}
	}
}
