/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject } from '@angular/core';
import { WINDOW_LOCATION_TOKEN } from '../../services/window-location/window-location.service';

export const IsSecureRouteGuard = () => {
	const location = inject(WINDOW_LOCATION_TOKEN);

	const isDev = location.hostname === 'localhost';
	const isRouteSecure = location.protocol === 'https:';

	if (!isDev && !isRouteSecure) {
		location.href = `https:${location.href.substring(location.protocol.length)}`;
	}

	return isDev || isRouteSecure;
};
