{
	"_embedded": {
		"item": [
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/6cad367a-d9ae-4cac-989b-5524ef63e48e"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING",
				"electricSystem": "220V",
				"name": "Pressure cooking",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:0fc17f15-1b3a-4667-8a11-9da7b7967485",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/126aabe2-46a0-452e-9405-c7551781fa1f"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/6cad367a-d9ae-4cac-989b-5524ef63e48e"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/0c1ac88c-ef54-401b-b369-57abe9a25659_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/6cad367a-d9ae-4cac-989b-5524ef63e48e"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING",
				"electricSystem": "220V",
				"name": "Pressure cooking Duplicate",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:00017f15-1b3a-4667-8a11-9da7b7967485",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/126aabe2-46a0-452e-9405-c7551781fa1f"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/6cad367a-d9ae-4cac-989b-5524ef63e48e"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/00017f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d02624ec-3727-40de-a68b-cd96ff9d1a36.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d02624ec-3727-40de-a68b-cd96ff9d1a36_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d02624ec-3727-40de-a68b-cd96ff9d1a36_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d02624ec-3727-40de-a68b-cd96ff9d1a36_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/d52d7841-bb80-4410-9fa3-dadd66f3ed86"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW",
				"electricSystem": "220V",
				"name": "Slow cook Low",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:883da320-453e-416f-bf90-b7433a624c64",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/ba3f5c9c-c045-4d12-bfff-cc899ea2cd07"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/d52d7841-bb80-4410-9fa3-dadd66f3ed86"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/9ed574f1-3df9-4290-95bf-1da940980a18.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/9ed574f1-3df9-4290-95bf-1da940980a18_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/9ed574f1-3df9-4290-95bf-1da940980a18_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/9ed574f1-3df9-4290-95bf-1da940980a18_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/3390f6ae-cbf8-4a78-9204-c29eb1fb44f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH",
				"electricSystem": "220V",
				"name": "Slow cook High",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:496f2889-4f8b-4ddf-8591-bb23d47783b9",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/283f30ef-a8d5-45b0-8f46-cba40d2644ff"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/3390f6ae-cbf8-4a78-9204-c29eb1fb44f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e416f1ae-8a27-40ac-b3e7-41ac9def60be.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e416f1ae-8a27-40ac-b3e7-41ac9def60be_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e416f1ae-8a27-40ac-b3e7-41ac9def60be_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e416f1ae-8a27-40ac-b3e7-41ac9def60be_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/500088e5-347d-4fcc-bef9-f1c91d5ceb4b"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING",
				"electricSystem": "220V",
				"name": "Steam cooking",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:9d6b271e-c328-4e61-9236-641200f6a125",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/e69a9eec-2d11-4596-8bbe-80e471d21ee4"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/500088e5-347d-4fcc-bef9-f1c91d5ceb4b"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/09a64d70-6bed-4d3d-ac33-00f44ec3c488.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/09a64d70-6bed-4d3d-ac33-00f44ec3c488_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/09a64d70-6bed-4d3d-ac33-00f44ec3c488_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/09a64d70-6bed-4d3d-ac33-00f44ec3c488_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/4d16a4c9-a4ad-40fc-a6fc-448d78492c93"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES",
				"electricSystem": "220V",
				"name": "Special recipes",
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/0f84684e-0cc4-4e17-a34f-b52a5754592a"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/4d16a4c9-a4ad-40fc-a6fc-448d78492c93"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/2498b0b7-73d5-4a49-bbd7-39ebc6a0c746"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SAUTE",
				"electricSystem": "220V",
				"name": "Sauté",
				"time": { "min": 600, "max": 3540, "adjustable": true, "default": 600 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:224692d1-ec04-416d-8936-33e047904ef3",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/a68e10a1-7ac4-4361-8b2c-31e348764a52"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/2498b0b7-73d5-4a49-bbd7-39ebc6a0c746"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/224692d1-ec04-416d-8936-33e047904ef3?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7aa356c2-8356-4262-97c2-559b5d3bc010_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/2498b0b7-73d5-4a49-bbd7-39ebc6a0c746"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SAUTE",
				"electricSystem": "220V",
				"name": "Sauté Duplicate",
				"time": { "min": 600, "max": 3540, "adjustable": true, "default": 600 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:111692d1-ec04-416d-8936-33e047904ef3",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/a68e10a1-7ac4-4361-8b2c-31e348764a52"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/2498b0b7-73d5-4a49-bbd7-39ebc6a0c746"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/111692d1-ec04-416d-8936-33e047904ef3?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3a2225ee-e900-487d-8d15-565c2c61b070.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3a2225ee-e900-487d-8d15-565c2c61b070_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3a2225ee-e900-487d-8d15-565c2c61b070_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3a2225ee-e900-487d-8d15-565c2c61b070_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/e6b5165a-95c1-4f15-9b1e-b910da7aef4e"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SAUCE_THICKENING",
				"electricSystem": "220V",
				"name": "Sauce thickening",
				"time": { "min": 300, "max": 900, "adjustable": true, "default": 480 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:61839461-a4c5-4fe6-bbcd-56478c60fdae",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/80629f0e-5d90-4950-a1d3-359c69c29c38"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/e6b5165a-95c1-4f15-9b1e-b910da7aef4e"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/61839461-a4c5-4fe6-bbcd-56478c60fdae?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_BEEF",
				"electricSystem": "220V",
				"name": "Beef",
				"time": { "min": 180, "max": 3540, "adjustable": true, "default": 1800 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 70.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:a4a38eaa-2149-4ce0-8956-db2cc3599559",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/e760835a-133e-4605-beda-87a98e1faba1"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a4a38eaa-2149-4ce0-8956-db2cc3599559?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_POULTRY",
				"electricSystem": "220V",
				"name": "Poultry",
				"time": { "min": 120, "max": 3540, "adjustable": true, "default": 900 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 60.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:4f691071-e5af-48e6-b873-66d21abf343f",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/15d58455-70d6-4315-8443-f3d8f9d77716"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/4f691071-e5af-48e6-b873-66d21abf343f?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_PORK",
				"electricSystem": "220V",
				"name": "Pork",
				"time": { "min": 120, "max": 3540, "adjustable": true, "default": 900 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 70.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:132fa4b6-c6df-4829-9ce3-87af253431a4",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/591a6cba-21e4-4e5e-b50b-3ec1308e7c3b"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/132fa4b6-c6df-4829-9ce3-87af253431a4?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_PORK",
				"electricSystem": "220V",
				"name": "Pork Duplicate",
				"time": { "min": 120, "max": 3540, "adjustable": true, "default": 900 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 70.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:000fa4b6-c6df-4829-9ce3-87af253431a4",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/591a6cba-21e4-4e5e-b50b-3ec1308e7c3b"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/000fa4b6-c6df-4829-9ce3-87af253431a4?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/00017f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_LAMB",
				"electricSystem": "220V",
				"name": "Lamb",
				"time": { "min": 180, "max": 3540, "adjustable": true, "default": 1200 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 70.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:724cdcdd-c802-4513-aa63-0b2bbb373639",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/5282c32f-5ddf-4238-b388-7b76e2d12d5b"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/724cdcdd-c802-4513-aa63-0b2bbb373639?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_SEAFOOD",
				"electricSystem": "220V",
				"name": "Seafood",
				"time": { "min": 120, "max": 3540, "adjustable": true, "default": 180 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 40.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:1f18c73f-5666-4621-8ed1-c0fb2b8d5350",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/ca94f464-60ec-40e6-b656-39320e01e6ef"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/1f18c73f-5666-4621-8ed1-c0fb2b8d5350?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "PRESSURE_COOKING_MANUAL",
				"electricSystem": "220V",
				"name": "Manual",
				"time": { "min": 120, "max": 3540, "adjustable": true, "default": 300 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 20.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:cb81853f-cf98-4f43-9003-c846dae60eb9",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/27772edc-4026-4d9c-b63f-7172add8cc38"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/cb81853f-cf98-4f43-9003-c846dae60eb9?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_BEEF",
				"electricSystem": "220V",
				"name": "Beef",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 43200 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:55fdd7cd-0e5d-41c5-a44a-a09ec827e9f0",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/0b60c8ed-63df-4ba5-9da2-72a72b65d22d"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/55fdd7cd-0e5d-41c5-a44a-a09ec827e9f0?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_POULTRY",
				"electricSystem": "220V",
				"name": "Poultry",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 36000 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:8e129d24-b0df-4152-ba36-0f9132a6c21c",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/62d3a61a-e317-4387-8d95-337966b205a4"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/8e129d24-b0df-4152-ba36-0f9132a6c21c?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_PORK",
				"electricSystem": "220V",
				"name": "Pork",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 36000 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:d19184ac-31e6-4567-82d4-2261ea5d214b",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/29cc70cf-3204-4826-87fe-b5e97eb24988"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/d19184ac-31e6-4567-82d4-2261ea5d214b?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_LAMB",
				"electricSystem": "220V",
				"name": "Lamb",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 36000 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:fa3e0cb4-8f7c-41ec-9001-e49e94a7b677",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/fd1f604d-a722-42d8-ac0b-f7b1845dc398"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/fa3e0cb4-8f7c-41ec-9001-e49e94a7b677?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_SEAFOOD",
				"electricSystem": "220V",
				"name": "Seafood",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 28800 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:126d65fe-6f16-4115-8d73-019700e813f3",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/f9387de8-0281-48f0-9ca0-58f531497b20"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/126d65fe-6f16-4115-8d73-019700e813f3?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_LOW_MANUAL",
				"electricSystem": "220V",
				"name": "Manual",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 7200 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:646fd7a9-b781-46c0-8b16-c53f7d4507c1",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/6bcaf5ec-224f-4f9b-a4a6-8e9947b98d71"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/646fd7a9-b781-46c0-8b16-c53f7d4507c1?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_BEEF",
				"electricSystem": "220V",
				"name": "Beef",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 21600 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:08d8f018-5808-4d48-918c-a26896a3c28d",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/abb32e7b-5250-455d-820f-d3af76484384"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/08d8f018-5808-4d48-918c-a26896a3c28d?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_POULTRY",
				"electricSystem": "220V",
				"name": "Poultry",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 14400 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:a8bd2281-b2ee-44bb-90f6-0d338008b5c4",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/a5e4fef1-bff8-4a6f-af18-b34c250fffd0"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a8bd2281-b2ee-44bb-90f6-0d338008b5c4?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_PORK",
				"electricSystem": "220V",
				"name": "Pork",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 14400 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:18d9b69c-8bd1-4651-bb18-ef9551b7a20e",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/d11f6382-bf93-44c4-9df2-1218d1626890"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/18d9b69c-8bd1-4651-bb18-ef9551b7a20e?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_LAMB",
				"electricSystem": "220V",
				"name": "Lamb",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 14400 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:780a0b5d-c7f3-4418-b179-b35be03805b5",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/1245222c-3af9-459b-b7d0-b062c6ea0187"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/780a0b5d-c7f3-4418-b179-b35be03805b5?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_SEAFOOD",
				"electricSystem": "220V",
				"name": "Seafood",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 7200 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:3cb50e2b-cf81-4fda-92fd-4da7529d082f",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/8ae0cba4-b956-4ea6-8a0a-a5791624c610"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/3cb50e2b-cf81-4fda-92fd-4da7529d082f?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SLOW_COOK_HIGH_MANUAL",
				"electricSystem": "220V",
				"name": "Manual",
				"time": { "min": 7200, "max": 43200, "adjustable": true, "default": 7200 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:a1a2e08b-71e9-4230-8fdb-174c4b7e351b",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/41a4a3a5-3874-452d-b874-6db74ecbcb9d"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a1a2e08b-71e9-4230-8fdb-174c4b7e351b?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bbb6b085-a340-4549-9613-ac75d0f5dc05_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_BEEF",
				"electricSystem": "220V",
				"name": "Beef",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 1800 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:4b6df7a1-dbcc-46a2-8925-99693240cd09",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/5782d92b-f5cb-4ab8-910d-b1500c24f149"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/5e78be16-3eb2-4d56-abcb-d561906e3cb2"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/4b6df7a1-dbcc-46a2-8925-99693240cd09?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/58461793-af86-4a58-a695-919a418b643e_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_POULTRY",
				"electricSystem": "220V",
				"name": "Poultry",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 1200 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:43ee629c-0ba6-4cd6-87e6-219a9d46dbd7",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/3c3686aa-c52d-47ea-89f1-30ba978d417a"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/73de2962-88a8-478f-9c90-e1436ad758ab"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/43ee629c-0ba6-4cd6-87e6-219a9d46dbd7?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/417c3db8-232c-415a-9a2c-f0a082b78dff_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_PORK",
				"electricSystem": "220V",
				"name": "Pork",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 1800 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:8783a704-7e74-4376-8dd5-ae3cab7e7065",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/c20ee076-6c50-421a-8ad9-ce12434533c2"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7d2538fb-a687-4ec1-9e32-9225676114f0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/8783a704-7e74-4376-8dd5-ae3cab7e7065?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/719b0f45-d701-4740-9ba8-aaa8402c744f_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_LAMB",
				"electricSystem": "220V",
				"name": "Lamb",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 1800 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:9358aa32-9a02-44a8-af0c-b4505cd0032b",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/a471b79f-256d-48b7-9ca3-ceace4c02db8"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ed5594e2-d7c1-4376-a1e5-355cd62f67e9"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9358aa32-9a02-44a8-af0c-b4505cd0032b?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/26b57b1e-5fac-4c78-96bd-438ecc078fa0_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_SEAFOOD",
				"electricSystem": "220V",
				"name": "Seafood",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 480 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:c99431f6-d9fe-4c6a-b089-05a52c656f1e",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/26465b91-fd02-4413-96e8-526bc3eed3e7"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/fdaae513-5d52-4e11-a3a6-ad46ceb2e110"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/c99431f6-d9fe-4c6a-b089-05a52c656f1e?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f1672684-ba93-4800-b090-4d92cb706674_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "STEAM_COOKING_MANUAL",
				"electricSystem": "220V",
				"name": "Manual",
				"time": { "min": 180, "max": 1800, "adjustable": true, "default": 600 },
				"pressure": { "min": 10.0, "max": 10.0, "adjustable": false, "default": 10.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:1149fc8d-b981-4e58-b7fa-bdc2be8701da",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/4e6a0820-28f1-4bdd-acca-e29ccdf55b7e"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/c65f0ed1-8dd9-40a9-b28a-861b7fe0fffe"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/1149fc8d-b981-4e58-b7fa-bdc2be8701da?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/833f2940-a4ee-4492-9aa4-b98bfef67cf5.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/833f2940-a4ee-4492-9aa4-b98bfef67cf5_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/833f2940-a4ee-4492-9aa4-b98bfef67cf5_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/833f2940-a4ee-4492-9aa4-b98bfef67cf5_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/6fbe9af6-6b4b-41d4-b12c-08b233718bec"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_RICE",
				"electricSystem": "220V",
				"name": "Rice",
				"time": { "min": 840, "max": 840, "adjustable": false, "default": 840 },
				"pressure": { "min": 30.0, "max": 30.0, "adjustable": false, "default": 30.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:64053bb8-e3b4-4c29-8484-dcb0359c4435",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/a73bd2d9-ea5b-435e-883f-3e6fcc320a6d"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/6fbe9af6-6b4b-41d4-b12c-08b233718bec"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/64053bb8-e3b4-4c29-8484-dcb0359c4435?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e1940ef1-895f-4237-a9a5-5dfe1791580a.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e1940ef1-895f-4237-a9a5-5dfe1791580a_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e1940ef1-895f-4237-a9a5-5dfe1791580a_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/e1940ef1-895f-4237-a9a5-5dfe1791580a_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ca45aa99-1a64-4938-a0c6-d4d9cb951eb0"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_FRIED_RICE",
				"electricSystem": "220V",
				"name": "Fried rice",
				"time": { "min": 660, "max": 660, "adjustable": false, "default": 660 },
				"pressure": { "min": 30.0, "max": 30.0, "adjustable": false, "default": 30.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:63bd6619-a6e3-46d9-aa0d-e7779fa708bb",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/86ea7b9b-440b-411f-83cc-784daabae7e4"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ca45aa99-1a64-4938-a0c6-d4d9cb951eb0"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/63bd6619-a6e3-46d9-aa0d-e7779fa708bb?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ea41292c-b32c-461d-b13b-0b0b0d22f35c.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ea41292c-b32c-461d-b13b-0b0b0d22f35c_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ea41292c-b32c-461d-b13b-0b0b0d22f35c_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ea41292c-b32c-461d-b13b-0b0b0d22f35c_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/22a44208-bca9-4c21-80e6-c20b39c24557"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_SOUP",
				"electricSystem": "220V",
				"name": "Soup",
				"time": { "min": 1200, "max": 3540, "adjustable": true, "default": 1200 },
				"pressure": {
					"min": 20.0,
					"max": 50.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 50.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:61dd4604-dcd4-461f-9bb3-14c27e9a48eb",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/5f92cad9-03bf-4eec-af64-a3792cd303b4"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/22a44208-bca9-4c21-80e6-c20b39c24557"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/61dd4604-dcd4-461f-9bb3-14c27e9a48eb?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ded717c1-bf2e-4445-9de8-fc7af4362986.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ded717c1-bf2e-4445-9de8-fc7af4362986_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ded717c1-bf2e-4445-9de8-fc7af4362986_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/ded717c1-bf2e-4445-9de8-fc7af4362986_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/927d150a-87c4-4f54-8ce0-dbf11601574f"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_BEANS",
				"electricSystem": "220V",
				"name": "Beans",
				"time": { "min": 1200, "max": 3540, "adjustable": true, "default": 1200 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 70.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:56342703-67ec-4bd8-bbd2-7d45e93a2ba5",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/c7edbcac-c101-4bc2-83e1-9b9f00362abb"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/927d150a-87c4-4f54-8ce0-dbf11601574f"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/56342703-67ec-4bd8-bbd2-7d45e93a2ba5?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ebd07f3-283b-41a6-a527-68ff7b75b7a4.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ebd07f3-283b-41a6-a527-68ff7b75b7a4_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ebd07f3-283b-41a6-a527-68ff7b75b7a4_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ebd07f3-283b-41a6-a527-68ff7b75b7a4_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/8f6d7a99-14f0-4dd7-9b53-86c722f6867d"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_PORRIDGE",
				"electricSystem": "220V",
				"name": "Porridge",
				"time": { "min": 900, "max": 3540, "adjustable": true, "default": 900 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 40.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:fb50701c-9ec0-40b8-84c1-a85c7e15ad16",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/edc7e418-11d8-4b90-9a93-0a6994822c78"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/8f6d7a99-14f0-4dd7-9b53-86c722f6867d"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/fb50701c-9ec0-40b8-84c1-a85c7e15ad16?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/791d6a68-6225-428d-9851-d633ea314462.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/791d6a68-6225-428d-9851-d633ea314462_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/791d6a68-6225-428d-9851-d633ea314462_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/791d6a68-6225-428d-9851-d633ea314462_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/05026fa1-b859-46ff-9032-f948ee6ec688"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_CABBAGE_ROLLS",
				"electricSystem": "220V",
				"name": "Cabbage rolls",
				"time": { "min": 600, "max": 1200, "adjustable": true, "default": 1080 },
				"pressure": { "min": 40.0, "max": 40.0, "adjustable": false, "default": 40.0 },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:2fd6e5b7-6102-4dc7-af9f-bbdc344aaa79",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/dbfb49e8-2ce1-48c2-ab5d-5752a46d358a"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/05026fa1-b859-46ff-9032-f948ee6ec688"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/2fd6e5b7-6102-4dc7-af9f-bbdc344aaa79?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d9e7eeef-2604-4c82-8467-ae0c829b9f60.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d9e7eeef-2604-4c82-8467-ae0c829b9f60_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d9e7eeef-2604-4c82-8467-ae0c829b9f60_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/d9e7eeef-2604-4c82-8467-ae0c829b9f60_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/f1105875-369d-4069-8187-58c39a059af2"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_KAYA",
				"electricSystem": "220V",
				"name": "Kaya",
				"time": { "min": 600, "max": 3540, "adjustable": true, "default": 840 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:2f4ee0ef-beed-44e9-9869-6decedcf1630",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/79b24fe8-dd02-42d3-8a30-50a49b1c71ac"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/f1105875-369d-4069-8187-58c39a059af2"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/2f4ee0ef-beed-44e9-9869-6decedcf1630?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f7b8c861-31f5-4eff-8b96-7a47e4aae32f.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f7b8c861-31f5-4eff-8b96-7a47e4aae32f_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f7b8c861-31f5-4eff-8b96-7a47e4aae32f_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/f7b8c861-31f5-4eff-8b96-7a47e4aae32f_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/123488ba-c847-4880-bd02-2e3358bc81d3"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_YOGURT",
				"electricSystem": "220V",
				"name": "Yogurt",
				"time": { "min": 21600, "max": 43200, "adjustable": true, "default": 28800 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:546c1757-7256-482f-85a4-c371a1213553",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/c69fc1b6-89aa-4dbd-96da-5edfc9b47b66"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/123488ba-c847-4880-bd02-2e3358bc81d3"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/546c1757-7256-482f-85a4-c371a1213553?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3f622484-f5af-445b-80e8-c2a5f39759ee.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3f622484-f5af-445b-80e8-c2a5f39759ee_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3f622484-f5af-445b-80e8-c2a5f39759ee_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/3f622484-f5af-445b-80e8-c2a5f39759ee_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/66f9d110-8095-444a-90fa-b16e3371d25c"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_TENDONS",
				"electricSystem": "220V",
				"name": "Tendons",
				"time": { "min": 900, "max": 3540, "adjustable": true, "default": 900 },
				"pressure": {
					"min": 20.0,
					"max": 70.0,
					"interval": 10.0,
					"adjustable": true,
					"default": 40.0
				},
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:732f276e-57dd-4d4a-8f8f-1c2df10fc837",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/2866175c-ce0b-4ea9-84b5-c44eaa68e3af"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/66f9d110-8095-444a-90fa-b16e3371d25c"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/732f276e-57dd-4d4a-8f8f-1c2df10fc837?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7f0969ef-b842-493d-9bbe-2d19777765c2.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7f0969ef-b842-493d-9bbe-2d19777765c2_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7f0969ef-b842-493d-9bbe-2d19777765c2_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7f0969ef-b842-493d-9bbe-2d19777765c2_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/b4eb1223-8d0a-48e8-ace6-80d9626d8c9a"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_JAM",
				"electricSystem": "220V",
				"name": "Jam",
				"time": { "min": 1500, "max": 3540, "adjustable": true, "default": 3300 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:cdf24ea7-4513-4de7-b311-13a2a345f606",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/8c8f83b7-e46f-42c1-a913-45e25226a620"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/b4eb1223-8d0a-48e8-ace6-80d9626d8c9a"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/cdf24ea7-4513-4de7-b311-13a2a345f606?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			},
			{
				"_embedded": {
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/5b5d9185-2f42-42b6-a392-1d92e34913d3.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/5b5d9185-2f42-42b6-a392-1d92e34913d3_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/5b5d9185-2f42-42b6-a392-1d92e34913d3_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/5b5d9185-2f42-42b6-a392-1d92e34913d3_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/cdb642b5-2ec8-41d1-9a70-16923132438e"
							},
							"collection": { "href": "https://dev.backend.ka.philips.com/api/Media" }
						}
					}
				},
				"type": "RECIPE",
				"category": "SPECIAL_RECIPES_CAKE",
				"electricSystem": "220V",
				"name": "Cake",
				"time": { "min": 2400, "max": 3540, "adjustable": true, "default": 2700 },
				"pressure": { "adjustable": false },
				"_links": {
					"self:getSelfByUUID": {
						"href": "cid:d610bbd3-3d8e-4c99-9120-347fd4dc4fbe",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"cookingMethodCategory": {
						"href": "https://dev.backend.ka.philips.com/api/Category/08098153-ce04-49cc-9506-b3c17a540f73"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/cdb642b5-2ec8-41d1-9a70-16923132438e"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/d610bbd3-3d8e-4c99-9120-347fd4dc4fbe?unitSystem=METRIC"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08?unitSystem=METRIC"
					}
				}
			}
		]
	},
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/CookingMethod?electricSystem=220V&unitSystem=METRIC&type=RECIPE&category=ALL_IN_ONE_COOKER&page=1&size=100"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/0fc17f15-1b3a-4667-8a11-9da7b7967485"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/883da320-453e-416f-bf90-b7433a624c64"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/496f2889-4f8b-4ddf-8591-bb23d47783b9"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9d6b271e-c328-4e61-9236-641200f6a125"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/509e7e1d-f62d-4b72-9f2a-db5e9d6c5f08"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/224692d1-ec04-416d-8936-33e047904ef3"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/61839461-a4c5-4fe6-bbcd-56478c60fdae"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a4a38eaa-2149-4ce0-8956-db2cc3599559"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/4f691071-e5af-48e6-b873-66d21abf343f"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/132fa4b6-c6df-4829-9ce3-87af253431a4"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/724cdcdd-c802-4513-aa63-0b2bbb373639"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/1f18c73f-5666-4621-8ed1-c0fb2b8d5350"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/cb81853f-cf98-4f43-9003-c846dae60eb9"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/55fdd7cd-0e5d-41c5-a44a-a09ec827e9f0"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/8e129d24-b0df-4152-ba36-0f9132a6c21c"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/d19184ac-31e6-4567-82d4-2261ea5d214b"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/fa3e0cb4-8f7c-41ec-9001-e49e94a7b677"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/126d65fe-6f16-4115-8d73-019700e813f3"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/646fd7a9-b781-46c0-8b16-c53f7d4507c1"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/08d8f018-5808-4d48-918c-a26896a3c28d"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a8bd2281-b2ee-44bb-90f6-0d338008b5c4"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/18d9b69c-8bd1-4651-bb18-ef9551b7a20e"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/780a0b5d-c7f3-4418-b179-b35be03805b5"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/3cb50e2b-cf81-4fda-92fd-4da7529d082f"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a1a2e08b-71e9-4230-8fdb-174c4b7e351b"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/4b6df7a1-dbcc-46a2-8925-99693240cd09"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/43ee629c-0ba6-4cd6-87e6-219a9d46dbd7"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/8783a704-7e74-4376-8dd5-ae3cab7e7065"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/9358aa32-9a02-44a8-af0c-b4505cd0032b"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/c99431f6-d9fe-4c6a-b089-05a52c656f1e"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/1149fc8d-b981-4e58-b7fa-bdc2be8701da"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/64053bb8-e3b4-4c29-8484-dcb0359c4435"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/63bd6619-a6e3-46d9-aa0d-e7779fa708bb"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/61dd4604-dcd4-461f-9bb3-14c27e9a48eb"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/56342703-67ec-4bd8-bbd2-7d45e93a2ba5"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/fb50701c-9ec0-40b8-84c1-a85c7e15ad16"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/2fd6e5b7-6102-4dc7-af9f-bbdc344aaa79"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/2f4ee0ef-beed-44e9-9869-6decedcf1630"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/546c1757-7256-482f-85a4-c371a1213553"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/732f276e-57dd-4d4a-8f8f-1c2df10fc837"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/cdf24ea7-4513-4de7-b311-13a2a345f606"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/d610bbd3-3d8e-4c99-9120-347fd4dc4fbe"
			}
		]
	},
	"page": { "size": 100, "totalElements": 42, "totalPages": 1, "number": 1 }
}
