{
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000000/Option/00000000-0000-0000-0000-000000000001"
		},
		"value": {
			"href": "https://dev.backend.ka.philips.com/api/Accessory/00000000-0000-0000-0000-000000000001"
		}
	},
	"isPreselected": true,
	"label": "Bottom tray"
}
