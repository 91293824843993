{
  "ingredientUnit": {
    "bag": {
      "empty": "sacos",
      "plural": "sacos",
      "singular": "saco"
    },
    "branch": {
      "empty": "ramos",
      "plural": "ramos",
      "singular": "ramo"
    },
    "bunch": {
      "empty": "maços",
      "plural": "molhos",
      "singular": "molho"
    },
    "can": {
      "empty": "latas",
      "plural": "latas",
      "singular": "lata"
    },
    "centiliter": {
      "empty": "centilitros",
      "plural": "centilitros",
      "singular": "centilitro"
    },
    "clove": {
      "empty": "dentes",
      "plural": "dentes",
      "singular": "dente"
    },
    "cup": {
      "empty": "copos",
      "plural": "copos",
      "singular": "copo"
    },
    "dash": {
      "empty": "toques",
      "plural": "fios",
      "singular": "fio"
    },
    "decigram": {
      "empty": "decigrama",
      "plural": "decigramas",
      "singular": "decigrama"
    },
    "deciliter": {
      "empty": "decilitros",
      "plural": "decilitros",
      "singular": "decilitro"
    },
    "drop": {
      "empty": "gotas",
      "plural": "gotas",
      "singular": "gota"
    },
    "fluidOunce": {
      "empty": "onças líquidas",
      "plural": "onças líquidas",
      "singular": "onça líquida"
    },
    "gram": {
      "empty": "gramas",
      "plural": "gramas",
      "singular": "grama"
    },
    "kilogram": {
      "empty": "quilogramas",
      "plural": "quilogramas",
      "singular": "quilograma"
    },
    "liter": {
      "empty": "litros",
      "plural": "litros",
      "singular": "litro"
    },
    "microgram": {
      "empty": "microgramas",
      "plural": "microgramas",
      "singular": "micrograma"
    },
    "milligram": {
      "empty": "miligramas",
      "plural": "miligramas",
      "singular": "miligrama"
    },
    "milliliter": {
      "empty": "mililitros",
      "plural": "mililitros",
      "singular": "mililitro"
    },
    "ounce": {
      "empty": "onças",
      "plural": "onças",
      "singular": "onça"
    },
    "package": {
      "plural": "pacotes",
      "singular": "pacote"
    },
    "piece": {
      "empty": "pedaços",
      "plural": "pedaços",
      "singular": "pedaço"
    },
    "pinch": {
      "empty": "pitadas",
      "plural": "pitadas",
      "singular": "pitada"
    },
    "pound": {
      "empty": "libras",
      "plural": "libras",
      "singular": "libra"
    },
    "sachet": {
      "empty": "sachê",
      "plural": "sachês",
      "singular": "sachê"
    },
    "shot": {
      "empty": "doses",
      "plural": "doses",
      "singular": "dose"
    },
    "slice": {
      "empty": "fatias",
      "plural": "fatias",
      "singular": "fatia"
    },
    "stick": {
      "empty": "tabletes",
      "plural": "tabletes",
      "singular": "tablete"
    },
    "tablespoon": {
      "empty": "colheres de sopa",
      "plural": "colheres de sopa",
      "singular": "colher de sopa"
    },
    "teaspoon": {
      "empty": "colheres de chá",
      "plural": "colheres de chá",
      "singular": "colher de chá"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Sim",
        "label": "Começo fácil",
        "notEasyToStart": "Não",
        "placeholder": "Começo fácil"
      }
    }
  }
}