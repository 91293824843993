{
	"_embedded": {
		"item": [
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/8c51251d-7d2e-46b5-87a7-ce82367ca0bb.png",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/8c51251d-7d2e-46b5-87a7-ce82367ca0bb_thumbnail.png",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/8c51251d-7d2e-46b5-87a7-ce82367ca0bb_high.png",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/8c51251d-7d2e-46b5-87a7-ce82367ca0bb_medium.png"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/ca3514fc-b90c-40ca-8560-4c559e80ccc5"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"name": "NutriMax XXL",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Product/fdde05da-9981-422e-8956-cd15db5ac78e"
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Product/fdde05da-9981-422e-8956-cd15db5ac78e/Category"
					},
					"variants": {
						"href": "https://dev.backend.ka.philips.com/api/Product/fdde05da-9981-422e-8956-cd15db5ac78e/Variant{?country}",
						"templated": true
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Product/fdde05da-9981-422e-8956-cd15db5ac78e/Translation"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/ca3514fc-b90c-40ca-8560-4c559e80ccc5"
					},
					"device": {
						"href": "https://dev.backend.ka.philips.com/api/Device/fdde05da-9981-422e-8956-cd15db5ac78e{?country,unitSystem}",
						"templated": true
					}
				}
			}
		]
	},
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Product?categories=AIRFRYER&categories=DEVICE_PRODUCT&page=1&size=20"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Product/fdde05da-9981-422e-8956-cd15db5ac78e"
			}
		]
	},
	"page": {
		"size": 20,
		"totalElements": 16,
		"totalPages": 1,
		"number": 1
	}
}
