{
	"_embedded": {
		"tenants": {
			"_embedded": {
				"item": [
					{
						"_embedded": {
							"icon": {
								"_links": {
									"self": {
										"href": "https://dev.backend.ka.philips.com/api/Media/00000000-0000-0000-0000-000000000000"
									}
								},
								"availableSizes": {},
								"primaryBlue": 255,
								"primaryGreen": 255,
								"primaryRed": 255,
								"url": "https://dev.cms.ka.philips.com/assets/tenants/nutriu/nutriu-logo.svg"
							}
						},
						"_links": {
							"administratorSelf": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Administrator/self"
							},
							"icon": {
								"href": "https://dev.backend.ka.philips.com/api/Media/00000000-0000-0000-0000-000000000000"
							},
							"rootApi": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985"
							},
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Tenant/2673af18-c52e-464f-85bf-f1415c86b888"
							},
							"tenantLogin": {
								"href": "https://dev.backend.ka.philips.com/api/Tenant$login"
							}
						},
						"name": "oneka"
					},
					{
						"_embedded": {
							"icon": {
								"_links": {
									"self": {
										"href": "https://dev.backend.ka.philips.com/api/Media/00000000-0000-0000-0000-000000000001"
									}
								},
								"availableSizes": {},
								"primaryBlue": 255,
								"primaryGreen": 255,
								"primaryRed": 255,
								"url": "https://dev.cms.ka.philips.com/assets/tenants/coffee/coffee-logo.svg"
							}
						},
						"_links": {
							"administratorSelf": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Administrator/self"
							},
							"icon": {
								"href": "https://dev.backend.ka.philips.com/api/Media/00000000-0000-0000-0000-000000000000"
							},
							"rootApi": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985"
							},
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Tenant/0921897c-a457-443b-b555-5bbc7cd62985"
							},
							"tenantLogin": {
								"href": "https://dev.backend.ka.philips.com/api/Tenant$login"
							}
						},
						"name": "coffee"
					}
				]
			},
			"_links": {
				"self": {
					"href": "https://dev.backend.ka.philips.com/api/AdministratorRoot/self/Tenants"
				}
			},
			"page": {
				"size": 2,
				"totalElements": 2,
				"totalPages": 1,
				"number": 1
			}
		}
	},
	"email": "john.doe@versuni.com",
	"createdAt": "2021-02-15T19:31:31.342Z",
	"modifiedAt": "2022-07-24T06:23:47.317172Z",
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AdministratorRoot/self"
		},
		"tenants": {
			"href": "https://dev.backend.ka.philips.com/api/AdministratorRoot/self/Tenants"
		}
	}
}
