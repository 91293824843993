{
	"_embedded": {
		"item": [
			{
				"name": "Pineapple spinach smoothie",
				"status": "DRAFT",
				"type": "RECIPE",
				"createdAt": "2018-02-15T19:31:31.342Z",
				"modifiedAt": "2019-07-24T06:23:47.317172Z",
				"_links": {
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					},
					"defaultTranslation": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2/Translation"
					},
					"recipe": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/83b13b1a-e1cc-485b-b363-fa9a8cf4bef4"
					}
				}
			},
			{
				"name": "Smoothie",
				"status": "DRAFT",
				"type": "RECIPE",
				"createdAt": "2018-02-15T19:31:31.342Z",
				"modifiedAt": "2019-07-24T06:23:47.317172Z",
				"_links": {
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					},
					"defaultTranslation": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2/Translation"
					},
					"recipe": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/83b13b1a-e1cc-485b-b363-fa9a8cf4bef4"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/PushMessage/213213123"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/PushMessage/832876455"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2?page=1&size=10"
		}
	},
	"page": {
		"number": 1,
		"size": 20,
		"totalElements": 55,
		"totalPages": 3
	}
}
