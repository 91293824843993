{
  "ingredientUnit": {
    "bag": {
      "empty": "kotti",
      "plural": "kotti",
      "singular": "kott"
    },
    "branch": {
      "empty": "oksa",
      "plural": "oksa",
      "singular": "oks"
    },
    "bunch": {
      "empty": "kimpu",
      "plural": "kimpu",
      "singular": "kimp"
    },
    "can": {
      "empty": "purki",
      "plural": "purki",
      "singular": "purk"
    },
    "centiliter": {
      "empty": "cl",
      "plural": "cl",
      "singular": "cl"
    },
    "clove": {
      "empty": "küünt",
      "plural": "küünt",
      "singular": "küüs"
    },
    "cup": {
      "empty": "tassi",
      "plural": "tassi",
      "singular": "tass"
    },
    "dash": {
      "empty": "natuke",
      "plural": "natuke",
      "singular": "natuke"
    },
    "decigram": {
      "empty": "dg",
      "plural": "dg",
      "singular": "dg"
    },
    "deciliter": {
      "empty": "dl",
      "plural": "dl",
      "singular": "dl"
    },
    "drop": {
      "empty": "tilka",
      "plural": "tilka",
      "singular": "tilk"
    },
    "fluidOunce": {
      "empty": "vedelikuuntsi",
      "plural": "vedelikuuntsi",
      "singular": "vedelikuunts"
    },
    "gram": {
      "empty": "g",
      "plural": "g",
      "singular": "g"
    },
    "kilogram": {
      "empty": "kg",
      "plural": "kg",
      "singular": "kg"
    },
    "liter": {
      "empty": "l",
      "plural": "l",
      "singular": "l"
    },
    "microgram": {
      "empty": "μg",
      "plural": "μg",
      "singular": "μg"
    },
    "milligram": {
      "empty": "mg",
      "plural": "mg",
      "singular": "mg"
    },
    "milliliter": {
      "empty": "ml",
      "plural": "ml",
      "singular": "ml"
    },
    "ounce": {
      "empty": "untsi",
      "plural": "untsi",
      "singular": "unts"
    },
    "package": {
      "plural": "pakki",
      "singular": "pakk"
    },
    "piece": {
      "empty": "tk",
      "plural": "tk",
      "singular": "tk"
    },
    "pinch": {
      "empty": "sõrmeotsatäit",
      "plural": "sõrmeotsatäit",
      "singular": "sõrmeotsatäis"
    },
    "pound": {
      "empty": "lb",
      "plural": "lb",
      "singular": "lb"
    },
    "sachet": {
      "empty": "pakikest",
      "plural": "pakki",
      "singular": "pakk"
    },
    "scoop": {
      "empty": "jäätisekulpi",
      "plural": "jäätisekulpi",
      "singular": "jäätisekulp"
    },
    "shot": {
      "empty": "pitsi",
      "plural": "pitsi",
      "singular": "pits"
    },
    "slice": {
      "empty": "viilu",
      "plural": "viilu",
      "singular": "viil"
    },
    "stick": {
      "empty": "pulka",
      "plural": "pulka",
      "singular": "pulk"
    },
    "tablespoon": {
      "empty": "spl",
      "plural": "spl",
      "singular": "spl"
    },
    "teaspoon": {
      "empty": "tl",
      "plural": "tl",
      "singular": "tl"
    }
  }
}