{
  "ingredientUnit": {
    "bag": {
      "empty": "kantung",
      "plural": "kantung",
      "singular": "kantung"
    },
    "branch": {
      "empty": "tangkai",
      "plural": "tangkai",
      "singular": "tangkai"
    },
    "bunch": {
      "empty": "ikat",
      "plural": "ikat",
      "singular": "ikat"
    },
    "can": {
      "empty": "kaleng",
      "plural": "kaleng",
      "singular": "kaleng"
    },
    "centiliter": {
      "empty": "sentiliter",
      "plural": "sentiliter",
      "singular": "sentiliter"
    },
    "clove": {
      "empty": "siung",
      "plural": "siung",
      "singular": "siung"
    },
    "cup": {
      "empty": "cangkir",
      "plural": "cangkir",
      "singular": "cangkir"
    },
    "dash": {
      "empty": "tabur",
      "plural": "tabur",
      "singular": "tabur"
    },
    "decigram": {
      "empty": "desigram",
      "plural": "desigram",
      "singular": "desigram"
    },
    "deciliter": {
      "empty": "desiliter",
      "plural": "desiliter",
      "singular": "desiliter"
    },
    "drop": {
      "empty": "tetes",
      "plural": "tetes",
      "singular": "tetes"
    },
    "fluidOunce": {
      "empty": "ons cairan",
      "plural": "ons cairan",
      "singular": "ons cairan"
    },
    "gram": {
      "empty": "gram",
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogram",
      "plural": "kilogram",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "liter",
      "plural": "liter",
      "singular": "liter"
    },
    "microgram": {
      "empty": "mikrogram",
      "plural": "mikrogram",
      "singular": "mikrogram"
    },
    "milligram": {
      "empty": "miligram",
      "plural": "miligram",
      "singular": "miligram"
    },
    "milliliter": {
      "empty": "mililiter",
      "plural": "mililiter",
      "singular": "mililiter"
    },
    "ounce": {
      "empty": "ons",
      "plural": "ons",
      "singular": "ons"
    },
    "package": {
      "plural": "paket",
      "singular": "paket"
    },
    "piece": {
      "empty": "potong",
      "plural": "potong",
      "singular": "potong"
    },
    "pinch": {
      "empty": "jumput",
      "plural": "jumput",
      "singular": "jumput"
    },
    "pound": {
      "empty": "pon",
      "plural": "pon",
      "singular": "pon"
    },
    "sachet": {
      "empty": "saset",
      "plural": "saset",
      "singular": "saset"
    },
    "shot": {
      "empty": "sloki",
      "plural": "sloki",
      "singular": "sloki"
    },
    "slice": {
      "empty": "iris",
      "plural": "iris",
      "singular": "iris"
    },
    "stick": {
      "empty": "batang",
      "plural": "batang",
      "singular": "batang"
    },
    "tablespoon": {
      "empty": "sendok makan",
      "plural": "sendok makan",
      "singular": "sendok makan"
    },
    "teaspoon": {
      "empty": "sendok teh",
      "plural": "sendok teh",
      "singular": "sendok teh"
    }
  }
}