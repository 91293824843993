{
  "ingredientUnit": {
    "bag": {
      "empty": "שקיות",
      "plural": "שקיות",
      "singular": "שקית"
    },
    "branch": {
      "empty": "ענפים",
      "plural": "ענפים",
      "singular": "ענף"
    },
    "bunch": {
      "empty": "צרורות",
      "plural": "צרורות",
      "singular": "צרור"
    },
    "can": {
      "empty": "פחיות שימורים",
      "plural": "פחיות שימורים",
      "singular": "פחית שימורים"
    },
    "centiliter": {
      "empty": "סנטיליטרים",
      "plural": "סנטיליטרים",
      "singular": "סנטיליטר"
    },
    "clove": {
      "empty": "שיניים",
      "plural": "שיניים",
      "singular": "שן"
    },
    "cup": {
      "empty": "כוסות",
      "plural": "כוסות",
      "singular": "כוס"
    },
    "dash": {
      "empty": "קורטובים",
      "plural": "קורטובים",
      "singular": "קורטוב"
    },
    "decigram": {
      "empty": "דציגרם",
      "plural": "דציגרם",
      "singular": "דציגרם"
    },
    "deciliter": {
      "empty": "דציליטרים",
      "plural": "דציליטרים",
      "singular": "דציליטר"
    },
    "drop": {
      "empty": "טיפות",
      "plural": "טיפות",
      "singular": "טיפה"
    },
    "fluidOunce": {
      "empty": "אונקיות נוזל",
      "plural": "אונקיות נוזל",
      "singular": "אונקיית נוזל"
    },
    "gram": {
      "empty": "גרמים",
      "plural": "גרמים",
      "singular": "גרם"
    },
    "kilogram": {
      "empty": "קילוגרמים",
      "plural": "קילוגרמים",
      "singular": "קילוגרם"
    },
    "liter": {
      "empty": "ליטרים",
      "plural": "ליטרים",
      "singular": "ליטר"
    },
    "microgram": {
      "empty": "מיקרוגרמים",
      "plural": "מיקרוגרמים",
      "singular": "מיקרוגרם"
    },
    "milligram": {
      "empty": "מיליגרמים",
      "plural": "מיליגרמים",
      "singular": "מיליגרם"
    },
    "milliliter": {
      "empty": "מיליליטרים",
      "plural": "מיליליטרים",
      "singular": "מיליליטר"
    },
    "ounce": {
      "empty": "אונקיות",
      "plural": "אונקיות",
      "singular": "אונקיה"
    },
    "package": {
      "plural": "חבילות",
      "singular": "חבילה"
    },
    "piece": {
      "empty": "חתיכות",
      "plural": "חתיכות",
      "singular": "חתיכה"
    },
    "pinch": {
      "empty": "קמצוצים",
      "plural": "קמצוצים",
      "singular": "קמצוץ"
    },
    "pound": {
      "empty": "ליברות",
      "plural": "ליברות",
      "singular": "ליברה"
    },
    "sachet": {
      "empty": "שקית קטנה",
      "plural": "שקית קטנה",
      "singular": "שקית קטנה"
    },
    "shot": {
      "empty": "שוטים",
      "plural": "שוטים",
      "singular": "שוט"
    },
    "slice": {
      "empty": "פרוסות",
      "plural": "פרוסות",
      "singular": "פרוסה"
    },
    "stick": {
      "empty": "מקלות",
      "plural": "מקלות",
      "singular": "מקל"
    },
    "tablespoon": {
      "empty": "כפות",
      "plural": "כפות",
      "singular": "כף"
    },
    "teaspoon": {
      "empty": "כפיות",
      "plural": "כפיות",
      "singular": "כפית"
    }
  }
}