{
  "countryName": {
    "IN": "India"
  },
  "ingredientUnit": {
    "bag": {
      "empty": "थैले ",
      "plural": "बैग ",
      "singular": "बैग "
    },
    "branch": {
      "empty": "शाखाएँ / डालियाँ ",
      "plural": "शाखाएँ / डालियाँ ",
      "singular": "शाख/ डाल "
    },
    "bunch": {
      "empty": "गुच्छे",
      "plural": "गुच्छे ",
      "singular": "गुच्छा "
    },
    "can": {
      "empty": "डिब्बे ",
      "plural": "डिब्बे ",
      "singular": "डिब्बा  "
    },
    "centiliter": {
      "empty": "सेंटीलीटर ",
      "plural": "सेंटीलीटरस ",
      "singular": "सेंटीलीटर "
    },
    "clove": {
      "empty": "क्लोवस ",
      "plural": "क्लोवस ",
      "singular": "क्लोव "
    },
    "cup": {
      "empty": "कप ",
      "plural": "कप ",
      "singular": "कप "
    },
    "dash": {
      "empty": "छींटे ",
      "plural": "छींटे ",
      "singular": "छींटा "
    },
    "decigram": {
      "empty": "डेसिग्राम",
      "plural": "डेसिग्रामस ",
      "singular": "डेसिग्राम "
    },
    "deciliter": {
      "empty": "डेसिलीटरस ",
      "plural": "डेसिलीटरस ",
      "singular": "डेसिलीटर "
    },
    "drop": {
      "empty": "बूंदे ",
      "plural": "बूंदे ",
      "singular": "बूंद "
    },
    "fluidOunce": {
      "empty": "तरल औंस /  द्रव औंस ",
      "plural": "तरल औंस /  द्रव औंस ",
      "singular": "तरल औंस /  द्रव औंस "
    },
    "gram": {
      "empty": "ग्रामस ",
      "plural": "ग्रामस ",
      "singular": "ग्राम "
    },
    "kilogram": {
      "empty": "किलोग्रामस ",
      "plural": "किलोग्रामस ",
      "singular": "किलोग्राम "
    },
    "liter": {
      "empty": "लीटरस ",
      "plural": "लीटरस ",
      "singular": "लीटर "
    },
    "microgram": {
      "empty": "माइकोग्रामस ",
      "plural": "माइक्रोग्रामस ",
      "singular": "माइक्रोग्राम "
    },
    "milligram": {
      "empty": "मिलीग्रामस ",
      "plural": "मिलीग्रामस ",
      "singular": "मिलीग्राम "
    },
    "milliliter": {
      "empty": "मिलीलीटरस ",
      "plural": "मिलीलीटरस ",
      "singular": "मिलीलीटर "
    },
    "ounce": {
      "empty": "औंस ",
      "plural": "औंस ",
      "singular": "औंस "
    },
    "package": {
      "plural": "पैकेट ",
      "singular": "पैकेट "
    },
    "piece": {
      "empty": "टुकड़े ",
      "plural": "टुकड़े ",
      "singular": "टुकड़ा "
    },
    "pinch": {
      "empty": "चुटकियाँ ",
      "plural": "चुटकियाँ ",
      "singular": "चुटकी  "
    },
    "pound": {
      "empty": "पाउंडस ",
      "plural": "पाउंडस ",
      "singular": "पाउंड "
    },
    "sachet": {
      "empty": "सैशे ",
      "plural": "सैशे ",
      "singular": "सैशे "
    },
    "scoop": {
      "empty": "स्कूपस ",
      "plural": "स्कूपस ",
      "singular": "स्कूप"
    },
    "shot": {
      "empty": "शॉटस ",
      "plural": "शॉटस ",
      "singular": "शॉट "
    },
    "slice": {
      "empty": "स्लाइस ",
      "plural": "स्लाइस ",
      "singular": "स्लाइस "
    },
    "stick": {
      "empty": "स्टिकस ",
      "plural": "स्टिकस ",
      "singular": "स्टिक "
    },
    "tablespoon": {
      "empty": "बड़े चम्मच ",
      "plural": "बड़े चम्मच ",
      "singular": "बड़ा चम्मच "
    },
    "teaspoon": {
      "empty": "छोटे चम्मच",
      "plural": "छोटे चम्मच",
      "singular": "छोटा चम्मच "
    }
  }
}