{
  "ingredientUnit": {
    "bag": {
      "empty": "vreče",
      "plural": "vreče",
      "singular": "vreča"
    },
    "branch": {
      "empty": "vejice",
      "plural": "vejice",
      "singular": "vejica"
    },
    "bunch": {
      "empty": "šopi",
      "plural": "šopi",
      "singular": "šop"
    },
    "can": {
      "empty": "pločevinke",
      "plural": "pločevinke",
      "singular": "pločevinka"
    },
    "centiliter": {
      "empty": "centilitri",
      "plural": "centilitri",
      "singular": "centiliter"
    },
    "clove": {
      "empty": "stroki",
      "plural": "stroki",
      "singular": "strok"
    },
    "cup": {
      "empty": "skodelice",
      "plural": "skodelice",
      "singular": "skodelica"
    },
    "dash": {
      "empty": "kanček",
      "plural": "kanček",
      "singular": "kanček"
    },
    "decigram": {
      "empty": "decigram",
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "decilitri",
      "plural": "decilitri",
      "singular": "deciliter"
    },
    "drop": {
      "empty": "kapljice",
      "plural": "kapljice",
      "singular": "kapljica"
    },
    "fluidOunce": {
      "empty": "tekočinske unče",
      "plural": "tekočinske unče",
      "singular": "tekočinska unča"
    },
    "gram": {
      "empty": "grami",
      "plural": "grami",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogrami",
      "plural": "kilogrami",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "litri",
      "plural": "litri",
      "singular": "liter"
    },
    "microgram": {
      "empty": "mikrogrami",
      "plural": "mikrogrami",
      "singular": "mikrogram"
    },
    "milligram": {
      "empty": "miligrami",
      "plural": "miligrami",
      "singular": "miligram"
    },
    "milliliter": {
      "empty": "mililitri",
      "plural": "mililitri",
      "singular": "mililiter"
    },
    "ounce": {
      "empty": "unče",
      "plural": "unče",
      "singular": "unča"
    },
    "package": {
      "plural": "svežnji",
      "singular": "sveženj"
    },
    "piece": {
      "empty": "kosi",
      "plural": "kosi",
      "singular": "kos"
    },
    "pinch": {
      "empty": "ščepci",
      "plural": "ščepci",
      "singular": "ščepec"
    },
    "pound": {
      "empty": "funti",
      "plural": "funti",
      "singular": "funt"
    },
    "sachet": {
      "empty": "zavoji",
      "plural": "zavoji",
      "singular": "zavoj"
    },
    "shot": {
      "empty": "šilca",
      "plural": "šilca",
      "singular": "šilce"
    },
    "slice": {
      "empty": "rezine",
      "plural": "rezine",
      "singular": "rezina"
    },
    "stick": {
      "empty": "palčke",
      "plural": "palčke",
      "singular": "palčka"
    },
    "tablespoon": {
      "empty": "jedilne žlice",
      "plural": "jedilne žlice",
      "singular": "jedilna žlica"
    },
    "teaspoon": {
      "empty": "čajne žličke",
      "plural": "čajne žličke",
      "singular": "čajna žlička"
    }
  }
}