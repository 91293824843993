{
  "ingredientUnit": {
    "bag": {
      "plural": "poser",
      "singular": "pose"
    },
    "branch": {
      "plural": "kviste",
      "singular": "kvist"
    },
    "bunch": {
      "plural": "bundter",
      "singular": "bundt"
    },
    "can": {
      "plural": "dåser",
      "singular": "dåse"
    },
    "centiliter": {
      "plural": "centiliter",
      "singular": "centiliter"
    },
    "clove": {
      "plural": "fed",
      "singular": "fed"
    },
    "cup": {
      "plural": "kopper",
      "singular": "kop"
    },
    "dash": {
      "plural": "stænk",
      "singular": "stænk"
    },
    "decigram": {
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "plural": "deciliter",
      "singular": "deciliter"
    },
    "drop": {
      "plural": "dråber",
      "singular": "dråbe"
    },
    "gram": {
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilogram",
      "singular": "kilogram"
    },
    "liter": {
      "plural": "liter",
      "singular": "liter"
    },
    "milligram": {
      "plural": "milligram",
      "singular": "milligram"
    },
    "milliliter": {
      "plural": "milliliter",
      "singular": "milliliter"
    },
    "ounce": {
      "plural": "ounces",
      "singular": "ounce"
    },
    "package": {
      "plural": "pakker",
      "singular": "pakke"
    },
    "piece": {
      "plural": "stykker",
      "singular": "stykke"
    },
    "pinch": {
      "plural": "knivspidser",
      "singular": "knivspids"
    },
    "sachet": {
      "plural": "brev",
      "singular": "brev"
    },
    "slice": {
      "plural": "skiver",
      "singular": "skive"
    },
    "tablespoon": {
      "plural": "spiseskeer",
      "singular": "spiseske"
    },
    "teaspoon": {
      "plural": "teskeer",
      "singular": "teske"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Ja",
        "label": "Nem til at starte med",
        "notEasyToStart": "Nej",
        "placeholder": "Nem til at starte med"
      }
    }
  }
}