{
	"_embedded": {
		"item": [
			{
				"_embedded": {
					"roles": {
						"_embedded": {
							"item": [
								{
									"_embedded": {
										"organizations": {
											"_embedded": {
												"item": [
													{
														"name": "GLOBAL",
														"_links": {
															"self": {
																"href": "https://dev.backend.ka.philips.com/api/Organization/0000-0000"
															}
														}
													}
												]
											},
											"_links": {
												"self": {
													"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/0000-role-id-0000/Organization"
												}
											},
											"page": {
												"size": 50,
												"totalElements": 1,
												"totalPages": 1,
												"number": 1
											}
										}
									},
									"role": "GLOBAL_CONTENT_MANAGER",
									"_links": {
										"organizations": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/0000-role-id-0000/Organization"
										},
										"self": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/0000-role-id-0000"
										}
									}
								},
								{
									"_embedded": {
										"organizations": {
											"_embedded": {
												"item": [
													{
														"name": "BENELUX",
														"_links": {
															"self": {
																"href": "https://dev.backend.ka.philips.com/api/Organization/2222-2222"
															}
														}
													}
												]
											},
											"page": {
												"size": 50,
												"totalElements": 2,
												"totalPages": 1,
												"number": 1
											},
											"_links": {
												"self": {
													"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111/Organization"
												}
											}
										}
									},
									"role": "COMMUNITY_MANAGER",
									"_links": {
										"organizations": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111/Organization"
										},
										"self": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111"
										}
									}
								},
								{
									"_embedded": {
										"organizations": {
											"_embedded": {
												"item": [
													{
														"name": "DACH",
														"_links": {
															"self": {
																"href": "https://dev.backend.ka.philips.com/api/Organization/1111-1111"
															}
														}
													}
												]
											},
											"page": {
												"size": 50,
												"totalElements": 2,
												"totalPages": 1,
												"number": 1
											},
											"_links": {
												"self": {
													"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111/Organization"
												}
											}
										}
									},
									"role": "COMMUNITY_MANAGER",
									"_links": {
										"organizations": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111/Organization"
										},
										"self": {
											"href": "https://dev.backend.ka.philips.com/api/Administrator/Role/1111-role-id-1111"
										}
									}
								}
							]
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Administrator/0000-administrator-id-0000/Roles"
							}
						},
						"page": {
							"size": 50,
							"totalElements": 2,
							"totalPages": 1,
							"number": 1
						}
					}
				},
				"email": "john.doe@versuni.com",
				"name": "John Doe",
				"status": "ACTIVE",
				"contact": "doe.john.contact@versuni.com",
				"company": "Versuni",
				"createdAt": "2021-02-15T19:31:31.342Z",
				"modifiedAt": "2022-07-24T06:23:47.317172Z",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Administrator/0000-administrator-id-0000"
					},
					"roles": {
						"href": "https://dev.backend.ka.philips.com/api/Administrator/0000-administrator-id-0000/Roles"
					}
				}
			}
		]
	},
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Administrator"
		}
	},
	"page": {
		"size": 20,
		"totalElements": 1,
		"totalPages": 1,
		"number": 1
	}
}
