{
  "ingredientUnit": {
    "bag": {
      "empty": "vrecká",
      "plural": "vrecká",
      "singular": "vrecko"
    },
    "branch": {
      "empty": "vetvičky",
      "plural": "vetvičky",
      "singular": "vetvička"
    },
    "bunch": {
      "empty": "hrste",
      "plural": "hrste",
      "singular": "hrsť"
    },
    "can": {
      "empty": "plechovky",
      "plural": "plechovky",
      "singular": "plechovka"
    },
    "centiliter": {
      "empty": "centilitre",
      "plural": "centilitre",
      "singular": "centiliter"
    },
    "clove": {
      "empty": "strúčiky",
      "plural": "strúčiky",
      "singular": "strúčik"
    },
    "cup": {
      "empty": "šálky",
      "plural": "šálky",
      "singular": "šálka"
    },
    "dash": {
      "empty": "niekoľko mililitrov",
      "plural": "niekoľko mililitrov",
      "singular": "pár mililitrov"
    },
    "decigram": {
      "empty": "decigram",
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "decilitre",
      "plural": "decilitre",
      "singular": "deciliter"
    },
    "drop": {
      "empty": "kvapky",
      "plural": "kvapky",
      "singular": "kvapka"
    },
    "fluidOunce": {
      "empty": "tekuté unce",
      "plural": "tekuté unce",
      "singular": "tekutá unca"
    },
    "gram": {
      "empty": "gramy",
      "plural": "gramy",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogramy",
      "plural": "kilogramy",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "litre",
      "plural": "litre",
      "singular": "liter"
    },
    "microgram": {
      "empty": "mikrogramy",
      "plural": "mikrogramy",
      "singular": "mikrogram"
    },
    "milligram": {
      "empty": "miligramy",
      "plural": "miligramy",
      "singular": "miligram"
    },
    "milliliter": {
      "empty": "mililitre",
      "plural": "mililitre",
      "singular": "mililiter"
    },
    "ounce": {
      "empty": "unce",
      "plural": "unce",
      "singular": "unca"
    },
    "package": {
      "plural": "balíky",
      "singular": "balík"
    },
    "piece": {
      "empty": "kusy",
      "plural": "kusy",
      "singular": "kus"
    },
    "pinch": {
      "empty": "štipky",
      "plural": "štipky",
      "singular": "štipka"
    },
    "pound": {
      "empty": "libry",
      "plural": "libry",
      "singular": "libra"
    },
    "sachet": {
      "empty": "vrecúško",
      "plural": "vrecúško",
      "singular": "vrecúško"
    },
    "shot": {
      "empty": "štamperlíky",
      "plural": "štamperlíky",
      "singular": "štamperlík"
    },
    "slice": {
      "empty": "plátky",
      "plural": "plátky",
      "singular": "plátok"
    },
    "stick": {
      "empty": "x 113 g",
      "plural": "x 113 g",
      "singular": "113 g"
    },
    "tablespoon": {
      "empty": "polievkové lyžice",
      "plural": "polievkové lyžice",
      "singular": "polievková lyžica"
    },
    "teaspoon": {
      "empty": "čajové lyžičky",
      "plural": "čajové lyžičky",
      "singular": "čajová lyžička"
    }
  }
}