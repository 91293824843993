{
	"name": "Venus (HD988x) configuration",
	"slug": "VENUS_DEVICE_CONFIG",
	"_links": {
		"attributes": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000000/Attribute"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000000"
		}
	}
}
