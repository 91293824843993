{
	"_embedded": {
		"item": [
			{
				"category": "AIR_STEAM",
				"cookingMethodId": "51f4fcec-2135-444f-a33a-8958663acfcb",
				"electricSystem": "220V",
				"humidityLevel": "HIGH",
				"name": "Air steam",
				"temperature": { "min": 120, "max": 120, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 5400 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/51f4fcec-2135-444f-a33a-8958663acfcb?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "STEAMING",
				"cookingMethodId": "b816144b-09a9-439d-a66d-60ad208c3901",
				"electricSystem": "220V",
				"humidityLevel": "HIGH",
				"name": "Steaming",
				"temperature": { "min": 100, "max": 100, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 5400 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/b816144b-09a9-439d-a66d-60ad208c3901?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "ROAST",
				"cookingMethodId": "5f76611d-08c5-4db9-8885-7f4b433c4f0e",
				"electricSystem": "220V",
				"humidityLevel": "LOW",
				"name": "Roast",
				"temperature": { "min": 140, "max": 160, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 5400 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/5f76611d-08c5-4db9-8885-7f4b433c4f0e?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "BAKE",
				"cookingMethodId": "3971e78c-e84f-405d-8de3-5196b3b51214",
				"electricSystem": "220V",
				"humidityLevel": "LOW",
				"name": "Bake",
				"temperature": { "min": 140, "max": 160, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 5400 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/3971e78c-e84f-405d-8de3-5196b3b51214?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "SLOW_COOK",
				"cookingMethodId": "628ba77c-2663-498d-810e-2566f2fafaaf",
				"electricSystem": "220V",
				"humidityLevel": "HIGH",
				"name": "Slow cook",
				"temperature": { "min": 50, "max": 80, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 10800 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/628ba77c-2663-498d-810e-2566f2fafaaf?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "PREHEAT",
				"cookingMethodId": "a50bd2d3-35b1-48a9-90d2-9d20a3ed9a73",
				"electricSystem": "220V",
				"humidityLevel": "OFF",
				"name": "Preheat",
				"temperature": { "min": 160, "max": 160, "interval": 5, "unit": "CELSIUS" },
				"time": { "min": 300, "max": 300 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a50bd2d3-35b1-48a9-90d2-9d20a3ed9a73?unitSystem=METRIC"
					}
				}
			},
			{
				"category": "SOUS_VIDE",
				"cookingMethodId": "c584bba2-24f5-4214-9ee3-796aaa50ef22",
				"electricSystem": "220V",
				"humidityLevel": "HIGH",
				"name": "Sous vide",
				"temperature": { "min": 50, "max": 90, "interval": 1, "unit": "CELSIUS" },
				"time": { "min": 60, "max": 10800 },
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/CookingMethod/c584bba2-24f5-4214-9ee3-796aaa50ef22?unitSystem=METRIC"
					}
				}
			}
		]
	},
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/CookingMethod?electricSystem=220V&unitSystem=METRIC&page=1&size=100"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/51f4fcec-2135-444f-a33a-8958663acfcb"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/b816144b-09a9-439d-a66d-60ad208c3901"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/5f76611d-08c5-4db9-8885-7f4b433c4f0e"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/3971e78c-e84f-405d-8de3-5196b3b51214"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/628ba77c-2663-498d-810e-2566f2fafaaf"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/a50bd2d3-35b1-48a9-90d2-9d20a3ed9a73"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/CookingMethod/c584bba2-24f5-4214-9ee3-796aaa50ef22"
			}
		]
	},
	"page": { "size": 100, "totalElements": 7, "totalPages": 1, "number": 1 }
}
