{
	"name": "Pineapple spinach smoothie - title",
	"description": "With pumpkin season just beginning, find out how you can make the most of delicious, vitamin-rich seasonal squashes. ",
	"language": "en-GB",
	"createdAt": "2018-02-15T19:31:31.342Z",
	"modifiedAt": "2019-07-24T06:23:47.317172Z",
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h"
		},
		"pushMessage": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
		}
	}
}
