{
  "ingredientUnit": {
    "bag": {
      "empty": "pytlíky",
      "plural": "pytlíky",
      "singular": "pytlík"
    },
    "branch": {
      "empty": "větvičky",
      "plural": "větvičky",
      "singular": "větvička"
    },
    "bunch": {
      "empty": "trsy",
      "plural": "trsy",
      "singular": "trs"
    },
    "can": {
      "empty": "plechovky",
      "plural": "plechovky",
      "singular": "plechovka"
    },
    "centiliter": {
      "empty": "centilitry",
      "plural": "centilitry",
      "singular": "centilitr"
    },
    "clove": {
      "empty": "stroužky",
      "plural": "stroužky",
      "singular": "stroužek"
    },
    "cup": {
      "empty": "hrníčky",
      "plural": "hrníčky",
      "singular": "hrníček"
    },
    "dash": {
      "empty": "troška",
      "plural": "troška",
      "singular": "troška"
    },
    "decigram": {
      "empty": "decigramy",
      "plural": "decigramy",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "decilitry",
      "plural": "decilitry",
      "singular": "decilitr"
    },
    "drop": {
      "empty": "kapky",
      "plural": "kapky",
      "singular": "kapka"
    },
    "fluidOunce": {
      "empty": "unce tekutiny",
      "plural": "unce tekutiny",
      "singular": "unce tekutiny"
    },
    "gram": {
      "empty": "gramy",
      "plural": "gramy",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogramy",
      "plural": "kilogramy",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "litry",
      "plural": "litry",
      "singular": "litr"
    },
    "microgram": {
      "empty": "mikrogramy",
      "plural": "mikrogramy",
      "singular": "mikrogram"
    },
    "milligram": {
      "empty": "miligramy",
      "plural": "miligramy",
      "singular": "miligram"
    },
    "milliliter": {
      "empty": "millilitry",
      "plural": "millilitry",
      "singular": "mililitr"
    },
    "ounce": {
      "empty": "unce",
      "plural": "unce",
      "singular": "unce"
    },
    "package": {
      "plural": "balení",
      "singular": "balení"
    },
    "piece": {
      "empty": "kusy",
      "plural": "kusy",
      "singular": "kus"
    },
    "pinch": {
      "empty": "špetky",
      "plural": "špetky",
      "singular": "špetka"
    },
    "pound": {
      "empty": "libry",
      "plural": "libry",
      "singular": "libra"
    },
    "sachet": {
      "empty": "sáčky",
      "plural": "sáčky",
      "singular": "sáček"
    },
    "shot": {
      "empty": "panáky",
      "plural": "panáky",
      "singular": "panák"
    },
    "slice": {
      "empty": "plátky",
      "plural": "plátky",
      "singular": "plátek"
    },
    "stick": {
      "empty": "tyčinky",
      "plural": "tyčinky",
      "singular": "tyčinka"
    },
    "tablespoon": {
      "empty": "lžíce",
      "plural": "lžíce",
      "singular": "lžíce"
    },
    "teaspoon": {
      "empty": "lžičky",
      "plural": "lžičky",
      "singular": "lžička"
    }
  }
}