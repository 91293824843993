{
	"_embedded": {
		"item": [
			{
				"name": "Pineapple spinach smoothie - title en",
				"description": "Lorem IPSUM En",
				"language": "en-US",
				"status": "APPROVED",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h"
					},
					"pushMessage": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					}
				}
			},
			{
				"name": "Pineapple spinach smoothie - title de",
				"description": "Lorem IPSUM De",
				"language": "de-DE",
				"status": "DRAFT",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-65643"
					},
					"pushMessage": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					}
				}
			}
		]
	},
	"_links": {}
}
