{
	"_embedded": {
		"item": [
			{
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002"
					},
					"options": {
						"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option"
					}
				},
				"isAdjustable": false,
				"isRequired": true,
				"isVisible": true,
				"label": "Temperature",
				"name": "temperature",
				"valueType": "OBJECT"
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000002/Attribute?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000002/Attribute?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000002/Attribute?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000002/Attribute?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000002/Attribute?page=1&size=10"
		}
	}
}
