{
  "ingredientUnit": {
    "bag": {
      "plural": "حقائب",
      "singular": "حقيبة"
    },
    "branch": {
      "plural": "فروع",
      "singular": "فرع"
    },
    "bunch": {
      "plural": "مجموعات",
      "singular": "مجموعة"
    },
    "can": {
      "plural": "علب",
      "singular": "علبة"
    },
    "centiliter": {
      "plural": "سنتيلترات",
      "singular": "سنتيلتر"
    },
    "clove": {
      "plural": "فصوص",
      "singular": "فص"
    },
    "cup": {
      "plural": "أكواب",
      "singular": "كوب"
    },
    "dash": {
      "plural": "رشات",
      "singular": "رشة"
    },
    "decigram": {
      "plural": "ديسيجرامات",
      "singular": "ديسيجرامات"
    },
    "deciliter": {
      "plural": "ديسيلترات",
      "singular": "ديسيلتر"
    },
    "drop": {
      "plural": "قطرات",
      "singular": "قطرة"
    },
    "gram": {
      "plural": "جرامات",
      "singular": "جرام"
    },
    "kilogram": {
      "plural": "كيلو جرامات",
      "singular": "كيلو جرام"
    },
    "liter": {
      "plural": "لترات",
      "singular": "لتر"
    },
    "milligram": {
      "plural": "مليجرامات",
      "singular": "مليجرام"
    },
    "milliliter": {
      "plural": "ملليلترات",
      "singular": "ملليلتر"
    },
    "ounce": {
      "plural": "أوقيات",
      "singular": "أوقية"
    },
    "package": {
      "plural": "حزم",
      "singular": "حزمة"
    },
    "piece": {
      "plural": "قطع",
      "singular": "قطعة"
    },
    "pinch": {
      "plural": "نتفات",
      "singular": "نتفة"
    },
    "sachet": {
      "plural": "أكياس",
      "singular": "أكياس"
    },
    "slice": {
      "plural": "شرائح",
      "singular": "شريحة"
    },
    "tablespoon": {
      "plural": "ملاعق كبيرة",
      "singular": "ملعقة كبيرة"
    },
    "teaspoon": {
      "plural": "ملاعق صغيرة",
      "singular": "ملعقة صغيرة"
    }
  }
}