var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { SessionManager } from '../sessions/SessionManager';
import { v4 } from 'uuid';
import { PageManager } from '../sessions/PageManager';
import EventBus, { Topic } from '../event-bus/EventBus';
var webClientVersion = '1.19.0';
/**
 * A cache which stores events generated by telemetry plugins.
 *
 * The event cache stores meta data and events until they are dispatched to the
 * data plane. The event cache removes the oldest event once the cache is full
 * and a new event is added.
 */
var EventCache = /** @class */function () {
  /**
   * @param applicationDetails Application identity and version.
   * @param batchLimit The maximum number of events that will be returned in a batch.
   * @param eventCacheSize  The maximum number of events the cache can contain before dropping events.
   * @param sessionManager  The sessionManager returns user id, session id and handles session timeout.
   * @param pageManager The pageManager returns page id.
   */
  function EventCache(applicationDetails, config, eventBus) {
    if (eventBus === void 0) {
      eventBus = new EventBus();
    }
    var _this = this;
    this.eventBus = eventBus;
    this.events = [];
    /**
     * Update the current page interaction for the session.
     */
    this.recordPageView = function (payload) {
      if (_this.isCurrentUrlAllowed()) {
        _this.pageManager.recordPageView(payload);
      }
    };
    /**
     * Add an event to the cache and reset the session timer.
     *
     * If the session is being recorded, the event will be recorded.
     * If the session is not being recorded, the event will not be recorded.
     *
     * @param type The event schema.
     */
    this.recordEvent = function (type, eventData) {
      if (!_this.enabled) {
        return;
      }
      if (_this.isCurrentUrlAllowed()) {
        var session = _this.sessionManager.getSession();
        _this.sessionManager.incrementSessionEventCount();
        if (_this.canRecord(session)) {
          _this.addRecordToCache(type, eventData);
        }
      }
    };
    /**
     * Returns the current session (1) if a session exists and (2) if the
     * current URL is allowed. Returns undefined otherwise.
     */
    this.getSession = function () {
      if (_this.isCurrentUrlAllowed()) {
        return _this.sessionManager.getSession();
      }
      return undefined;
    };
    /**
     * Add a session start event to the cache.
     */
    this.recordSessionInitEvent = function (session, type, eventData) {
      if (!_this.enabled) {
        return;
      }
      _this.sessionManager.incrementSessionEventCount();
      if (_this.canRecord(session)) {
        _this.addRecordToCache(type, eventData);
      }
    };
    this.canRecord = function (session) {
      return session.record && (session.eventCount <= _this.config.sessionEventLimit || _this.config.sessionEventLimit <= 0);
    };
    /**
     * Add an event to the cache.
     *
     * @param type The event schema.
     */
    this.addRecordToCache = function (type, eventData) {
      if (!_this.enabled) {
        return;
      }
      if (_this.events.length === _this.config.eventCacheSize) {
        // Drop newest event and keep the older ones
        // 1. Older events tend to be more relevant, such as session start
        //    or performance entries that are attributed to web vitals
        // 2. Dropping an old event requires linear time
        return;
      }
      // The data plane service model (i.e., LogEvents) does not adhere to the
      // RUM agent data model, where sessions and pages are first class
      // objects with their own attribute sets. Instead, we store session
      // attributes and page attributes together as 'meta data'.
      var metaData = __assign(__assign(__assign({}, _this.sessionManager.getAttributes()), _this.pageManager.getAttributes()), {
        version: '1.0.0',
        'aws:client': _this.installationMethod,
        'aws:clientVersion': webClientVersion
      });
      var partialEvent = {
        id: v4(),
        timestamp: new Date(),
        type: type
      };
      _this.eventBus.dispatch(Topic.EVENT, __assign(__assign({}, partialEvent), {
        details: eventData,
        metadata: metaData
      }));
      _this.events.push(__assign(__assign({}, partialEvent), {
        details: JSON.stringify(eventData),
        metadata: JSON.stringify(metaData)
      }));
    };
    this.appMonitorDetails = applicationDetails;
    this.config = config;
    this.enabled = true;
    this.pageManager = new PageManager(config, this.recordEvent);
    this.sessionManager = new SessionManager(applicationDetails, config, this.recordSessionInitEvent, this.pageManager);
    this.installationMethod = config.client;
  }
  /**
   * The event cache will record new events or new meta data.
   */
  EventCache.prototype.enable = function () {
    this.enabled = true;
  };
  /**
   * The event cache will not record new events or new meta data. Events and
   * meta data which are already in the cache will still be accessible.
   */
  EventCache.prototype.disable = function () {
    this.enabled = false;
  };
  /**
   * Returns true if the session is sampled, false otherwise.
   */
  EventCache.prototype.isSessionSampled = function () {
    return this.sessionManager.isSampled();
  };
  /**
   * Returns true if there are one or more events in the cache.
   */
  EventCache.prototype.hasEvents = function () {
    return this.events.length !== 0;
  };
  /**
   * Removes and returns the next batch of events.
   */
  EventCache.prototype.getEventBatch = function () {
    var rumEvents = [];
    if (this.events.length === 0) {
      return rumEvents;
    }
    if (this.events.length <= this.config.batchLimit) {
      // Return all events.
      rumEvents = this.events;
      this.events = [];
    } else {
      // Dispatch the front of the array and retain the back of the array.
      rumEvents = this.events.splice(0, this.config.batchLimit);
    }
    return rumEvents;
  };
  /**
   * Returns an object containing the AppMonitor ID and application version.
   */
  EventCache.prototype.getAppMonitorDetails = function () {
    return this.appMonitorDetails;
  };
  /**
   * Returns an object containing the session ID and user ID.
   */
  EventCache.prototype.getUserDetails = function () {
    return {
      userId: this.sessionManager.getUserId(),
      sessionId: this.sessionManager.getSession().sessionId
    };
  };
  /**
   * Set custom session attributes to add them to all event metadata.
   *
   * @param payload object containing custom attribute data in the form of key, value pairs
   */
  EventCache.prototype.addSessionAttributes = function (sessionAttributes) {
    this.sessionManager.addSessionAttributes(sessionAttributes);
  };
  /**
   * Returns {@code true} if the current url matches one of the allowedPages
   * and does not match any of the deniedPages; returns {@code false}
   * otherwise.
   */
  EventCache.prototype.isCurrentUrlAllowed = function () {
    var location = document.location.toString();
    var exclude = this.config.pagesToExclude.some(function (re) {
      return re.test(location);
    });
    var include = this.config.pagesToInclude.some(function (re) {
      return re.test(location);
    });
    return include && !exclude;
  };
  return EventCache;
}();
export { EventCache };