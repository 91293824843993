/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { APP_BASE_HREF } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import { ApplicationRef, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DatastoreService } from 'ngx-hal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, provideAppConfig } from './app.config';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { ErrorModalModule } from './components/error-modal/error-modal.module';
import { PopupDialogModule } from './components/popup-dialog/popup-dialog.module';
import { SSO_PROVIDERS_DATA_TOKEN, ssoProvidersData } from './enums/sso-provider.enum';
import { OneKAErrorHandler } from './error-handler/error-handler';
import { IconRegistryModule } from './icon-registry.module';
import { AcceptLanguageInterceptor } from './interceptors/accept-language.interceptor';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { CacheBustInterceptor } from './interceptors/cacheBust.interceptor';
import { Http2Interceptor } from './interceptors/http2.interceptor';
import { MockHttpInterceptor } from './interceptors/mock-http.interceptor';
import { UnescapeCharactersInterceptor } from './interceptors/unescapeCharacters.interceptor';
import { AccessDeniedContainerModule } from './pages/access-denied-container/access-denied-container.module';
import { DevLoginModule } from './pages/dev-login/dev-login.module';
import { ErrorPageModule } from './pages/error-page/error-page.module';
import { NotFoundContainerModule } from './pages/not-found-container/not-found-container.module';
import { SsoLandingPageModule } from './pages/sso-landing-page/sso-landing-page.module';
import { AuthModule } from './services/auth/auth.module';
import { BrowserDetectorService } from './services/browser-detector/browser-detector.service';
import { ConstantService } from './services/constant/constant.service';
import { Datastore } from './services/datastore/datastore.service';
import { HalDatastoreService } from './services/datastore/hal-datastore.service';
import { HttpService } from './services/http/http.service';
import { LocaleService } from './services/locale/locale.service';
import { OrganizationModule } from './services/organization/organization.module';
import { ServiceWorkerService } from './services/service-worker/service-worker.service';
import { SESSION_STORAGE } from './services/storage/session-storage/session-storage.service';
import { StorageService } from './services/storage/storage.service';
import { WINDOW_LOCATION_TOKEN } from './services/window-location/window-location.service';
import { WindowOnBeforeUnloadService } from './services/window-on-before-unload/window-on-before-unload.service';
import { WINDOW_TOKEN, windowProvider } from './services/window/window.service';
import { TranslationsLoader } from './translations/translations-loader';
import { provideCloudwatchRUM } from './utils/cloudwatch-rum';

const baseHref: string =
	window['__appBaseHref'] === 'BASE_HREF_PLACEHOLDER' ? '/' : window['__appBaseHref'];

// Application wide providers
const APP_PROVIDERS = [
	...APP_RESOLVER_PROVIDERS,
	provideAppConfig(),
	// __appBaseHref => Read from index.html as environment-variable.json is not loaded yet
	{ provide: APP_BASE_HREF, useValue: baseHref },
	provideCloudwatchRUM(),
];

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		AccessDeniedContainerModule,
		AppRoutingModule,
		AuthModule,
		BrowserModule,
		BrowserAnimationsModule,
		ErrorModalModule,
		ErrorPageModule,
		OrganizationModule,
		DevLoginModule,
		NotFoundContainerModule,
		IconRegistryModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: TranslationsLoader,
			},
		}),
		PopupDialogModule,
		SsoLandingPageModule,
		ReactiveFormsModule.withConfig({
			callSetDisabledState: 'whenDisabledForLegacyCode',
		}),
	],
	providers: [
		APP_PROVIDERS,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthorizationInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AcceptLanguageInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CacheBustInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnescapeCharactersInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MockHttpInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: Http2Interceptor,
			multi: true,
		},
		{
			provide: SESSION_STORAGE,
			useValue: sessionStorage,
		},
		ConstantService,
		LocaleService,
		StorageService,
		HttpService,
		Datastore,
		{
			provide: DatastoreService,
			useClass: HalDatastoreService,
			deps: [HttpClient, APP_CONFIG],
		},
		{ provide: HalDatastoreService, useExisting: DatastoreService },
		BrowserDetectorService,
		ServiceWorkerService,
		WindowOnBeforeUnloadService,
		{
			provide: ErrorHandler,
			useClass: OneKAErrorHandler,
		},
		{
			provide: SSO_PROVIDERS_DATA_TOKEN,
			useValue: ssoProvidersData,
		},
		{
			provide: WINDOW_TOKEN,
			useValue: windowProvider(),
		},
		{
			provide: WINDOW_LOCATION_TOKEN,
			useValue: window.location,
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {
	public constructor(public appRef: ApplicationRef) {}
}
