{
  "ingredientUnit": {
    "bag": {
      "empty": "σακουλάκια",
      "plural": "σακουλάκια",
      "singular": "σακουλάκι"
    },
    "branch": {
      "empty": "κλωνάρια",
      "plural": "κλωνάρια",
      "singular": "κλωνάρι"
    },
    "bunch": {
      "empty": "ματσάκια",
      "plural": "ματσάκια",
      "singular": "ματσάκι"
    },
    "can": {
      "empty": "κονσέρβες",
      "plural": "κονσέρβες",
      "singular": "κονσέρβα"
    },
    "centiliter": {
      "empty": "εκατοστόλιτρα",
      "plural": "εκατοστόλιτρα",
      "singular": "εκατοστόλιτρο"
    },
    "clove": {
      "empty": "σκελίδες",
      "plural": "σκελίδες",
      "singular": "σκελίδα"
    },
    "cup": {
      "empty": "φλιτζάνια",
      "plural": "φλιτζάνια",
      "singular": "φλιτζάνι"
    },
    "dash": {
      "empty": "1/8 από το κουταλάκι του γλυκού",
      "plural": "1/8 από το κουταλάκι του γλυκού",
      "singular": "1/8 από το κουταλάκι του γλυκού"
    },
    "decigram": {
      "empty": "δέκατα του γραμμαρίου",
      "plural": "δέκατα του γραμμαρίου",
      "singular": "δέκατο του γραμμαρίου"
    },
    "deciliter": {
      "empty": "δέκατα του λίτρου",
      "plural": "δέκατα του λίτρου",
      "singular": "δέκατο του λίτρου"
    },
    "drop": {
      "empty": "σταγόνες",
      "plural": "σταγόνες",
      "singular": "σταγόνα"
    },
    "fluidOunce": {
      "empty": "ουγγιές υγρών",
      "plural": "ουγγιές υγρών",
      "singular": "ουγγιά υγρού"
    },
    "gram": {
      "empty": "γραμμάρια",
      "plural": "γραμμάρια",
      "singular": "γραμμάριο"
    },
    "kilogram": {
      "empty": "κιλά",
      "plural": "κιλά",
      "singular": "κιλό"
    },
    "liter": {
      "empty": "λίτρα",
      "plural": "λίτρα",
      "singular": "λίτρο"
    },
    "microgram": {
      "empty": "μικρογραμμάρια",
      "plural": "μικρογραμμάρια",
      "singular": "μικρογραμμάριο"
    },
    "milligram": {
      "empty": "χιλιοστόγραμμα",
      "plural": "χιλιοστόγραμμα",
      "singular": "χιλιοστόγραμμο"
    },
    "milliliter": {
      "empty": "χιλιοστόλιτρα",
      "plural": "χιλιοστόλιτρα",
      "singular": "χιλιοστόλιτρο"
    },
    "ounce": {
      "empty": "ουγγιές",
      "plural": "ουγγιές",
      "singular": "ουγγιά"
    },
    "package": {
      "plural": "συσκευασίες",
      "singular": "συσκευασία"
    },
    "piece": {
      "empty": "τεμάχια",
      "plural": "τεμάχια",
      "singular": "τεμάχιο"
    },
    "pinch": {
      "empty": "1/16 από το κουταλάκι του γλυκού",
      "plural": "1/16 από το κουταλάκι του γλυκού",
      "singular": "1/16 από το κουταλάκι του γλυκού"
    },
    "pound": {
      "empty": "λίβρες",
      "plural": "λίβρες",
      "singular": "λίβρα"
    },
    "sachet": {
      "empty": "φακελάκια",
      "plural": "φακελάκια",
      "singular": "φακελάκι"
    },
    "shot": {
      "empty": "σφηνάκια",
      "plural": "σφηνάκια",
      "singular": "σφηνάκι"
    },
    "slice": {
      "empty": "φέτες",
      "plural": "φέτες",
      "singular": "φέτα"
    },
    "stick": {
      "empty": "στικς",
      "plural": "στικς",
      "singular": "στικ"
    },
    "tablespoon": {
      "empty": "κουτάλια της σούπας",
      "plural": "κουτάλια της σούπας",
      "singular": "κουτάλι της σούπας"
    },
    "teaspoon": {
      "empty": "κουταλάκια του γλυκού",
      "plural": "κουταλάκια του γλυκού",
      "singular": "κουταλάκι του γλυκού"
    }
  }
}