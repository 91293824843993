/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({ imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class IconRegistryModule {
	private readonly topLevelIcons: Array<string> = [
		'ic-access-denied',
		'ic-add-new-item',
		'ic-ai-translation',
		'ic-air-speed',
		'ic-air-speed',
		'ic-apple-solid',
		'ic-apple',
		'ic-approve',
		'ic-arrow-down',
		'ic-arrow-left',
		'ic-article-solid',
		'ic-article',
		'ic-basic',
		'ic-book-solid',
		'ic-book',
		'ic-bottle',
		'ic-camera',
		'ic-caret-down',
		'ic-check-circle',
		'ic-check',
		'ic-checked',
		'ic-chevron',
		'ic-close-small',
		'ic-close',
		'ic-comment-level',
		'ic-comment-solid',
		'ic-comment',
		'ic-copy-primary',
		'ic-criteria-info',
		'ic-details',
		'ic-device-close',
		'ic-device',
		'ic-dolphin',
		'ic-download',
		'ic-duration',
		'ic-edit',
		'ic-error-circle-small',
		'ic-error-round',
		'ic-error-triangle-solid',
		'ic-error-triangle',
		'ic-export',
		'ic-eye',
		'ic-favorite',
		'ic-file-add',
		'ic-file-close',
		'ic-file-config',
		'ic-file-plus',
		'ic-file-right',
		'ic-file',
		'ic-filter',
		'ic-flag-solid',
		'ic-flag',
		'ic-globe-arrow',
		'ic-globe',
		'ic-heart',
		'ic-help',
		'ic-highlight',
		'ic-history',
		'ic-humidity',
		'ic-humidity',
		'ic-hyperlink',
		'ic-image-change',
		'ic-image',
		'ic-info-circle',
		'ic-info-round',
		'ic-ingredient',
		'ic-language-change',
		'ic-link-green',
		'ic-link',
		'ic-loading',
		'ic-lock-large',
		'ic-lock',
		'ic-megaphone',
		'ic-megaphone-basket',
		'ic-megaphone-message',
		'ic-message',
		'ic-mobile',
		'ic-move-to',
		'ic-notification',
		'ic-nutrition-missing',
		'ic-nutrition-ok',
		'ic-nuts',
		'ic-open-in',
		'ic-permissions',
		'ic-pin',
		'ic-plus',
		'ic-pressure',
		'ic-pressure',
		'ic-print',
		'ic-reaction-like-solid',
		'ic-reaction-like',
		'ic-recipe-solid',
		'ic-recipe',
		'ic-reply-level',
		'ic-save',
		'ic-search',
		'ic-share',
		'ic-speed',
		'ic-speed',
		'ic-steps',
		'ic-sync',
		'ic-tags',
		'ic-temperature',
		'ic-temperature',
		'ic-three-dots-horizontal',
		'ic-time-servings',
		'ic-time',
		'ic-translation',
		'ic-trash',
		'ic-unit-conversion-missing',
		'ic-unit-conversion-ok',
		'ic-unlock',
		'ic-upload',
		'ic-user-change',
		'ic-user-close',
		'ic-user-type',
		'ic-user',
		'ic-view',
		'ic-warning-info-small',
		'ic-warning-info',
		'ic-warning',
		'ic-xml-file',
	];

	constructor(
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
	) {
		this.registerIcons(this.topLevelIcons);
	}

	private registerIcons(iconNames: Array<string>, folder = ''): void {
		iconNames.forEach((iconName: string) => {
			this.matIconRegistry.addSvgIcon(
				iconName,
				this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/${folder}${iconName}.svg`),
			);
		});
	}
}
