{
	"isAdjustable": true,
	"isRequired": false,
	"isVisible": true,
	"label": "Accessories",
	"name": "accessories",
	"placeholder": "Choose accessories",
	"selectionType": "MULTIPLE",
	"valueType": "LINK",
	"_links": {
		"options": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option"
		},
		"prerequisite": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000000"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002"
		}
	}
}
