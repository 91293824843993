{
  "ingredientUnit": {
    "bag": {
      "plural": "пакетики",
      "singular": "пакетик"
    },
    "branch": {
      "plural": "гілочки",
      "singular": "гілочка"
    },
    "bunch": {
      "plural": "пучки",
      "singular": "пучок"
    },
    "can": {
      "plural": "банки",
      "singular": "банка"
    },
    "centiliter": {
      "plural": "сантилітри",
      "singular": "сантилітр"
    },
    "clove": {
      "plural": "зубчики",
      "singular": "зубчик"
    },
    "cup": {
      "plural": "чашки",
      "singular": "чашка"
    },
    "dash": {
      "plural": "щіпки",
      "singular": "щіпка"
    },
    "decigram": {
      "plural": "дециграми",
      "singular": "дециграм"
    },
    "deciliter": {
      "plural": "децилітри",
      "singular": "децилітр"
    },
    "drop": {
      "plural": "краплі",
      "singular": "крапля"
    },
    "fluidOunce": {
      "plural": "рідкі унції",
      "singular": "рідка унція (30мл)"
    },
    "gram": {
      "plural": "грами",
      "singular": "грам"
    },
    "kilogram": {
      "plural": "кілограми",
      "singular": "кілограм"
    },
    "liter": {
      "plural": "літри",
      "singular": "літр"
    },
    "microgram": {
      "plural": "мікрограми",
      "singular": "мікрограм"
    },
    "milligram": {
      "plural": "міліграми",
      "singular": "міліграм"
    },
    "milliliter": {
      "plural": "мілілітри",
      "singular": "мілілітр"
    },
    "ounce": {
      "plural": "унція (28г)",
      "singular": "унції"
    },
    "package": {
      "plural": "пакунки",
      "singular": "пакунок"
    },
    "piece": {
      "plural": "шматки",
      "singular": "шматок"
    },
    "pinch": {
      "plural": "дрібки",
      "singular": "дрібка"
    },
    "pound": {
      "plural": "фунти",
      "singular": "фунт (0,45кг = 16 унцій)"
    },
    "sachet": {
      "plural": "саше",
      "singular": "саше"
    },
    "shot": {
      "plural": "шоти",
      "singular": "шот (45мл)"
    },
    "slice": {
      "plural": "слайси / скибки",
      "singular": "слайс / скибка"
    },
    "stick": {
      "plural": "палички",
      "singular": "паличка"
    },
    "tablespoon": {
      "plural": "столові ложки",
      "singular": "столова ложка"
    },
    "teaspoon": {
      "plural": "чайні ложки",
      "singular": "чайна ложка"
    }
  }
}