{
	"_embedded": {
		"item": [
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7577de12-031e-485f-895b-762595d52eb7"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/2eeb032f-7475-4708-9d60-df6e83ce33ca_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/7577de12-031e-485f-895b-762595d52eb7"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-14B27C66",
				"createdAt": "2023-02-21T12:49:48.107140Z",
				"modifiedAt": "2023-02-21T12:49:53.473853Z",
				"status": "DRAFT",
				"managed": true,
				"translationCount": 1,
				"name": "Selenium recipe 21022023134855613152",
				"description": "Selenium recipe description 21022023134855613152",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92"
					},
					"self:getSelfByUUID": {
						"href": "cid:2e381eb4-4ab6-49b1-b105-96d715290f92",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7577de12-031e-485f-895b-762595d52eb7"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/7577de12-031e-485f-895b-762595d52eb7"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/2e381eb4-4ab6-49b1-b105-96d715290f92"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2e381eb4-4ab6-49b1-b105-96d715290f92/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=2e381eb4-4ab6-49b1-b105-96d715290f92{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/2e381eb4-4ab6-49b1-b105-96d715290f92{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/34983503-91db-412d-99e2-b68357286aed"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/cd7ff290-8073-46ce-a6c2-70ada2f8790d_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/34983503-91db-412d-99e2-b68357286aed"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-23305F57",
				"createdAt": "2023-02-21T12:48:26.492591Z",
				"modifiedAt": "2023-02-21T12:48:31.139912Z",
				"status": "DRAFT",
				"managed": true,
				"translationCount": 1,
				"name": "Selenium recipe 21022023134733919278",
				"description": "Selenium recipe description 21022023134733919278",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547"
					},
					"self:getSelfByUUID": {
						"href": "cid:0745c9b7-5e4b-4ed7-a5ce-e50432814547",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/34983503-91db-412d-99e2-b68357286aed"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/34983503-91db-412d-99e2-b68357286aed"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/0745c9b7-5e4b-4ed7-a5ce-e50432814547"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=0745c9b7-5e4b-4ed7-a5ce-e50432814547{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/0745c9b7-5e4b-4ed7-a5ce-e50432814547{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/289f9027-1a72-4141-a59a-50f41ab91ce0"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4ac4650d-4cfe-4caf-a58d-dadf7ae2e93d_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/289f9027-1a72-4141-a59a-50f41ab91ce0"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-2D8BC08A",
				"createdAt": "2023-02-21T12:47:05.199647Z",
				"modifiedAt": "2023-02-21T12:47:10.506441Z",
				"status": "DRAFT",
				"managed": true,
				"translationCount": 0,
				"name": "Selenium recipe 21022023134612836121",
				"description": "Selenium recipe description 21022023134612836121",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7"
					},
					"self:getSelfByUUID": {
						"href": "cid:e0905dcc-6eb0-49aa-9a05-860923d041f7",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/289f9027-1a72-4141-a59a-50f41ab91ce0"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/289f9027-1a72-4141-a59a-50f41ab91ce0"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/e0905dcc-6eb0-49aa-9a05-860923d041f7"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e0905dcc-6eb0-49aa-9a05-860923d041f7/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=e0905dcc-6eb0-49aa-9a05-860923d041f7{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/e0905dcc-6eb0-49aa-9a05-860923d041f7{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/bde67423-d928-49e4-a207-e04bdacedb85"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/40e28619-9c2a-4af4-8a3b-f946c66ecb43_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/bde67423-d928-49e4-a207-e04bdacedb85"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-0D6E67A2",
				"createdAt": "2023-02-21T12:43:36.536333Z",
				"modifiedAt": "2023-02-21T12:43:42.517049Z",
				"status": "DRAFT",
				"managed": true,
				"translationCount": 0,
				"name": "Selenium recipe 21022023134240368660",
				"description": "Selenium recipe description 21022023134240368660",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df"
					},
					"self:getSelfByUUID": {
						"href": "cid:fb03e175-443d-46a2-b9f4-69f8082080df",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/bde67423-d928-49e4-a207-e04bdacedb85"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/bde67423-d928-49e4-a207-e04bdacedb85"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/fb03e175-443d-46a2-b9f4-69f8082080df"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fb03e175-443d-46a2-b9f4-69f8082080df/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=fb03e175-443d-46a2-b9f4-69f8082080df{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/fb03e175-443d-46a2-b9f4-69f8082080df{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "UR-54D1F09B",
				"createdAt": "2023-02-21T11:48:43.211064Z",
				"modifiedAt": "2023-02-21T11:48:43.211064Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "12",
				"description": "",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9"
					},
					"self:getSelfByUUID": {
						"href": "cid:c546d48b-fd3f-4a1e-ba14-72808bed2ae9",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/c546d48b-fd3f-4a1e-ba14-72808bed2ae9"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=c546d48b-fd3f-4a1e-ba14-72808bed2ae9{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/c546d48b-fd3f-4a1e-ba14-72808bed2ae9{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/b4dc7616-3074-4ebe-a01c-1c8c72756cba"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/879e8a4c-dc9f-47b7-8fb0-6c94ce8018ac_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/b4dc7616-3074-4ebe-a01c-1c8c72756cba"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "UR-3D98DCB2",
				"createdAt": "2023-02-21T11:38:40.202564Z",
				"modifiedAt": "2023-02-21T11:38:40.202564Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "top recept",
				"description": "descr",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253"
					},
					"self:getSelfByUUID": {
						"href": "cid:c24d92c8-89ef-4867-ace5-2350a0b8d253",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/b4dc7616-3074-4ebe-a01c-1c8c72756cba"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/b4dc7616-3074-4ebe-a01c-1c8c72756cba"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/c24d92c8-89ef-4867-ace5-2350a0b8d253"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/c24d92c8-89ef-4867-ace5-2350a0b8d253/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=c24d92c8-89ef-4867-ace5-2350a0b8d253{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/c24d92c8-89ef-4867-ace5-2350a0b8d253{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/346665cd-0293-4e21-80a7-899f8d2917ab"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/1117d650-5eb1-48be-870a-ad197b01c392_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/346665cd-0293-4e21-80a7-899f8d2917ab"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-4572B79C",
				"createdAt": "2019-12-09T16:10:06.481669Z",
				"modifiedAt": "2023-02-21T11:36:33.836847Z",
				"status": "DRAFT",
				"managed": true,
				"statusReason": "TAKEN_OFFLINE_DRAFT",
				"translationCount": 45,
				"name": "Mango muesli balls with coconut flakes",
				"description": "You don’t feel hungry early in the morning or you just don’t have the time for eating breakfast? Don’t worry, these mango muesli balls are perfect for snacking on the go or at work and contain all the healthy ingredients of a regular muesli bowl.\n\nTip: The amount of ingredients in this recipe is optimal for the XXL Airfryer. You can fit slightly less of these balls in the XL model, fry in batches if you use the compact Airfryer. The temperature and time should be similar for a full basket of balls.",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80"
					},
					"self:getSelfByUUID": {
						"href": "cid:e2fafb01-fb19-4a5d-910e-68b77cf3cb80",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/346665cd-0293-4e21-80a7-899f8d2917ab"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/346665cd-0293-4e21-80a7-899f8d2917ab"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/e2fafb01-fb19-4a5d-910e-68b77cf3cb80"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=e2fafb01-fb19-4a5d-910e-68b77cf3cb80{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/e2fafb01-fb19-4a5d-910e-68b77cf3cb80{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/0701cf8b-9a08-499e-a164-24c470d19fbe"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/be5157a3-78f0-41b2-a86f-9df32b925ec4_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/0701cf8b-9a08-499e-a164-24c470d19fbe"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-FF916FED",
				"createdAt": "2022-03-21T15:43:25.268246Z",
				"modifiedAt": "2023-02-21T11:32:20.706083Z",
				"status": "DRAFT",
				"managed": true,
				"statusReason": "APPROVED_BUT_MOVED_FOR_EDITING_DRAFT",
				"translationCount": 41,
				"name": "Ribeye steak with asparagus",
				"description": "With this recipe, you can prepare a delicious steak dinner any day of the week. It comes together in about half an hour with just a couple of simple ingredients. Minimal seasoning allows the natural flavors of beef and asparagus to shine, and air frying keeps the asparagus tender and steak juicy. This easy dish is an excellent choice for a nutritious family meal or an elegant dinner to impress your guests.\n\nKeep the ribeye steak at room temperature for at least 30 minutes before cooking.",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2"
					},
					"self:getSelfByUUID": {
						"href": "cid:69258c23-1e1b-4727-861c-2a161f58d9d2",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/0701cf8b-9a08-499e-a164-24c470d19fbe"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/0701cf8b-9a08-499e-a164-24c470d19fbe"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/69258c23-1e1b-4727-861c-2a161f58d9d2"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/69258c23-1e1b-4727-861c-2a161f58d9d2/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=69258c23-1e1b-4727-861c-2a161f58d9d2{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/69258c23-1e1b-4727-861c-2a161f58d9d2{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/f69b627e-5c7d-43e8-b630-9ae071cbd60a"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4dd38b08-e659-4be0-8572-ebd5af93a249_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/f69b627e-5c7d-43e8-b630-9ae071cbd60a"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-9358302C",
				"createdAt": "2021-09-13T06:54:02.185952Z",
				"modifiedAt": "2023-02-21T11:29:44.280939Z",
				"status": "DRAFT",
				"managed": true,
				"statusReason": "APPROVED_BUT_MOVED_FOR_EDITING_DRAFT",
				"translationCount": 42,
				"name": "Crispy &amp; soft secret ingredient chicken tenders",
				"description": "Are chicken tenders at the top of your family&amp;apos;s all-time favorite dinners? With this simple twist on the classic recipe, you can make them even tastier by adding just one secret ingredient. \n\nMayonnaise in the egg coating will help the chicken stay juicy and the coating stick better, making the tenders extra crispy. \n\nAnd why not add some herbs and spices to the breadcrumbs for a kick of flavor? These succulent tenders take less than half an hour to prepare and do not require any oil for frying. Quick, easy and delicious – what&amp;apos;s not to love?",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df"
					},
					"self:getSelfByUUID": {
						"href": "cid:5cf5ae9a-4739-4ed6-92ce-339af69161df",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/f69b627e-5c7d-43e8-b630-9ae071cbd60a"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/f69b627e-5c7d-43e8-b630-9ae071cbd60a"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/5cf5ae9a-4739-4ed6-92ce-339af69161df"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5cf5ae9a-4739-4ed6-92ce-339af69161df/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=5cf5ae9a-4739-4ed6-92ce-339af69161df{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/5cf5ae9a-4739-4ed6-92ce-339af69161df{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "MR-22E3C2FA",
				"createdAt": "2023-02-21T11:16:23.582416Z",
				"modifiedAt": "2023-02-21T11:18:01.462004Z",
				"status": "DRAFT",
				"managed": true,
				"translationCount": 1,
				"name": "Cosmos 1.1lcl",
				"description": "czx",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032"
					},
					"self:getSelfByUUID": {
						"href": "cid:fe33e4d2-a9d0-4e34-835d-c1754e6ac032",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/fe33e4d2-a9d0-4e34-835d-c1754e6ac032"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=fe33e4d2-a9d0-4e34-835d-c1754e6ac032{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/fe33e4d2-a9d0-4e34-835d-c1754e6ac032{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/e9706252-e540-437d-a6f6-5a3c701a0865"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/4f5a2e6a-eb74-48bb-895d-47b835f903d8_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/e9706252-e540-437d-a6f6-5a3c701a0865"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-61A91024",
				"createdAt": "2023-02-21T11:14:09.625188Z",
				"modifiedAt": "2023-02-21T11:14:31.127181Z",
				"status": "APPROVED",
				"managed": true,
				"translationCount": 1,
				"name": "Cosmos 1gl",
				"description": "da",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a"
					},
					"self:getSelfByUUID": {
						"href": "cid:cf976df7-156f-4016-8065-be942572290a",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/e9706252-e540-437d-a6f6-5a3c701a0865"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/e9706252-e540-437d-a6f6-5a3c701a0865"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/cf976df7-156f-4016-8065-be942572290a"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/cf976df7-156f-4016-8065-be942572290a/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=cf976df7-156f-4016-8065-be942572290a{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/cf976df7-156f-4016-8065-be942572290a{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/f39971bd-c66a-4d02-bb77-fe0b8f523de9"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/075bd0ff-7d66-4ce7-b821-3e40840adc5e_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/f39971bd-c66a-4d02-bb77-fe0b8f523de9"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-A27C72B6",
				"createdAt": "2023-01-18T14:44:29.642808Z",
				"modifiedAt": "2023-02-21T10:51:31.783036Z",
				"status": "LIVE",
				"managed": true,
				"translationCount": 1,
				"name": "Breakfast oat pasta",
				"description": "Add a fun twist to your breakfast oats and transform them into pasta using just 3 ingredients and your Pasta Maker. To make oat pasta from scratch, we combined oat flour with spelt flour so it stays compact and extrudes well. \n\nServe it with peanut butter sauce and juicy berries for a satisfying breakfast that will please all pasta lovers.",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1"
					},
					"self:getSelfByUUID": {
						"href": "cid:a23a39bc-7c85-4260-b160-b3a0fe947ad1",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/f39971bd-c66a-4d02-bb77-fe0b8f523de9"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/f39971bd-c66a-4d02-bb77-fe0b8f523de9"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/a23a39bc-7c85-4260-b160-b3a0fe947ad1"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=a23a39bc-7c85-4260-b160-b3a0fe947ad1{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/a23a39bc-7c85-4260-b160-b3a0fe947ad1{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/1614ceca-63ef-4b7b-861d-6c5529076d20"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/bf8ff028-a2e3-497e-b467-c8ee6527a0ac_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/1614ceca-63ef-4b7b-861d-6c5529076d20"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-7E0457D9",
				"createdAt": "2023-02-20T22:36:49.435031Z",
				"modifiedAt": "2023-02-21T10:51:31.706460Z",
				"status": "LIVE",
				"managed": true,
				"translationCount": 1,
				"name": "Selenium recipe 20022023223606146253",
				"description": "Selenium recipe description 20022023223606146253",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16"
					},
					"self:getSelfByUUID": {
						"href": "cid:ff8c0247-c934-49fa-b5d1-342a9aba6b16",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/1614ceca-63ef-4b7b-861d-6c5529076d20"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/1614ceca-63ef-4b7b-861d-6c5529076d20"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/ff8c0247-c934-49fa-b5d1-342a9aba6b16"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=ff8c0247-c934-49fa-b5d1-342a9aba6b16{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/ff8c0247-c934-49fa-b5d1-342a9aba6b16{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/d6a8e193-b718-4d7e-9af0-bf57c0c7617c"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/194fa1bd-baa6-4bb1-b886-daa3ac290e3e_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/d6a8e193-b718-4d7e-9af0-bf57c0c7617c"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-E4BD99CB",
				"createdAt": "2023-01-19T13:30:53.428533Z",
				"modifiedAt": "2023-02-21T10:40:29.830495Z",
				"status": "LIVE",
				"managed": true,
				"translationCount": 1,
				"name": "No-knead zucchini bread",
				"description": "Made with fresh veggies and oat flour, this savory bread is a great way to add some extra fiber and nutrients to your meals. It&amp;apos;s moist, flavorful and takes minutes to put together – just mix everything and bake, no kneading or rising required.",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec"
					},
					"self:getSelfByUUID": {
						"href": "cid:6a30ea61-6832-4968-bf5d-f9de5765d5ec",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/d6a8e193-b718-4d7e-9af0-bf57c0c7617c"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/d6a8e193-b718-4d7e-9af0-bf57c0c7617c"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/6a30ea61-6832-4968-bf5d-f9de5765d5ec"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=6a30ea61-6832-4968-bf5d-f9de5765d5ec{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/6a30ea61-6832-4968-bf5d-f9de5765d5ec{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/488a4527-5476-47d4-9171-05a0de3572be"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd.jpg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_thumbnail.jpg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_high.jpg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/a9207a85-6a33-4063-a07b-ccc81c2224bd_medium.jpg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/488a4527-5476-47d4-9171-05a0de3572be"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "MR-E241B505",
				"createdAt": "2023-01-24T11:22:28.154220Z",
				"modifiedAt": "2023-02-21T10:40:25.892960Z",
				"status": "LIVE",
				"managed": true,
				"translationCount": 1,
				"name": "Apple fries with raspberry ketchup",
				"description": "This quick recipe is a great way to get a few laughs. Fresh apples are cut into french fries and served with a raspberry chocolate sauce that resembles ketchup. Make this healthy sweet snack in only 15 minutes from start to finish. ",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b"
					},
					"self:getSelfByUUID": {
						"href": "cid:2bc5bfd4-100a-47f1-a66b-4b548baaf94b",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/488a4527-5476-47d4-9171-05a0de3572be"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/488a4527-5476-47d4-9171-05a0de3572be"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/2bc5bfd4-100a-47f1-a66b-4b548baaf94b"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=2bc5bfd4-100a-47f1-a66b-4b548baaf94b{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/2bc5bfd4-100a-47f1-a66b-4b548baaf94b{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"_embedded": {
					"media": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db.jpeg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_thumbnail.jpeg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_high.jpeg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_medium.jpeg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/a6617869-2715-4199-b3cb-354dce017939"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					},
					"image": {
						"url": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db.jpeg",
						"availableSizes": {
							"thumbnail": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_thumbnail.jpeg",
							"high": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_high.jpeg",
							"medium": "https://dev.backend.ka.philips.com/media/cf-s3-f8aa42f9-526e-4ff5-b97a-7fe674f5edb9/7e80d606-95f8-48b4-8f51-28a2ca2850db_medium.jpeg"
						},
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/Media/a6617869-2715-4199-b3cb-354dce017939"
							},
							"collection": {
								"href": "https://dev.backend.ka.philips.com/api/Media"
							}
						}
					}
				},
				"shortId": "UR-0159785F",
				"createdAt": "2023-02-21T08:41:32.931664Z",
				"modifiedAt": "2023-02-21T08:41:32.931664Z",
				"status": "LIVE",
				"managed": false,
				"translationCount": 1,
				"name": "Test Peta3",
				"description": "Goff",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb"
					},
					"self:getSelfByUUID": {
						"href": "cid:6d1c1687-d469-4e17-b310-a32f8b32b2fb",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/a6617869-2715-4199-b3cb-354dce017939"
					},
					"image": {
						"href": "https://dev.backend.ka.philips.com/api/Media/a6617869-2715-4199-b3cb-354dce017939"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/6d1c1687-d469-4e17-b310-a32f8b32b2fb"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=6d1c1687-d469-4e17-b310-a32f8b32b2fb{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/6d1c1687-d469-4e17-b310-a32f8b32b2fb{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "UR-19C78667",
				"createdAt": "2023-02-21T08:16:56.655291Z",
				"modifiedAt": "2023-02-21T08:16:56.655291Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "11",
				"description": "",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef"
					},
					"self:getSelfByUUID": {
						"href": "cid:fbc39bb7-0093-46ab-98e4-48cf79455eef",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/fbc39bb7-0093-46ab-98e4-48cf79455eef"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/fbc39bb7-0093-46ab-98e4-48cf79455eef/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=fbc39bb7-0093-46ab-98e4-48cf79455eef{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/fbc39bb7-0093-46ab-98e4-48cf79455eef{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "UR-ECD8681B",
				"createdAt": "2023-02-21T08:16:14.626363Z",
				"modifiedAt": "2023-02-21T08:16:14.626363Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "10",
				"description": "",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38"
					},
					"self:getSelfByUUID": {
						"href": "cid:23962bb7-92f9-4bcd-9a08-0f57a5fedd38",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/23962bb7-92f9-4bcd-9a08-0f57a5fedd38"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=23962bb7-92f9-4bcd-9a08-0f57a5fedd38{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/23962bb7-92f9-4bcd-9a08-0f57a5fedd38{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "UR-2546395F",
				"createdAt": "2023-02-21T08:15:51.824513Z",
				"modifiedAt": "2023-02-21T08:15:51.824513Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "9",
				"description": "",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31"
					},
					"self:getSelfByUUID": {
						"href": "cid:9d27f6a4-a48a-43ee-a8de-0b390a4e0d31",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=9d27f6a4-a48a-43ee-a8de-0b390a4e0d31{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			},
			{
				"shortId": "UR-0D2A64DD",
				"createdAt": "2023-02-21T08:15:31.045437Z",
				"modifiedAt": "2023-02-21T08:15:31.045437Z",
				"status": "APPROVED",
				"managed": false,
				"translationCount": 1,
				"name": "8",
				"description": "",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90"
					},
					"self:getSelfByUUID": {
						"href": "cid:31ab4f5f-6379-4cf1-a7d2-6543df9aaf90",
						"type": "application/vnd.api+json",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
					},
					"steps": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Step{?deviceModel,country}",
						"templated": true
					},
					"categories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Category{?parent,root}",
						"templated": true
					},
					"deviceCategories": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Category?root=DEVICE"
					},
					"favouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite"
					},
					"unfavouriteMe": {
						"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Translation"
					},
					"publications": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Publication{?countries}",
						"templated": true
					},
					"history": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/History{?event}",
						"templated": true
					},
					"publicationsTemplated": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Publication{?countries}",
						"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/1107623959/Spaces+Architecture",
						"templated": true
					},
					"managingCountries": {
						"href": "https://dev.backend.ka.philips.com/api/Content/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Country"
					},
					"articles": {
						"href": "https://dev.backend.ka.philips.com/api/Article?recipeId=31ab4f5f-6379-4cf1-a7d2-6543df9aaf90{&q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
						"templated": true
					},
					"comments": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Comment"
					},
					"metrics": {
						"href": "https://dev.backend.ka.philips.com/api/Content/Metrics/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90{?contentProfileId}",
						"templated": true
					},
					"incompatibleDevices": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90/Device$incompatibleDevices{?unitSystem}",
						"templated": true
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/search/Recipe?includePremium=true&search=domain_id%3Din%3D%2800000004-0000-0000-0000-d00000000000%2C00000001-0000-0000-0000-d00000000000%2C00000006-0000-0000-0000-d00000000000%2C061b497e-122b-42ca-9d2f-8b5b681e8121%2C1dbda8e8-8c4b-476a-96ef-1cfc8df04449%2C00000007-0000-0000-0000-d00000000000%2C00000003-0000-0000-0000-d00000000000%2C6a4ab5de-7b6f-44b1-a14e-849390f0104e%2C687be2dd-ed8e-4c8c-9e17-88af825e5b13%2C00000002-0000-0000-0000-d00000000000%2Cb2bb3f27-1adc-4bb6-814e-5e2d17e345e6%2C52c5b4e6-1149-4440-889f-0c8b998c2668%2Cb6656dc5-b8b9-4ee3-9409-66a6a48a9910%2C9e899ac1-1a83-4e99-bee7-47811da9d645%2C979d151a-1e61-4d48-9ec1-3de4d095a50d%2Cc819be9d-0aa0-4e66-8309-1a8ff9adf667%2Cf5d579b1-aae5-4d68-b5f8-5ff14e3dcf25%2C930cf640-1c6b-4dcb-800f-dcce0a25846c%2Cb6570693-7a43-4038-a389-787fa14aaf9e%2Cd34d5ea3-d51a-4a50-b3e8-0c24256ffc49%2Cb504326a-98e1-4b17-9e5f-b2bae10743b5%2C00000005-0000-0000-0000-d00000000000%2C9aa3befa-89c1-47df-bac7-3322421de3a2%29%3Bcontent_status%3Din%3D%28APPROVED%2CDRAFT%2CIN_REVIEW%2CLIVE_FLAGGED%2CLIVE%29&page=1&size=20&sort=modified_at,desc"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/search/Recipe?includePremium=true&search=domain_id%3Din%3D%2800000004-0000-0000-0000-d00000000000%2C00000001-0000-0000-0000-d00000000000%2C00000006-0000-0000-0000-d00000000000%2C061b497e-122b-42ca-9d2f-8b5b681e8121%2C1dbda8e8-8c4b-476a-96ef-1cfc8df04449%2C00000007-0000-0000-0000-d00000000000%2C00000003-0000-0000-0000-d00000000000%2C6a4ab5de-7b6f-44b1-a14e-849390f0104e%2C687be2dd-ed8e-4c8c-9e17-88af825e5b13%2C00000002-0000-0000-0000-d00000000000%2Cb2bb3f27-1adc-4bb6-814e-5e2d17e345e6%2C52c5b4e6-1149-4440-889f-0c8b998c2668%2Cb6656dc5-b8b9-4ee3-9409-66a6a48a9910%2C9e899ac1-1a83-4e99-bee7-47811da9d645%2C979d151a-1e61-4d48-9ec1-3de4d095a50d%2Cc819be9d-0aa0-4e66-8309-1a8ff9adf667%2Cf5d579b1-aae5-4d68-b5f8-5ff14e3dcf25%2C930cf640-1c6b-4dcb-800f-dcce0a25846c%2Cb6570693-7a43-4038-a389-787fa14aaf9e%2Cd34d5ea3-d51a-4a50-b3e8-0c24256ffc49%2Cb504326a-98e1-4b17-9e5f-b2bae10743b5%2C00000005-0000-0000-0000-d00000000000%2C9aa3befa-89c1-47df-bac7-3322421de3a2%29%3Bcontent_status%3Din%3D%28APPROVED%2CDRAFT%2CIN_REVIEW%2CLIVE_FLAGGED%2CLIVE%29&page=1&size=20&sort=modified_at,desc"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/search/Recipe?includePremium=true&search=domain_id%3Din%3D%2800000004-0000-0000-0000-d00000000000%2C00000001-0000-0000-0000-d00000000000%2C00000006-0000-0000-0000-d00000000000%2C061b497e-122b-42ca-9d2f-8b5b681e8121%2C1dbda8e8-8c4b-476a-96ef-1cfc8df04449%2C00000007-0000-0000-0000-d00000000000%2C00000003-0000-0000-0000-d00000000000%2C6a4ab5de-7b6f-44b1-a14e-849390f0104e%2C687be2dd-ed8e-4c8c-9e17-88af825e5b13%2C00000002-0000-0000-0000-d00000000000%2Cb2bb3f27-1adc-4bb6-814e-5e2d17e345e6%2C52c5b4e6-1149-4440-889f-0c8b998c2668%2Cb6656dc5-b8b9-4ee3-9409-66a6a48a9910%2C9e899ac1-1a83-4e99-bee7-47811da9d645%2C979d151a-1e61-4d48-9ec1-3de4d095a50d%2Cc819be9d-0aa0-4e66-8309-1a8ff9adf667%2Cf5d579b1-aae5-4d68-b5f8-5ff14e3dcf25%2C930cf640-1c6b-4dcb-800f-dcce0a25846c%2Cb6570693-7a43-4038-a389-787fa14aaf9e%2Cd34d5ea3-d51a-4a50-b3e8-0c24256ffc49%2Cb504326a-98e1-4b17-9e5f-b2bae10743b5%2C00000005-0000-0000-0000-d00000000000%2C9aa3befa-89c1-47df-bac7-3322421de3a2%29%3Bcontent_status%3Din%3D%28APPROVED%2CDRAFT%2CIN_REVIEW%2CLIVE_FLAGGED%2CLIVE%29&page=2&size=20&sort=modified_at,desc"
		},
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/search/Recipe?includePremium=true&search=domain_id%3Din%3D%2800000004-0000-0000-0000-d00000000000%2C00000001-0000-0000-0000-d00000000000%2C00000006-0000-0000-0000-d00000000000%2C061b497e-122b-42ca-9d2f-8b5b681e8121%2C1dbda8e8-8c4b-476a-96ef-1cfc8df04449%2C00000007-0000-0000-0000-d00000000000%2C00000003-0000-0000-0000-d00000000000%2C6a4ab5de-7b6f-44b1-a14e-849390f0104e%2C687be2dd-ed8e-4c8c-9e17-88af825e5b13%2C00000002-0000-0000-0000-d00000000000%2Cb2bb3f27-1adc-4bb6-814e-5e2d17e345e6%2C52c5b4e6-1149-4440-889f-0c8b998c2668%2Cb6656dc5-b8b9-4ee3-9409-66a6a48a9910%2C9e899ac1-1a83-4e99-bee7-47811da9d645%2C979d151a-1e61-4d48-9ec1-3de4d095a50d%2Cc819be9d-0aa0-4e66-8309-1a8ff9adf667%2Cf5d579b1-aae5-4d68-b5f8-5ff14e3dcf25%2C930cf640-1c6b-4dcb-800f-dcce0a25846c%2Cb6570693-7a43-4038-a389-787fa14aaf9e%2Cd34d5ea3-d51a-4a50-b3e8-0c24256ffc49%2Cb504326a-98e1-4b17-9e5f-b2bae10743b5%2C00000005-0000-0000-0000-d00000000000%2C9aa3befa-89c1-47df-bac7-3322421de3a2%29%3Bcontent_status%3Din%3D%28APPROVED%2CDRAFT%2CIN_REVIEW%2CLIVE_FLAGGED%2CLIVE%29&page=890&size=20&sort=modified_at,desc"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/2e381eb4-4ab6-49b1-b105-96d715290f92"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/0745c9b7-5e4b-4ed7-a5ce-e50432814547"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/e0905dcc-6eb0-49aa-9a05-860923d041f7"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/fb03e175-443d-46a2-b9f4-69f8082080df"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/c546d48b-fd3f-4a1e-ba14-72808bed2ae9"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/c24d92c8-89ef-4867-ace5-2350a0b8d253"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/e2fafb01-fb19-4a5d-910e-68b77cf3cb80"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/69258c23-1e1b-4727-861c-2a161f58d9d2"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/5cf5ae9a-4739-4ed6-92ce-339af69161df"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/fe33e4d2-a9d0-4e34-835d-c1754e6ac032"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/cf976df7-156f-4016-8065-be942572290a"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/a23a39bc-7c85-4260-b160-b3a0fe947ad1"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/ff8c0247-c934-49fa-b5d1-342a9aba6b16"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/6a30ea61-6832-4968-bf5d-f9de5765d5ec"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/2bc5bfd4-100a-47f1-a66b-4b548baaf94b"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/6d1c1687-d469-4e17-b310-a32f8b32b2fb"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/fbc39bb7-0093-46ab-98e4-48cf79455eef"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/23962bb7-92f9-4bcd-9a08-0f57a5fedd38"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/9d27f6a4-a48a-43ee-a8de-0b390a4e0d31"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/31ab4f5f-6379-4cf1-a7d2-6543df9aaf90"
			}
		]
	},
	"page": {
		"size": 20,
		"totalElements": 17783,
		"totalPages": 890,
		"number": 1
	}
}
