{
  "ingredientUnit": {
    "bag": {
      "plural": "förpackningar",
      "singular": "förpackning"
    },
    "branch": {
      "plural": "kvistar",
      "singular": "kvist"
    },
    "bunch": {
      "plural": "knippor",
      "singular": "knippa"
    },
    "can": {
      "plural": "burkar",
      "singular": "burk"
    },
    "centiliter": {
      "plural": "centiliter",
      "singular": "centiliter"
    },
    "clove": {
      "plural": "klyftor",
      "singular": "klyfta"
    },
    "cup": {
      "plural": "amerikanska cups",
      "singular": "cup"
    },
    "dash": {
      "plural": "stänk",
      "singular": "skvätt"
    },
    "decigram": {
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "plural": "deciliter",
      "singular": "deciliter"
    },
    "drop": {
      "plural": "droppar",
      "singular": "droppe"
    },
    "gram": {
      "plural": "grams",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilo",
      "singular": "kilo"
    },
    "liter": {
      "plural": "liter",
      "singular": "liter"
    },
    "milligram": {
      "plural": "miligram",
      "singular": "miligram"
    },
    "milliliter": {
      "plural": "mililiter",
      "singular": "mililiter"
    },
    "ounce": {
      "plural": "oz",
      "singular": "oz"
    },
    "package": {
      "plural": "paket",
      "singular": "paket"
    },
    "piece": {
      "plural": "styck",
      "singular": "styck"
    },
    "pinch": {
      "plural": "nypor",
      "singular": "nypa"
    },
    "sachet": {
      "plural": "påse",
      "singular": "påse"
    },
    "slice": {
      "plural": "skivor",
      "singular": "skiva"
    },
    "tablespoon": {
      "plural": "matskedar",
      "singular": "matsked"
    },
    "teaspoon": {
      "plural": "teskedar",
      "singular": "tesked"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Ja",
        "label": "För nybörjare",
        "notEasyToStart": "Nej",
        "placeholder": "För nybörjare"
      }
    }
  }
}