{
  "ingredientUnit": {
    "bag": {
      "plural": "pussia",
      "singular": "pussi"
    },
    "branch": {
      "plural": "oksat",
      "singular": "oksa"
    },
    "bunch": {
      "plural": "nippua",
      "singular": "nippu"
    },
    "can": {
      "plural": "tölkkiä",
      "singular": "tölkki"
    },
    "centiliter": {
      "plural": "senttilitraa",
      "singular": "senttilitra"
    },
    "clove": {
      "plural": "kynttä",
      "singular": "kynsi"
    },
    "cup": {
      "plural": "kuppia",
      "singular": "kuppi"
    },
    "dash": {
      "plural": "tilkkaa",
      "singular": "tilkka"
    },
    "decigram": {
      "plural": "desigrammaa",
      "singular": "desigramma"
    },
    "deciliter": {
      "plural": "desilitraa",
      "singular": "desilitra"
    },
    "drop": {
      "plural": "pisaraa",
      "singular": "pisara"
    },
    "gram": {
      "plural": "grammaa",
      "singular": "gramma"
    },
    "kilogram": {
      "plural": "kilogrammaa",
      "singular": "kilogramma"
    },
    "liter": {
      "plural": "litraa",
      "singular": "litra"
    },
    "milligram": {
      "plural": "milligrammaa",
      "singular": "milligramma"
    },
    "milliliter": {
      "plural": "millilitraa",
      "singular": "millilitra"
    },
    "ounce": {
      "plural": "unssia",
      "singular": "unssi"
    },
    "package": {
      "plural": "pakettia",
      "singular": "paketti"
    },
    "piece": {
      "plural": "kappaletta",
      "singular": "kappale"
    },
    "pinch": {
      "plural": "hyppysellistä",
      "singular": "hyppysellinen"
    },
    "sachet": {
      "plural": "pussia",
      "singular": "pussi"
    },
    "slice": {
      "plural": "siivua",
      "singular": "siivu"
    },
    "tablespoon": {
      "plural": "ruokalusikkaa",
      "singular": "ruokalusikka"
    },
    "teaspoon": {
      "plural": "teelusikkaa",
      "singular": "teelusikka"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Kyllä",
        "label": "Helppo aloittaa",
        "notEasyToStart": "Ei",
        "placeholder": "Helppo aloittaa"
      }
    }
  }
}