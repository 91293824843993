{
	"_embedded": {
		"importResults": {
			"_embedded": {
				"item": [
					{
						"itemType": "FILE",
						"resultType": "INVALID_FILE_PROVIDED",
						"message": "File 'MA-CD2BB131_Cooking_great_food_just_got_easier_en-GB_1681739775562.xml' is not a valid: Error lineNumber: 2; columnNumber: 101; cvc-complex-type.3.2.2: Attribute 'trggLang' is not allowed to appear in element 'xliff'.",
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/93f012d6-2e41-4713-bd3d-7e83d78b7459"
							},
							"importJob": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/548bb0ac-8cb0-441d-868f-39bfd1325f11"
							}
						}
					},
					{
						"itemType": "FILE",
						"resultType": "INVALID_FILE_PROVIDED",
						"message": "File 'MC-6F2B8F96_Energy-boosting_snacks_and_smoothies_en-GB_1681739692597.xml' is not a valid: Error lineNumber: 2; columnNumber: 101; cvc-complex-type.3.2.2: Attribute 'trggLang' is not allowed to appear in element 'xliff'.",
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/1e736ad4-7102-4212-ad8b-337cf90fb17b"
							},
							"importJob": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/548bb0ac-8cb0-441d-868f-39bfd1325f11"
							}
						}
					},
					{
						"itemType": "FILE",
						"resultType": "INVALID_FILE_PROVIDED",
						"message": "File 'MT-6D5BCA9E_A_happy_Easter_begins_with_a_great_brunch_en-GB_1681739734862.xml' is not a valid: Error lineNumber: 2; columnNumber: 101; cvc-complex-type.3.2.2: Attribute 'trggLang' is not allowed to appear in element 'xliff'.",
						"_links": {
							"self": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/7e70e75d-8850-415d-b06b-b1843dacd632"
							},
							"importJob": {
								"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/548bb0ac-8cb0-441d-868f-39bfd1325f11"
							}
						}
					}
				]
			},
			"_links": {
				"item": [
					{
						"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/93f012d6-2e41-4713-bd3d-7e83d78b7459"
					},
					{
						"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/1e736ad4-7102-4212-ad8b-337cf90fb17b"
					},
					{
						"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/Result/7e70e75d-8850-415d-b06b-b1843dacd632"
					}
				]
			}
		}
	},
	"date": "2023-06-07T10:21:40.919025Z",
	"importStatus": "FAILED",
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/548bb0ac-8cb0-441d-868f-39bfd1325f11"
		},
		"importResults": {
			"href": "https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/548bb0ac-8cb0-441d-868f-39bfd1325f11/Result"
		}
	}
}
