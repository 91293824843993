{
  "action": {
    "assignUnassignToMyCountryProfile": "Add / Remove naar mijn land profiel"
  },
  "button": {
    "cancel": "Annuleren",
    "close": "Dichtbij",
    "ok": "OK\n"
  },
  "common": {
    "consumer": {
      "plural": "Consumenten",
      "singular": "Consument"
    },
    "country": {
      "singular": "Land"
    },
    "recipe": {
      "byConsumer": "Door Consumer",
      "byPhilips": "Door Philips",
      "plural": "Recepten"
    }
  },
  "dashboard": {
    "statistics": {
      "header": {
        "active": "Actief",
        "all": "Alle",
        "live": "Live",
        "other": "Anders\n"
      },
      "subtitle": "Hier is wat er gebeurd is in de HomeID gemeenschap\n",
      "title": "Welkom, {{ name }}!",
      "total": "TOTAAL"
    }
  },
  "deviceFamily": {
    "airfryer": "Airfryer"
  },
  "error": {
    "generic": "Fout",
    "http_401": "Onbevoegd",
    "http_403": "Verboden",
    "isRequired": "{{ item }} is verplicht\n",
    "itemExists": "Item bestaat al"
  },
  "ingredient": {
    "choose": "Kies ingrediënt",
    "mergeModelTitle": "Vervang ingrediënt \"{{ ingredient }}\" met",
    "nameOrId": "Ingredientnaam of ID",
    "noResults": "Geen ingrediënten gevonden",
    "replace": "Vervangen",
    "replaceWith": "Vervangen door ...",
    "singular": "Ingrediënt"
  },
  "ingredientUnit": {
    "bag": {
      "plural": "pakken",
      "singular": "pak"
    },
    "branch": {
      "plural": "takjes",
      "singular": "takje"
    },
    "bunch": {
      "plural": "bosjes",
      "singular": "bosje"
    },
    "can": {
      "plural": "blikken",
      "singular": "blik"
    },
    "centiliter": {
      "plural": "centiliter",
      "singular": "centiliter"
    },
    "clove": {
      "plural": "teentjes",
      "singular": "teentje"
    },
    "cup": {
      "plural": "kopjes",
      "singular": "kopje"
    },
    "dash": {
      "plural": "scheutjes",
      "singular": "scheutje"
    },
    "decigram": {
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "plural": "deciliter",
      "singular": "deciliter"
    },
    "drop": {
      "plural": "druppels",
      "singular": "druppel"
    },
    "gram": {
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilogram",
      "singular": "kilogram"
    },
    "liter": {
      "plural": "liters",
      "singular": "liter"
    },
    "milligram": {
      "plural": "milligram",
      "singular": "milligram"
    },
    "milliliter": {
      "plural": "milliliter",
      "singular": "milliliter"
    },
    "package": {
      "plural": "zakken",
      "singular": "zak"
    },
    "piece": {
      "plural": "stukken",
      "singular": " stuk"
    },
    "pinch": {
      "plural": "snufjes",
      "singular": "snufje"
    },
    "sachet": {
      "plural": "zakjes",
      "singular": "zakje"
    },
    "slice": {
      "plural": "sneetjes",
      "singular": "sneetje"
    },
    "tablespoon": {
      "plural": "eetlepels",
      "singular": "eetlepel"
    },
    "teaspoon": {
      "plural": "theelepels",
      "singular": "theelepel"
    }
  },
  "language": {
    "choose": "Kies een taal",
    "singular": "Taal"
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Ja",
        "label": "Beginnersrecepten",
        "notEasyToStart": "Nee",
        "placeholder": "Beginnersrecepten"
      }
    }
  },
  "role": {
    "contentManager": "Content manager",
    "globalContentManager": "Global content manager",
    "systemAdministrator": "System administrator"
  },
  "unknownTranslationFor": {
    "ingredient": "Onbekende vertaling voor ingrediënt {{id}}"
  },
  "warning": {
    "iedge": {
      "description": "Het lijkt erop dat u Internet Explorer of Edge browser gebruikt. Applicatie kan zich niet gedragen zoals verwacht.\n\n<br>\n\nAls u problemen ondervindt, probeer dan Google Chrome of Mozilla Firefox te gebruiken.",
      "title": "Internet Explorer / Edge"
    }
  }
}