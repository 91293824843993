{
  "ingredientUnit": {
    "bag": {
      "plural": "bolsas",
      "singular": "bolsa"
    },
    "branch": {
      "plural": "ramas",
      "singular": "rama"
    },
    "bunch": {
      "plural": "racimos",
      "singular": "racimo"
    },
    "can": {
      "plural": "latas",
      "singular": "lata"
    },
    "centiliter": {
      "plural": "centilitros",
      "singular": "centilitro"
    },
    "clove": {
      "plural": "dientes",
      "singular": "diente"
    },
    "cup": {
      "plural": "tazas",
      "singular": "taza"
    },
    "dash": {
      "plural": "pizcas",
      "singular": "pizca"
    },
    "decigram": {
      "plural": "decigramos",
      "singular": "decigramo"
    },
    "deciliter": {
      "plural": "decilitros",
      "singular": "decilitro"
    },
    "drop": {
      "plural": "gotas",
      "singular": "gota"
    },
    "gram": {
      "plural": "gramos",
      "singular": "gramo"
    },
    "kilogram": {
      "plural": "kilogramos",
      "singular": "kilogramo"
    },
    "liter": {
      "plural": "litros",
      "singular": "litro"
    },
    "milligram": {
      "plural": "miligramos",
      "singular": "miligramo"
    },
    "milliliter": {
      "plural": "mililitros",
      "singular": "mililitro"
    },
    "ounce": {
      "plural": "onzas",
      "singular": "onza"
    },
    "piece": {
      "plural": "trozos",
      "singular": "trozo"
    },
    "pinch": {
      "plural": "pizquitas",
      "singular": "pizquita"
    },
    "sachet": {
      "plural": "sobrecitos",
      "singular": "sobrecito"
    },
    "slice": {
      "plural": "rebanadas",
      "singular": "rebanada"
    },
    "tablespoon": {
      "plural": "cucharadas",
      "singular": "cucharada"
    },
    "teaspoon": {
      "plural": "cucharaditas",
      "singular": "cucharadita"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Sí",
        "label": "Fácil de comenzar",
        "notEasyToStart": "No",
        "placeholder": "Fácil de comenzar"
      }
    }
  }
}