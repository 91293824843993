{
	"_embedded": {
		"item": [
			{
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option/00000000-0000-0000-0000-000000000002"
					},
					"value": {
						"href": "https://dev.backend.ka.philips.com/api/Accessory/00000000-0000-0000-0000-000000000001"
					}
				},
				"isPreselected": true,
				"label": "Bottom tray"
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option/00000000-0000-0000-0000-000000000002"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option?page=1&size=10"
		}
	}
}
