/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { SSO_PROVIDERS_DATA_TOKEN, SsoProvider } from '../../enums/sso-provider.enum';

export const SsoLandingPageGuard = () => {
	const authService = inject(AuthService);
	const ssoProvidersData = inject(SSO_PROVIDERS_DATA_TOKEN);
	const availableProviders = Object.keys(ssoProvidersData).filter(
		(provider) => ssoProvidersData[provider].available,
	) as Array<SsoProvider>;

	if (!availableProviders.length) {
		throw new Error('No providers available');
	}

	if (availableProviders.length === 1) {
		authService.goToLogin(authService.redirectUrlAfterLogin, availableProviders[0]);
		return false;
	}

	return true;
};
