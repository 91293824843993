{
  "ingredientUnit": {
    "bag": {
      "empty": "paquets",
      "plural": "sachets",
      "singular": "sachet"
    },
    "branch": {
      "empty": "branches",
      "plural": "branches",
      "singular": "branche"
    },
    "bunch": {
      "empty": "bouquets",
      "plural": "bouquets",
      "singular": "bouquet"
    },
    "can": {
      "empty": "boîtes",
      "plural": "boîtes",
      "singular": "boîte"
    },
    "centiliter": {
      "empty": "centilitres",
      "plural": "centilitres",
      "singular": "centilitre"
    },
    "clove": {
      "empty": "gousses",
      "plural": "gousses",
      "singular": "gousse"
    },
    "cup": {
      "empty": "tasses",
      "plural": "tasses",
      "singular": "tasse"
    },
    "dash": {
      "empty": "filets",
      "plural": "filets",
      "singular": "filet"
    },
    "decigram": {
      "empty": "décigramme",
      "plural": "décigramme",
      "singular": "décigramme"
    },
    "deciliter": {
      "empty": "décilitres",
      "plural": "décilitres",
      "singular": "décilitre"
    },
    "drop": {
      "empty": "gouttes",
      "plural": "gouttes",
      "singular": "goutte"
    },
    "fluidOunce": {
      "empty": "onces liquides",
      "plural": "onces liquides",
      "singular": "once liquide"
    },
    "gram": {
      "empty": "grammes",
      "plural": "grammes",
      "singular": "gramme"
    },
    "kilogram": {
      "empty": "kilogrammes",
      "plural": "kilogrammes",
      "singular": "kilogramme"
    },
    "liter": {
      "empty": "litres",
      "plural": "litres",
      "singular": "litre"
    },
    "microgram": {
      "empty": "microgrammes",
      "plural": "microgrammes",
      "singular": "microgramme"
    },
    "milligram": {
      "empty": "milligrammes",
      "plural": "milligrammes",
      "singular": "milligramme"
    },
    "milliliter": {
      "empty": "millilitres",
      "plural": "millilitres",
      "singular": "millilitre"
    },
    "ounce": {
      "empty": "onces",
      "plural": "onces",
      "singular": "once"
    },
    "package": {
      "plural": "paquets",
      "singular": "paquet"
    },
    "piece": {
      "empty": "morceaux",
      "plural": "morceaux",
      "singular": "morceau"
    },
    "pinch": {
      "empty": "pincées",
      "plural": "pincées",
      "singular": "pincée"
    },
    "pound": {
      "empty": "livres",
      "plural": "livres",
      "singular": "livre"
    },
    "sachet": {
      "empty": "sachet",
      "plural": "sachets",
      "singular": "sachet"
    },
    "shot": {
      "empty": "verres à gnôle",
      "plural": "verres à gnôle",
      "singular": "verre à gnôle"
    },
    "slice": {
      "empty": "tranches",
      "plural": "tranches",
      "singular": "tranche"
    },
    "stick": {
      "empty": "bâtons",
      "plural": "bâtons",
      "singular": "bâton"
    },
    "tablespoon": {
      "empty": "cuillères à soupe",
      "plural": "cuillères à soupe",
      "singular": "cuillère à soupe"
    },
    "teaspoon": {
      "empty": "cuillères à café",
      "plural": "cuillères à café",
      "singular": "cuillère à café"
    }
  }
}