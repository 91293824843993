{
	"_links": {
		"self": {
			"href": "https://dev.backend.ka.philips.com/api"
		},
		"administratorSelf": {
			"href": "https://dev.backend.ka.philips.com/api/Administrator/self"
		},
		"administrator": {
			"href": "https://dev.backend.ka.philips.com/api/00000001-0000-0000-0000-d00000000000/Administrator/{id}",
			"templated": true
		},
		"administrators": {
			"href": "https://dev.backend.ka.philips.com/api/00000001-0000-0000-0000-d00000000000/Administrator"
		},
		"announcements": {
			"href": "https://dev.backend.ka.philips.com/api/Announcement{?q,categories,publishedInCountries,notPublishedInCountries,publishedAtStartDate,status,type,linkedContentType,managingCountries,blockKey}",
			"templated": true
		},
		"articles": {
			"href": "https://dev.backend.ka.philips.com/api/Article{?recipeId,q,language,managingCountry,category,status,country,deviceModel,hasVideo,includePremium}",
			"templated": true
		},
		"categories": {
			"href": "https://dev.backend.ka.philips.com/api/Category{?slug,categories,parent}",
			"templated": true
		},
		"collections": {
			"href": "https://dev.backend.ka.philips.com/api/Collection{?q,category,status,country,managingCountry,language,shortId,managed,includePremium,isPremium,blockKey}",
			"templated": true
		},
		"countries": {
			"href": "https://dev.backend.ka.philips.com/api/Country{?code,enabled}",
			"templated": true
		},
		"countryProfileTypeCategories": {
			"href": "https://dev.backend.ka.philips.com/api/Category?parent=COUNTRY_PROFILE_TYPE{&slug,categories}",
			"templated": true
		},
		"devices": {
			"href": "https://dev.backend.ka.philips.com/api/Device"
		},
		"food": {
			"href": "https://dev.backend.ka.philips.com/api/Food{?q,shortId,allergens,universalProductCode,country,categories,createdBy}",
			"templated": true
		},
		"notices": {
			"href": "https://dev.backend.ka.philips.com/api/Notice?publishedInCountry={publishedInCountry}{&status}",
			"templated": true
		},
		"recipeSearch": {
			"href": "https://dev.backend.ka.philips.com/api/search/Recipe?search={search}{&includePremium}",
			"templated": true
		},
		"inspirationalRecipeBooks": {
			"href": "https://dev.backend.ka.philips.com/api/Collection$inspirational{?category,status,country}",
			"templated": true
		},
		"foodCategories": {
			"href": "https://dev.backend.ka.philips.com/api/Food/Category{?categories}",
			"templated": true
		},
		"surveys": {
			"href": "https://dev.backend.ka.philips.com/api/Survey{?categories}",
			"templated": true
		},
		"filters": {
			"href": "https://dev.backend.ka.philips.com/api/Filter?contentType={contentType}&category={category}{&isQuick}",
			"templated": true
		},
		"capabilities": {
			"href": "https://dev.backend.ka.philips.com/api/Capability"
		},
		"recipes": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe{?shortId,title,managed,status,blockKey}",
			"templated": true
		},
		"pushMessages": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage{?q,country,status,category,createdFrom,createdUntil,deliveryStatus,deliveredFrom}",
			"templated": true
		},
		"autocookPrograms": {
			"href": "https://dev.backend.ka.philips.com/api/AutocookProgram{?referenceId}",
			"templated": true
		},
		"scheduledEvents": {
			"href": "https://dev.backend.ka.philips.com/api/ScheduledEvent{?q,country,type,status,scheduledFrom,scheduledUntil}",
			"templated": true
		},
		"comments": {
			"href": "https://dev.backend.ka.philips.com/api/Comment{?status,state,contentType,managingCountry,publisherCountry,authorUserType,isReported}",
			"templated": true
		},
		"recipeIngredients": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/{id}/Ingredient{?unitSystem}",
			"templated": true
		},
		"contentTranslationImport": {
			"href": "https://dev.backend.ka.philips.com/api/Content/Translation"
		},
		"collectionRecipes": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/{id}/Recipe{?category,status,blockKey}",
			"templated": true
		},
		"follow": {
			"href": "https://dev.backend.ka.philips.com/api/Profile/self/Profile$follow/{id}",
			"templated": true
		},
		"favourite": {
			"href": "https://dev.backend.ka.philips.com/api/Profile/self/Content$favourite/{id}",
			"templated": true
		},
		"announcement": {
			"href": "https://dev.backend.ka.philips.com/api/Announcement/{id}",
			"templated": true
		},
		"recipe": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/{id}",
			"templated": true
		},
		"publication": {
			"href": "https://dev.backend.ka.philips.com/api/Content/{id}/Publication{?countries}",
			"templated": true
		},
		"recipeComments": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/{id}/Comment{?status,contentProfileId,blockKey}",
			"templated": true
		},
		"articleComments": {
			"href": "https://dev.backend.ka.philips.com/api/Article/{id}/Comment{?status,contentProfileId,blockKey}",
			"templated": true
		},
		"profile": {
			"href": "https://dev.backend.ka.philips.com/api/Profile/{id}",
			"templated": true
		},
		"device": {
			"href": "https://dev.backend.ka.philips.com/api/Device/{id}{?country,unitSystem}",
			"templated": true
		},
		"profileSurveyResponses": {
			"href": "https://dev.backend.ka.philips.com/api/Profile/self/Survey/Response{?categories}",
			"templated": true
		},
		"userFederation": {
			"href": "https://dev.backend.ka.philips.com/api/User/self/FederatedIdentity"
		},
		"profiles": {
			"href": "https://dev.backend.ka.philips.com/api/Profile{?type,identityProvider,federatedIdentity,lastLoggedAtAfter,country,email,name,phoneNumber,category}",
			"templated": true
		},
		"profileSelf": {
			"href": "https://dev.backend.ka.philips.com/api/Profile/self"
		},
		"media": {
			"href": "https://dev.backend.ka.philips.com/api/Media"
		},
		"collection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/{id}",
			"templated": true
		},
		"article": {
			"href": "https://dev.backend.ka.philips.com/api/Article/{id}",
			"templated": true
		},
		"publicationById": {
			"href": "https://dev.backend.ka.philips.com/api/Content/Publication/{id}",
			"templated": true
		},
		"mediaById": {
			"href": "https://dev.backend.ka.philips.com/api/Media/{id}",
			"templated": true
		},
		"pushMessage": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/{id}",
			"templated": true
		},
		"scheduledEvent": {
			"href": "https://dev.backend.ka.philips.com/api/ScheduledEvent/{id}",
			"templated": true
		},
		"comment": {
			"href": "https://dev.backend.ka.philips.com/api/Comment/{id}",
			"templated": true
		},
		"country": {
			"href": "https://dev.backend.ka.philips.com/api/Country/{id}",
			"templated": true
		},
		"recipeIngredient": {
			"href": "https://dev.backend.ka.philips.com/api/Ingredient/{id}{?unitSystem}",
			"templated": true
		},
		"coffeeMaintenanceArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/780aab87-e5db-43d3-8e07-83c2946f560c"
		},
		"coffeeSetupArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/4fd91ed5-2912-4718-8465-1463f33a5518"
		},
		"coffeeEwsSetupArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/86f4f1b6-f66c-43a1-b16d-9e7e68f59f42"
		},
		"coffeeTipsAndTricksArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/8e345163-cf26-4b14-b8b1-d3c5a1fd934f"
		},
		"coffeeHowToArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/a86de8b1-f552-4358-ba36-9ecb0e3cd881"
		},
		"coffeeCoffeeStoriesArticleCollection": {
			"href": "https://dev.backend.ka.philips.com/api/Collection/52df92c1-4057-4951-9380-45ab3cf38ea5"
		},
		"coffeeShopLinks": {
			"href": "https://dev.backend.ka.philips.com/api/v1/prx/locales"
		}
	}
}
