{
	"_embedded": {
		"item": [
			{
				"_links": {
					"associated": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332/Category$associated"
					},
					"children": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332/Category"
					},
					"food": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332/Food"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/mediaID-756-332"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332/Translation"
					}
				},
				"abbreviation": "COLOR"
			},
			{
				"_links": {
					"associated": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Category$associated"
					},
					"children": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Category"
					},
					"food": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Food"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/mediaID-756-332"
					},
					"parent": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332"
					},
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation"
					}
				},
				"abbreviation": "RED"
			}
		]
	},
	"_links": {
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-756-332"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123"
			}
		],
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Category"
		}
	}
}
