{
	"_embedded": {
		"item": [
			{
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country"
					},
					"content": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					},
					"country": {
						"href": "https://dev.backend.ka.philips.com/api/Country/156464ef-5355-4265-9481-3dff875e37bf"
					}
				}
			},
			{
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country"
					},
					"content": {
						"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
					},
					"country": {
						"href": "https://dev.backend.ka.philips.com/api/Country/c0000004-0000-0000-0000-cc0000000000"
					}
				}
			}
		]
	}
}
