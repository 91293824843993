{
	"name": "Pineapple spinach smoothie",
	"status": "DRAFT",
	"type": "RECIPE",
	"createdAt": "2018-02-15T19:31:31.342Z",
	"modifiedAt": "2019-07-24T06:23:47.317172Z",
	"_links": {
		"managingCountries": {
			"href": "https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2"
		},
		"defaultTranslation": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h"
		},
		"translations": {
			"href": "https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2/Translation"
		},
		"recipe": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/83b13b1a-e1cc-485b-b363-fa9a8cf4bef4"
		}
	}
}
