{
	"_embedded": {
		"variants": {
			"_links": {
				"self": {
					"href": "https://dev.backend.ka.philips.com/api/Product/067d933d-cbc4-43aa-bdc9-5876ff248828/Variant?page=0&size=50"
				}
			},
			"page": {
				"size": 50,
				"totalElements": 0,
				"totalPages": 0,
				"number": 0
			}
		}
	},
	"range": "Mock device",
	"model": "HRXXX",
	"time": {},
	"temperature": {
		"unit": "CELSIUS"
	},
	"_links": {
		"categories": {
			"href": "https://dev.backend.ka.philips.com/api/Product/067d933d-cbc4-43aa-bdc9-5876ff248828/Category"
		},
		"variants": {
			"href": "https://dev.backend.ka.philips.com/api/Product/067d933d-cbc4-43aa-bdc9-5876ff248828/Variant{?country}",
			"templated": true
		},
		"translations": {
			"href": "https://dev.backend.ka.philips.com/api/Product/067d933d-cbc4-43aa-bdc9-5876ff248828/Translation"
		},
		"self:getSelfByUUID": {
			"href": "cid:067d933d-cbc4-43aa-bdc9-5876ff248828",
			"type": "application/vnd.api+json",
			"deprecation": "https://nutriu.atlassian.net/wiki/spaces/ARCH/pages/608764256/OneKA+Interface"
		},
		"collection": {
			"href": "https://dev.backend.ka.philips.com/api/Device"
		},
		"accessories": {
			"href": "https://dev.backend.ka.philips.com/api/Device/067d933d-cbc4-43aa-bdc9-5876ff248828/Accessory"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Device/067d933d-cbc4-43aa-bdc9-5876ff248828?unitSystem=METRIC{&country}",
			"templated": true
		}
	},
	"isConnectable": false
}
