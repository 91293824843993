{
  "ingredientUnit": {
    "bag": {
      "empty": "包",
      "plural": "包",
      "singular": "包"
    },
    "branch": {
      "empty": "段",
      "plural": "段",
      "singular": "段"
    },
    "bunch": {
      "empty": "束",
      "plural": "束",
      "singular": "束"
    },
    "can": {
      "empty": "罐",
      "plural": "罐",
      "singular": "罐"
    },
    "centiliter": {
      "empty": "厘升",
      "plural": "厘升",
      "singular": "厘升"
    },
    "clove": {
      "empty": "瓣",
      "plural": "瓣",
      "singular": "瓣"
    },
    "cup": {
      "empty": "杯",
      "plural": "杯",
      "singular": "杯"
    },
    "dash": {
      "empty": "一些",
      "plural": "一些",
      "singular": "一點"
    },
    "decigram": {
      "empty": "十進制",
      "plural": "十進制",
      "singular": "十進制"
    },
    "deciliter": {
      "empty": "分升",
      "plural": "分升",
      "singular": "分升"
    },
    "drop": {
      "empty": "滴",
      "plural": "滴",
      "singular": "滴"
    },
    "fluidOunce": {
      "empty": "液量盎司",
      "plural": "液量盎司",
      "singular": "液盎司"
    },
    "gram": {
      "empty": "克",
      "plural": "克",
      "singular": "克"
    },
    "kilogram": {
      "empty": "公斤",
      "plural": "公斤",
      "singular": "公斤"
    },
    "liter": {
      "empty": "公升",
      "plural": "公升",
      "singular": "公升"
    },
    "microgram": {
      "empty": "微克",
      "plural": "微克",
      "singular": "微克"
    },
    "milligram": {
      "empty": "毫克",
      "plural": "毫克",
      "singular": "毫克"
    },
    "milliliter": {
      "empty": "毫升",
      "plural": "毫升",
      "singular": "毫升"
    },
    "ounce": {
      "empty": "盎司",
      "plural": "盎司",
      "singular": "盎司"
    },
    "package": {
      "plural": "包",
      "singular": "包"
    },
    "piece": {
      "empty": "件",
      "plural": "件",
      "singular": "件"
    },
    "pinch": {
      "empty": "少許",
      "plural": "少許",
      "singular": "少許"
    },
    "pound": {
      "empty": "磅",
      "plural": "磅",
      "singular": "磅"
    },
    "sachet": {
      "empty": "小包",
      "plural": "小包",
      "singular": "小包"
    },
    "shot": {
      "empty": "杯",
      "plural": "杯",
      "singular": "杯"
    },
    "slice": {
      "empty": "片",
      "plural": "片",
      "singular": "片"
    },
    "stick": {
      "empty": "枝",
      "plural": "枝",
      "singular": "枝"
    },
    "tablespoon": {
      "empty": "湯匙",
      "plural": "湯匙",
      "singular": "湯匙"
    },
    "teaspoon": {
      "empty": "茶匙",
      "plural": "茶匙",
      "singular": "茶匙"
    }
  }
}