{
  "ingredientUnit": {
    "bag": {
      "empty": "çantalar",
      "plural": "çantalar",
      "singular": "çanta"
    },
    "branch": {
      "empty": "şubeler",
      "plural": "şubeler",
      "singular": "şube"
    },
    "bunch": {
      "empty": "demetler",
      "plural": "demetler",
      "singular": "demet"
    },
    "can": {
      "empty": "kutular",
      "plural": "kutular",
      "singular": "kutu"
    },
    "centiliter": {
      "empty": "santilitre",
      "plural": "santilitre",
      "singular": "santilitre"
    },
    "clove": {
      "empty": "karanfil",
      "plural": "karanfil",
      "singular": "karanfil"
    },
    "cup": {
      "empty": "fincanlar",
      "plural": "fincanlar",
      "singular": "fincan"
    },
    "dash": {
      "empty": "az bir miktar",
      "plural": "az bir miktar",
      "singular": "az bir miktar"
    },
    "decigram": {
      "empty": "desigram",
      "plural": "desigram",
      "singular": "desigram"
    },
    "deciliter": {
      "empty": "desilitre",
      "plural": "desilitre",
      "singular": "desilitre"
    },
    "drop": {
      "empty": "damla",
      "plural": "damla",
      "singular": "damla"
    },
    "fluidOunce": {
      "empty": "sıvı ons",
      "plural": "sıvı ons",
      "singular": "sıvı ons"
    },
    "gram": {
      "empty": "gram",
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogram",
      "plural": "kilogram",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "litre",
      "plural": "litre",
      "singular": "litre"
    },
    "microgram": {
      "empty": "mikrogram",
      "plural": "mikrogram",
      "singular": "mikrogram"
    },
    "milligram": {
      "empty": "milligram",
      "plural": "milligram",
      "singular": "milligram"
    },
    "milliliter": {
      "empty": "mililitre",
      "plural": "mililitre",
      "singular": "mililitre"
    },
    "ounce": {
      "empty": "ons",
      "plural": "ons",
      "singular": "ons"
    },
    "package": {
      "plural": "paketler",
      "singular": "paket"
    },
    "piece": {
      "empty": "adetler",
      "plural": "adetler",
      "singular": "adet"
    },
    "pinch": {
      "empty": "tutam",
      "plural": "tutam",
      "singular": "tutam"
    },
    "pound": {
      "empty": "pound",
      "plural": "pound",
      "singular": "pound"
    },
    "sachet": {
      "empty": "baharat torbası",
      "plural": "baharat torbası",
      "singular": "baharat torbası"
    },
    "shot": {
      "empty": "shots",
      "plural": "shots",
      "singular": "shot"
    },
    "slice": {
      "empty": "dilimler",
      "plural": "dilimler",
      "singular": "dilim"
    },
    "stick": {
      "empty": "çubuklar",
      "plural": "çubuklar",
      "singular": "çubuk"
    },
    "tablespoon": {
      "empty": "yemek kaşığı",
      "plural": "yemek kaşığı",
      "singular": "yemek kaşığı"
    },
    "teaspoon": {
      "empty": "çay kaşığı",
      "plural": "çay kaşığı",
      "singular": "çay kaşığı"
    }
  }
}