{
  "ingredientUnit": {
    "bag": {
      "plural": "paquets",
      "singular": "paquet"
    },
    "branch": {
      "plural": "branches",
      "singular": "branche"
    },
    "bunch": {
      "plural": "bouquets",
      "singular": "bouquet"
    },
    "can": {
      "plural": "boîtes",
      "singular": "boîte"
    },
    "centiliter": {
      "plural": "centilitres",
      "singular": "centilitre"
    },
    "clove": {
      "plural": "gousses",
      "singular": "gousse"
    },
    "cup": {
      "plural": "tasses",
      "singular": "tasse"
    },
    "dash": {
      "plural": "filets",
      "singular": "filet"
    },
    "decigram": {
      "plural": "décigrammes",
      "singular": "décigramme"
    },
    "deciliter": {
      "plural": "décilitres",
      "singular": "décilitre"
    },
    "drop": {
      "plural": "gouttes",
      "singular": "goutte"
    },
    "gram": {
      "plural": "grammes",
      "singular": "gramme"
    },
    "kilogram": {
      "plural": "kilogrammes",
      "singular": "kilogramme"
    },
    "liter": {
      "plural": "litres",
      "singular": "litre"
    },
    "milligram": {
      "plural": "milligrammes",
      "singular": "milligramme"
    },
    "milliliter": {
      "plural": "millilitres",
      "singular": "millilitre"
    },
    "ounce": {
      "plural": "onces",
      "singular": "once"
    },
    "package": {
      "plural": "paquets",
      "singular": "paquet"
    },
    "piece": {
      "plural": "morceaux",
      "singular": "morceau"
    },
    "pinch": {
      "plural": "pincées",
      "singular": "pincée"
    },
    "sachet": {
      "plural": "sachets",
      "singular": "sachet"
    },
    "slice": {
      "plural": "tranches",
      "singular": "tranche"
    },
    "tablespoon": {
      "plural": "cuillères à soupe",
      "singular": "cuillère à soupe"
    },
    "teaspoon": {
      "plural": "cuillères à café",
      "singular": "cuillère à café"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Oui",
        "label": "Recette facile",
        "notEasyToStart": "Non",
        "placeholder": "Recette facile"
      }
    }
  }
}