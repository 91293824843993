{
  "ingredientUnit": {
    "bag": {
      "plural": "poser",
      "singular": "pose"
    },
    "branch": {
      "plural": "kvaster",
      "singular": "kvast"
    },
    "bunch": {
      "plural": "bunter",
      "singular": "bunt"
    },
    "can": {
      "plural": "bokser",
      "singular": "boks"
    },
    "centiliter": {
      "plural": "centiliter",
      "singular": "centiliter"
    },
    "clove": {
      "plural": "fedd",
      "singular": "fedd"
    },
    "cup": {
      "plural": "kopper",
      "singular": "kopp"
    },
    "dash": {
      "plural": "dasher",
      "singular": "dash"
    },
    "decigram": {
      "plural": "desigram",
      "singular": "desigram"
    },
    "deciliter": {
      "plural": "desiliter",
      "singular": "desiliter"
    },
    "drop": {
      "plural": "dråper",
      "singular": "dråpe"
    },
    "gram": {
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "plural": "kilo",
      "singular": "kilo"
    },
    "liter": {
      "plural": "liter",
      "singular": "liter"
    },
    "milligram": {
      "plural": "milligram",
      "singular": "milligram"
    },
    "milliliter": {
      "plural": "milliliter",
      "singular": "milliliter"
    },
    "ounce": {
      "plural": "ouncer",
      "singular": "ounce"
    },
    "package": {
      "plural": "pakker",
      "singular": "pakke"
    },
    "piece": {
      "plural": "stykker",
      "singular": "stykk"
    },
    "pinch": {
      "plural": "klyper",
      "singular": "klype"
    },
    "sachet": {
      "plural": "poser",
      "singular": "pose"
    },
    "slice": {
      "plural": "skiver",
      "singular": "skive"
    },
    "tablespoon": {
      "plural": "spiseskjeer",
      "singular": "spiseskje"
    },
    "teaspoon": {
      "plural": "teskjeer",
      "singular": "teskje"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "Ja",
        "label": "Lett å starte",
        "notEasyToStart": "Nei",
        "placeholder": "Lett å starte"
      }
    }
  }
}