{
	"_embedded": {
		"item": [
			{
				"quantity": "20",
				"unit": "GRAM",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Ingredient/213213123"
					},
					"food": {
						"href": "https://dev.backend.ka.philips.com/api/Food/d15f7993-9bfd-49c7-ae3b-83aac450a2d1"
					}
				}
			},
			{
				"quantity": "48",
				"unit": "MILLILITER",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Ingredient/832876455"
					},
					"food": {
						"href": "https://dev.backend.ka.philips.com/api/Food/959d008c-8568-43c1-a416-a2dcfa44fab7"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Ingredient/213213123"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/Ingredient/832876455"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient?page=1&size=10"
		}
	}
}
