{
	"_embedded": {
		"item": [
			{
				"name": "Red",
				"language": "en-US",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Category/Translation/categoryTranslationId-9534-423"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Category/Translation/categoryTranslationId-9534-423"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation?page=1&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation?page=1&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation"
		}
	}
}
