{
  "ingredientUnit": {
    "bag": {
      "empty": "săculețe",
      "plural": "săculețe",
      "singular": "săculeț"
    },
    "branch": {
      "empty": "ramuri",
      "plural": "ramuri",
      "singular": "ramură"
    },
    "bunch": {
      "empty": "mănunchiuri",
      "plural": "mănunchiuri",
      "singular": "mănunchi"
    },
    "can": {
      "empty": "conserve",
      "plural": "conserve",
      "singular": "conservă"
    },
    "centiliter": {
      "empty": "centilitri",
      "plural": "centilitri",
      "singular": "centilitru"
    },
    "clove": {
      "empty": "căței",
      "plural": "căței",
      "singular": "cățel"
    },
    "cup": {
      "empty": "căni",
      "plural": "căni",
      "singular": "cană"
    },
    "dash": {
      "empty": "picuri",
      "plural": "picuri",
      "singular": "pic"
    },
    "decigram": {
      "empty": "decigram",
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "decilitri",
      "plural": "decilitri",
      "singular": "decilitru"
    },
    "drop": {
      "empty": "picături",
      "plural": "picături",
      "singular": "picătură"
    },
    "fluidOunce": {
      "empty": "uncii fluid",
      "plural": "uncii fluid",
      "singular": "uncie fluid"
    },
    "gram": {
      "empty": "grame",
      "plural": "grame",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilograme",
      "plural": "kilograme",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "litri",
      "plural": "litri",
      "singular": "litru"
    },
    "microgram": {
      "empty": "micrograme",
      "plural": "micrograme",
      "singular": "microgram"
    },
    "milligram": {
      "empty": "miligrame",
      "plural": "miligrame",
      "singular": "miligram"
    },
    "milliliter": {
      "empty": "mililitri",
      "plural": "mililitri",
      "singular": "mililitru"
    },
    "ounce": {
      "empty": "uncii",
      "plural": "uncii",
      "singular": "uncie"
    },
    "package": {
      "plural": "pachete",
      "singular": "pachet"
    },
    "piece": {
      "empty": "bucăți",
      "plural": "bucăți",
      "singular": "bucată"
    },
    "pinch": {
      "empty": "vârfuri de cuțit",
      "plural": "vârfuri de cuțit",
      "singular": "vârf de cuțit"
    },
    "pound": {
      "empty": "livre",
      "plural": "livre",
      "singular": "livră"
    },
    "sachet": {
      "empty": "plic",
      "plural": "plic",
      "singular": "plic"
    },
    "shot": {
      "empty": "doze",
      "plural": "doze",
      "singular": "doză"
    },
    "slice": {
      "empty": "felii",
      "plural": "felii",
      "singular": "felie"
    },
    "stick": {
      "empty": "batoane",
      "plural": "batoane",
      "singular": "baton"
    },
    "tablespoon": {
      "empty": "linguri",
      "plural": "linguri",
      "singular": "lingură"
    },
    "teaspoon": {
      "empty": "lingurițe",
      "plural": "lingurițe",
      "singular": "linguriță"
    }
  }
}