{
  "ingredientUnit": {
    "bag": {
      "empty": "ถุง",
      "plural": "ถุง",
      "singular": "ถุง"
    },
    "branch": {
      "empty": "ก้าน",
      "plural": "ก้าน",
      "singular": "ก้าน"
    },
    "bunch": {
      "empty": "กำมือ",
      "plural": "กำมือ",
      "singular": "กำมือ"
    },
    "can": {
      "empty": "กระป๋อง",
      "plural": "กระป๋อง",
      "singular": "กระป๋อง"
    },
    "centiliter": {
      "empty": "เซนติลิตร",
      "plural": "เซนติลิตร",
      "singular": "เซนติลิตร"
    },
    "clove": {
      "empty": "กลีบ",
      "plural": "กลีบ",
      "singular": "กลีบ"
    },
    "cup": {
      "empty": "ถ้วย",
      "plural": "ถ้วย",
      "singular": "ถ้วย"
    },
    "dash": {
      "empty": "เหยาะ",
      "plural": "เหยาะ",
      "singular": "เหยาะ"
    },
    "decigram": {
      "empty": "เดซิกรัม",
      "plural": "เดซิกรัม",
      "singular": "เดซิกรัม"
    },
    "deciliter": {
      "empty": "เดซิลิตร",
      "plural": "เดซิลิตร",
      "singular": "เดซิลิตร"
    },
    "drop": {
      "empty": "หยด",
      "plural": "หยด",
      "singular": "หยด"
    },
    "fluidOunce": {
      "empty": "ออนซ์ของเหลว",
      "plural": "ออนซ์ของเหลว",
      "singular": "ออนซ์ของเหลว"
    },
    "gram": {
      "empty": "กรัม",
      "plural": "กรัม",
      "singular": "กรัม"
    },
    "kilogram": {
      "empty": "กิโลกรัม",
      "plural": "กิโลกรัม",
      "singular": "กิโลกรัม"
    },
    "liter": {
      "empty": "ลิตร",
      "plural": "ลิตร",
      "singular": "ลิตร"
    },
    "microgram": {
      "empty": "ไมโครกรัม",
      "plural": "ไมโครกรัม",
      "singular": "ไมโครกรัม"
    },
    "milligram": {
      "empty": "มิลลิกรัม",
      "plural": "มิลลิกรัม",
      "singular": "มิลลิกรัม"
    },
    "milliliter": {
      "empty": "มิลลิลิตร",
      "plural": "มิลลิลิตร",
      "singular": "มิลลิลิตร"
    },
    "ounce": {
      "empty": "ออนซ์",
      "plural": "ออนซ์",
      "singular": "ออนซ์"
    },
    "package": {
      "plural": "ห่อ",
      "singular": "ห่อ"
    },
    "piece": {
      "empty": "ชิ้น",
      "plural": "ชิ้น",
      "singular": "ชิ้น"
    },
    "pinch": {
      "empty": "หยิบมือ",
      "plural": "หยิบมือ",
      "singular": "หยิบมือ"
    },
    "pound": {
      "empty": "ปอนด์",
      "plural": "ปอนด์",
      "singular": "ปอนด์"
    },
    "sachet": {
      "empty": "ซอง",
      "plural": "ซอง",
      "singular": "ซอง"
    },
    "shot": {
      "empty": "ช็อต",
      "plural": "ช็อต",
      "singular": "ช็อต"
    },
    "slice": {
      "empty": "แผ่น",
      "plural": "แผ่น",
      "singular": "แผ่น"
    },
    "stick": {
      "empty": "แท่ง",
      "plural": "แท่ง",
      "singular": "แท่ง"
    },
    "tablespoon": {
      "empty": "ช้อนโต๊ะ",
      "plural": "ช้อนโต๊ะ",
      "singular": "ช้อนโต๊ะ"
    },
    "teaspoon": {
      "empty": "ช้อนชา",
      "plural": "ช้อนชา",
      "singular": "ช้อนชา"
    }
  }
}