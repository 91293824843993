{
	"_embedded": {
		"item": [
			{
				"order": 2,
				"description": "Description on the original model - 2",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/9c0bce82-12f7-49c7-9a65-6d17013cc293"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation"
					}
				}
			},
			{
				"order": 1,
				"description": "Description on the original model - 1",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/435d6rft7gy8hu"
					},
					"media": {
						"href": "https://dev.backend.ka.philips.com/api/Media/9c0bce82-12f7-49c7-9a65-6d17013cc293"
					},
					"translations": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/435d6rft7gy8hu/Translation"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/RecipeStep/v7t8by"
			},
			{
				"href": "https://dev.backend.ka.philips.com/api/RecipeStep/09878gyfutcyf"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step?page=1&size=10"
		}
	}
}
