{
  "ingredientUnit": {
    "bag": {
      "empty": "krepš.",
      "plural": "maiš.",
      "singular": "maiš."
    },
    "branch": {
      "empty": "šak.",
      "plural": "šak.",
      "singular": "šak."
    },
    "bunch": {
      "empty": "ryš.",
      "plural": "ryš.",
      "singular": "ryš."
    },
    "can": {
      "empty": "skard.",
      "plural": "skard.",
      "singular": "skard."
    },
    "centiliter": {
      "empty": "cl",
      "plural": "cl",
      "singular": "cl"
    },
    "clove": {
      "empty": "skilt.",
      "plural": "skilt.",
      "singular": "skilt."
    },
    "cup": {
      "empty": "puod.",
      "plural": "puod.",
      "singular": "puod."
    },
    "dash": {
      "empty": "šlak.",
      "plural": "šlak.",
      "singular": "šlak."
    },
    "decigram": {
      "empty": "dg",
      "plural": "dg",
      "singular": "dg"
    },
    "deciliter": {
      "empty": "dl",
      "plural": "dl",
      "singular": "dl"
    },
    "drop": {
      "empty": "laš.",
      "plural": "laš.",
      "singular": "laš."
    },
    "fluidOunce": {
      "empty": "skys. oz",
      "plural": "skys. oz",
      "singular": "skys. oz"
    },
    "gram": {
      "empty": "g",
      "plural": "g",
      "singular": "g"
    },
    "kilogram": {
      "empty": "kg",
      "plural": "kg",
      "singular": "kg"
    },
    "liter": {
      "empty": "l",
      "plural": "l",
      "singular": "l"
    },
    "microgram": {
      "empty": "μg",
      "plural": "μg",
      "singular": "μg"
    },
    "milligram": {
      "empty": "mg",
      "plural": "mg",
      "singular": "mg"
    },
    "milliliter": {
      "empty": "ml",
      "plural": "ml",
      "singular": "ml"
    },
    "ounce": {
      "empty": "oz",
      "plural": "oz",
      "singular": "oz"
    },
    "package": {
      "plural": "pak.",
      "singular": "pak."
    },
    "piece": {
      "empty": "vnt.",
      "plural": "vnt.",
      "singular": "vnt."
    },
    "pinch": {
      "empty": "žiups.",
      "plural": "žiups.",
      "singular": "žiups."
    },
    "pound": {
      "empty": "svarai",
      "plural": "svarų",
      "singular": "svaras"
    },
    "sachet": {
      "empty": "pak.",
      "plural": "pak.",
      "singular": "pak."
    },
    "scoop": {
      "empty": "kauš.",
      "plural": "kauš.",
      "singular": "kauš."
    },
    "shot": {
      "empty": "šlak.",
      "plural": "šlak.",
      "singular": "šlak."
    },
    "slice": {
      "empty": "griež.",
      "plural": "griež.",
      "singular": "griež."
    },
    "stick": {
      "empty": "lazd.",
      "plural": "lazd.",
      "singular": "lazd."
    },
    "tablespoon": {
      "empty": "šauk.",
      "plural": "šauk.",
      "singular": "šauk."
    },
    "teaspoon": {
      "empty": "arbt.šaukšt.",
      "plural": "arbt.šaukšt.",
      "singular": "arbt.šaukšt."
    }
  }
}