{
	"_embedded": {
		"item": [
			{
				"description": "Step description 1 Lorem Ipsum",
				"language": "en-US",
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/RecipeStep/Translation/7894389080954334"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/RecipeStep/Translation/7894389080954334"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation?page=1&size=10"
		}
	}
}
