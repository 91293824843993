{
	"_embedded": {
		"item": [
			{
				"description": "Lorem IPSUM",
				"language": "en-US",
				"name": "ABC",
				"keywords": "test,recipe",
				"test123": "test123",
				"steps": [
					{
						"description": "Lorem IPSUM"
					}
				],
				"_links": {
					"self": {
						"href": "https://dev.backend.ka.philips.com/api/Recipe/Translation/recipeTranslationId213434233"
					}
				}
			}
		]
	},
	"_links": {
		"first": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation?page=0&size=10"
		},
		"item": [
			{
				"href": "https://dev.backend.ka.philips.com/api/Recipe/Translation/recipeTranslationId213434233"
			}
		],
		"last": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation?page=10&size=10"
		},
		"next": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation?page=2&size=10"
		},
		"prev": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation?page=0&size=10"
		},
		"self": {
			"href": "https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation?page=1&size=10"
		}
	}
}
