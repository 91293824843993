{
  "ingredientUnit": {
    "bag": {
      "empty": "túi",
      "plural": "túi",
      "singular": "túi"
    },
    "branch": {
      "empty": "cành",
      "plural": "cành",
      "singular": "cành"
    },
    "bunch": {
      "empty": "bó",
      "plural": "bó",
      "singular": "bó"
    },
    "can": {
      "empty": "lon",
      "plural": "lon",
      "singular": "lon"
    },
    "centiliter": {
      "empty": "centilit",
      "plural": "centilit",
      "singular": "centilit"
    },
    "clove": {
      "empty": "nhánh",
      "plural": "nhánh",
      "singular": "nhánh"
    },
    "cup": {
      "empty": "tách",
      "plural": "tách",
      "singular": "tách"
    },
    "dash": {
      "empty": "một ít",
      "plural": "một ít",
      "singular": "một ít"
    },
    "decigram": {
      "empty": "decigram",
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "decilit",
      "plural": "decilit",
      "singular": "decilit"
    },
    "drop": {
      "empty": "giọt",
      "plural": "giọt",
      "singular": "giọt"
    },
    "fluidOunce": {
      "empty": "ounce chất lỏng",
      "plural": "ounce chất lỏng",
      "singular": "ounce chất lỏng"
    },
    "gram": {
      "empty": "gram",
      "plural": "gram",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilogram",
      "plural": "kilogram",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "lít",
      "plural": "lít",
      "singular": "lít"
    },
    "microgram": {
      "empty": "microgram",
      "plural": "microgram",
      "singular": "microgram"
    },
    "milligram": {
      "empty": "milligram",
      "plural": "milligram",
      "singular": "milligram"
    },
    "milliliter": {
      "empty": "millilit",
      "plural": "millilit",
      "singular": "millilit"
    },
    "ounce": {
      "empty": "ounce",
      "plural": "ounce",
      "singular": "ounce"
    },
    "package": {
      "plural": "gói",
      "singular": "gói"
    },
    "piece": {
      "empty": "cái",
      "plural": "cái",
      "singular": "cái"
    },
    "pinch": {
      "empty": "nhúm",
      "plural": "nhúm",
      "singular": "nhúm"
    },
    "pound": {
      "empty": "pound",
      "plural": "pound",
      "singular": "pound"
    },
    "sachet": {
      "empty": "gói nhỏ",
      "plural": "gói nhỏ",
      "singular": "gói nhỏ"
    },
    "shot": {
      "empty": "shot",
      "plural": "shot",
      "singular": "shot"
    },
    "slice": {
      "empty": "lát",
      "plural": "lát",
      "singular": "lát"
    },
    "stick": {
      "empty": "que",
      "plural": "que",
      "singular": "que"
    },
    "tablespoon": {
      "empty": "thìa canh",
      "plural": "thìa canh",
      "singular": "thìa canh"
    },
    "teaspoon": {
      "empty": "thìa cà phê",
      "plural": "thìa cà phê",
      "singular": "thìa cà phê"
    }
  }
}