{
  "ingredientUnit": {
    "bag": {
      "plural": "자루",
      "singular": "자루"
    },
    "branch": {
      "plural": "가지",
      "singular": "가지"
    },
    "bunch": {
      "plural": "송이",
      "singular": "송이"
    },
    "can": {
      "plural": "통조림",
      "singular": "통조림"
    },
    "centiliter": {
      "plural": "센티리터",
      "singular": "센티리터"
    },
    "clove": {
      "plural": "쪽",
      "singular": "쪽"
    },
    "cup": {
      "plural": "컵",
      "singular": "컵"
    },
    "dash": {
      "plural": "소량",
      "singular": "소량"
    },
    "decigram": {
      "plural": "데시그램",
      "singular": "데시그램"
    },
    "deciliter": {
      "plural": "데시리터",
      "singular": "데시리터"
    },
    "drop": {
      "plural": "방울",
      "singular": "방울"
    },
    "gram": {
      "plural": "그램",
      "singular": "그램"
    },
    "kilogram": {
      "plural": "킬로그램",
      "singular": "킬로그램"
    },
    "liter": {
      "plural": "리터",
      "singular": "리터"
    },
    "milligram": {
      "plural": "밀리그램",
      "singular": "밀리그램"
    },
    "milliliter": {
      "plural": "밀리리터",
      "singular": "밀리리터"
    },
    "ounce": {
      "plural": "온스",
      "singular": "온스"
    },
    "package": {
      "plural": "패키지",
      "singular": "패키지"
    },
    "piece": {
      "plural": "조각",
      "singular": "조각"
    },
    "pinch": {
      "plural": "꼬집",
      "singular": "꼬집"
    },
    "sachet": {
      "plural": "봉지",
      "singular": "봉지"
    },
    "slice": {
      "plural": "슬라이스",
      "singular": "슬라이스"
    },
    "tablespoon": {
      "plural": "테이블스푼",
      "singular": "테이블스푼"
    },
    "teaspoon": {
      "plural": "티스푼",
      "singular": "티스푼"
    }
  },
  "recipe": {
    "tag": {
      "difficulty": {
        "easyToStart": "예",
        "label": "쉬운 시작",
        "notEasyToStart": "아니요",
        "placeholder": "쉬운 시작"
      }
    }
  }
}